import React from "react";
import RequireAuth from "../components/RequireAuth";
import { Route, Routes } from "react-router-dom";
import { toggleContext } from "../App";
import adminSidebarRoutes from "../data/sidebar";
import userRoles from "../utils/roles";

import Sidebar from "../components/Sidebar/Sidebar";
import Dashboard from "../pages/Dashboard/Dashboard";
import ContractorAccount from "../pages/ContractorAccount/ContractorAccount";
import TruckdriverAccount from "../pages/TruckdriverAccount/TruckdriverAccount";
import ContractorDetails from "../pages/ContractorDetails/ContractorDetails";
import TruckDriverDetails from "../pages/TruckDriverDetails/TruckDriverDetails";
import NotFound from "../pages/NotFound/NotFound";
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import JobSummary from "pages/JobSummary/JobSummary";
import JobSummaryDetails from "pages/JobSummaryDetails/JobSummaryDetails";

const AdminLayout = () => {
  const [isopen, setisOpen] = React.useState(true);
  return (
    <>
      <toggleContext.Provider
        value={{
          isopen,
          setisOpen
        }}
      >
        <Sidebar routes={adminSidebarRoutes} />
        <div className={isopen ? "sidecontainer" : "sidecontainerclose"}>
          <div>
            <Routes>
              <Route element={<RequireAuth allowedRoles={[userRoles.ADMIN]} />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="job-summary" element={<JobSummary />} />
                <Route path="job/:jobId/driver-logs/:driverId" element={<JobSummaryDetails />} />
                <Route path="contractor-account" element={<ContractorAccount />} />
                <Route path="contractor-account/:id/details" element={<ContractorDetails />} />
                <Route path="truckdriver-account" element={<TruckdriverAccount />} />
                <Route path="truckdriver-account/:id/details" element={<TruckDriverDetails />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </div>
      </toggleContext.Provider>
    </>
  );
};

export default AdminLayout;
