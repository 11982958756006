import toast from "react-hot-toast";

import {
  postLoggedInUserApi,
  postLoggedOutUserApi,
  sendOtpApi,
  verifyOtpApi,
  registerContractorApi,
  resetPasswordApi,
  forgotPasswordSendOtpApi,
  fetchProfileApi,
  updateProfileApi,
  uploadDocumentApi,
  getUploadDocumentApi
} from "../../services/userServices";

import {
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  RESET_IS_SIGNING,
  SET_LOGGEDOUT_USER,
  SET_SENDING_OTP,
  RESET_SENDING_OTP,
  SET_VERIFYING_OTP,
  RESET_VERIFYING_OTP,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_DOCUMENT_LINK,
  GET_DOCUMENT_LINK_SUCCESS,
  GET_DOCUMENT_LINK_FAILURE,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE
} from "./types";

export const setLoggedInUser = ({ user, token, role }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token, role }
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER
  };
};

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING
  };
};

export const sendOtp = () => {
  return {
    type: SET_SENDING_OTP
  };
};
export const resetOtp = () => {
  return {
    type: RESET_SENDING_OTP
  };
};
export const verifyOtp = () => {
  return {
    type: SET_VERIFYING_OTP
  };
};
export const resetVerifyOtp = () => {
  return {
    type: RESET_VERIFYING_OTP
  };
};

export const fetchProfile = () => {
  return {
    type: FETCH_PROFILE
  };
};

export const fetchProfileSuccess = (contractorProfile) => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: { contractorProfile }
  };
};

export const fetchProfileFailure = (error) => {
  return {
    type: FETCH_PROFILE_FAILURE,
    payload: error
  };
};

export const updateProfile = () => {
  return {
    type: UPDATE_PROFILE
  };
};

export const updateProfileSuccess = (updatedName) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { updatedName }
  };
};

export const updateProfileFailure = () => {
  return {
    type: UPDATE_PROFILE_FAILURE
  };
};

export const getDocumentLink = () => {
  return {
    type: GET_DOCUMENT_LINK
  };
};

export const getDocumentLinkSuccess = (documentUpload) => {
  return {
    type: GET_DOCUMENT_LINK_SUCCESS,
    payload: { documentUpload }
  };
};

export const getDocumentLinkFailure = (error) => {
  return {
    type: GET_DOCUMENT_LINK_FAILURE,
    payload: error
  };
};

export const uploadDocument = () => {
  return {
    type: UPLOAD_DOCUMENT
  };
};

export const uploadDocumentSuccess = () => {
  return {
    type: UPDATE_PROFILE_SUCCESS
  };
};

export const uploadDocumentFailure = () => {
  return {
    type: UPLOAD_DOCUMENT_FAILURE
  };
};

export const fetchLoginUserThunkAction = (requestBody) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());

      const { data } = await postLoggedInUserApi({
        username: requestBody.email,
        password: requestBody.password,
        roles: ["admin", "contractor"]
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }

      toast.success("Logged in successfully.");
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("role", data.data.user.roleId);
      localStorage.setItem("user", JSON.stringify(data.data.user));

      dispatch(
        setLoggedInUser({
          user: data.data.user,
          token: data.data.token,
          role: data.data.user.roleId
        })
      );
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const loggingOutUserThunkAction = ({ userid }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await postLoggedOutUserApi({ user_id: +userid });

      if (data.success !== true) {
        throw new Error(data.message);
      }

      const userRole = localStorage.getItem("role");

      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("user");
      dispatch(setLoggedOutUser());
      toast.success("Logged out successfully");
      onSuccess(Number(userRole));
    } catch (error) {
      localStorage.removeItem("token");
      dispatch(setLoggedOutUser());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const sendOtpThunkAction = (email, navigateToVerifyOtp) => {
  return async (dispatch) => {
    try {
      dispatch(sendOtp());
      const { data } = await sendOtpApi({
        username: email,
        role: "contractor"
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetOtp());
      navigateToVerifyOtp(email);
    } catch (error) {
      dispatch(resetOtp());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const forgotPasswordSendOtpThunkAction = ({ username, roles }, navigateToVerifyOtp) => {
  return async (dispatch) => {
    try {
      dispatch(sendOtp());
      const { data } = await forgotPasswordSendOtpApi({
        username: username,
        roles
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetOtp());
      navigateToVerifyOtp(username);
    } catch (error) {
      dispatch(resetOtp());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const verifyOtpThunkAction = ({ username, otp }, navigateToRegister) => {
  return async (dispatch) => {
    try {
      dispatch(verifyOtp());
      const { data } = await verifyOtpApi({
        username: username,
        otp: otp
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success(data.message);
      dispatch(resetVerifyOtp());
      navigateToRegister();
    } catch (error) {
      dispatch(resetVerifyOtp());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const resetPasswordThunkAction = (requestBody, navigateToLogin) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const details = {
        username: requestBody.username,
        otp: requestBody.otp,
        password: requestBody.password,
        roles: ["admin", "contractor"]
      };
      const promise = resetPasswordApi(details);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Password Reset successful",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      navigateToLogin();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};

export const registerUserThunkAction = (details, onSuccess = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());

      const promise = registerContractorApi(details);

      toast.promise(promise, {
        loading: "Loading...",
        success: "User registered successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};

export const fetchProfileThunkAction = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchProfile());
      const { data } = await fetchProfileApi();

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(fetchProfileSuccess(data?.data));
    } catch (error) {
      dispatch(fetchProfileFailure(error));
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateProfileThunkAction = (requestBodyFormData, firstName, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      dispatch(updateProfile());
      const { data } = await updateProfileApi(requestBodyFormData);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(updateProfileSuccess(firstName));
      const user = JSON.parse(localStorage.getItem("user"));

      const updateUser = { ...user, firstName: firstName };
      localStorage.setItem("user", JSON.stringify(updateUser));

      toast.success("Profile Updated!");
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(updateProfileFailure());
      dispatch(resetIsSigning());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const getDocumentLinkThunkAction = (prms) => {
  return async (dispatch) => {
    try {
      dispatch(getDocumentLink());
      const { data } = await getUploadDocumentApi(prms);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(getDocumentLinkSuccess(data?.data));
    } catch (error) {
      //if user directly visits file upload route without userId, docId, then APIs throw 500 error
      if (error?.response?.status === 500) {
        dispatch(
          getDocumentLinkFailure({
            ...error,
            response: {
              ...error?.response,
              data: {
                ...error?.response?.data,
                message: "Invalid Link!"
              }
            }
          })
        );
      } else {
        dispatch(getDocumentLinkFailure(error));
      }
      // toast.error(error?.response?.data?.message || error?.message);
    }
  };
};

export const uploadDocumentThunkAction = (requestBody) => {
  return async (dispatch) => {
    try {
      dispatch(uploadDocument());

      const promise = uploadDocumentApi(requestBody);

      toast.promise(promise, {
        loading: "Uploading...",
        success: "Document Uploaded successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(uploadDocumentSuccess());
    } catch (error) {
      dispatch(uploadDocumentFailure());
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};
