import React from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, InputGroup, Row } from "reactstrap";
import "../ContractorLogin/ContractorLogin.css";
import Logo from "../../assets/img/logo/cheetah_broken_logo.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendOtpThunkAction } from "../../redux/User/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/User/selectors";

const CreateContractorAccount = () => {
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToVerifyOtp = (email) => {
    navigate("/auth/verifyOtp", { state: { username: email, forgot: false } });
  };

  const { isSending } = useSelector(selectUser);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Must be a Valid Email").required("Email Required")
    }),
    onSubmit: (values) => {
      dispatch(sendOtpThunkAction(values.email, navigateToVerifyOtp));
    }
  });
  return (
    <>
      <div className="bg_contractor">
        <div className="bg_shadow">
          <Row className="justify-content-md-center contractor_container" lg="3" md="7" xs="12">
            <Col lg="3" md="7" xs="12" className="col-lg-auto">
              <Card className="bg-secondary shadow border-0 justify-content-md-center">
                <div
                  className="text-center"
                  style={{
                    background: "#F9F9F9",
                    borderRadius: "0.375rem 0.375rem 0 0"
                  }}
                >
                  <span>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{
                        width: "70%",
                        padding: "2rem 0",
                        marginLeft: "2rem"
                      }}
                    />
                  </span>
                </div>

                <CardBody className="px-lg-5 py-lg-1 container_card">
                  <div className="text-center  mb-4">
                    <h3 className="text-dark text-center fw-bold">Create account</h3>
                    <p>
                      Enter your account details below or &nbsp;
                      <Link
                        to="/auth/contractor/login"
                        style={{
                          color: "black",
                          fontWeight: "500"
                        }}
                      >
                        <span>Log in </span>
                      </Link>
                    </p>
                  </div>
                  <Form role="form" onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <input
                          type="text"
                          className="form-control container_input"
                          placeholder="Enter Your E-mail"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </InputGroup>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger mt-2">{formik.errors.email}</div>
                      ) : null}
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        disabled={!formik.isValid || isSending}
                        className="my-4 contractor_btn"
                        color="dark"
                        type="submit"
                      >
                        <div style={{ fontWeight: "bold" }}>
                          {isSending ? "Sending..." : "Send OTP"}
                        </div>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CreateContractorAccount;
