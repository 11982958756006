import axiosInstance from "./axiosInstance";

export const foremanListApi = (prms) =>
  axiosInstance.get(`api/v1/contractor/foreman-list`, {
    params: {
      limit: prms?.limit || 10,
      page: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "created_at",
      order: prms?.order || "asc"
    }
  });

export const addForemanApi = (userData) =>
  axiosInstance.post("api/v1/contractor/create-foreman", userData);

export const deleteForemanApi = (id) => axiosInstance.delete(`api/v1/contractor/foreman/${id}`);

export const currentJobPostingApi = (prms) =>
  axiosInstance.get(`api/v1/contractor/job-list`, {
    params: {
      limit: prms?.limit || 10,
      page: prms?.page || 0,
      search: prms?.search || ""
    }
  });

export const addJobApi = (userData) => axiosInstance.post("api/v1/contractor/create-job", userData);

export const deleteJobApi = (id) => axiosInstance.delete(`api/v1/contractor/job/${id}`);

export const jobDetailsApi = (id) => axiosInstance.get(`api/v1/contractor/job/details/${id}`);

export const updateJobApi = (id, userData) =>
  axiosInstance.put(`api/v1/contractor/job/${id}`, userData);
