import {
  JOB_DETAILS_FAILURE,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_SUMMARY_LISTING_FAILURE,
  JOB_SUMMARY_LISTING_REQUEST,
  JOB_SUMMARY_LISTING_SUCCESS,
  JOB_SUMMARY_LOGS_FAILURE,
  JOB_SUMMARY_LOGS_REQUEST,
  JOB_SUMMARY_LOGS_SUCCESS
} from "./types";

const initialState = {
  isLoading: false,
  jobs: null,
  totalPages: 0,
  error: "",
  isSummaryLogsLoading: false,
  summaryLogs: null,
  summaryLogsTotalPages: 0,
  summaryLogsError: "",
  jobDetails: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case JOB_SUMMARY_LISTING_REQUEST:
      return { ...state, isLoading: true, summaryLogs: null };
    case JOB_SUMMARY_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload?.jobsData,
        totalPages: action.payload?.total,
        error: ""
      };
    case JOB_SUMMARY_LISTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobs: null,
        totalPages: 0,
        error: action.payload
      };

    case JOB_SUMMARY_LOGS_REQUEST:
      return { ...state, isSummaryLogsLoading: true };
    case JOB_SUMMARY_LOGS_SUCCESS:
      return {
        ...state,
        isSummaryLogsLoading: false,
        summaryLogs: action.payload?.jobsData,
        summaryLogsTotalPages: action.payload?.total,
        summaryLogsError: ""
      };
    case JOB_SUMMARY_LOGS_FAILURE:
      return {
        ...state,
        isSummaryLogsLoading: false,
        summaryLogs: null,
        summaryLogsTotalPages: 0,
        summaryLogsError: action.payload
      };

    case JOB_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case JOB_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobDetails: action.payload,
        error: ""
      };
    case JOB_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobDetails: null,
        error: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
