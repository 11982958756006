import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalFooter } from "reactstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  fetchTruckDriverListThunkAction,
  updateTruckDriverStatusThunkAction
} from "../../redux/Admin/action";

const RequestApprovedModal = ({ toggleModal, isOpen, id }) => {
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();
  const navigator = useNavigate();

  const onNavigate = () => {
    navigator("/admin/truckdriver-account");
    dispatch(
      fetchTruckDriverListThunkAction(
        {
          limit: 10
        },
        onSuccess,
        onError
      )
    );
  };
  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  const ApproveModal = () => {
    const userData = { status: true };
    toggleModal(false);
    dispatch(updateTruckDriverStatusThunkAction(id, userData, onNavigate, onError));
  };

  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Are you sure you want to Approve Truck Driver ?</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              ApproveModal();
            }}
          >
            Approve
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RequestApprovedModal;
