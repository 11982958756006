export const SET_IS_SIGNING = "SET_IS_SIGNING";
export const RESET_IS_SIGNING = "RESET_IS_SIGNING";

export const SET_LOGGEDIN_USER = "SET_USER";
export const SET_LOGGEDOUT_USER = "SET_LOGGEDOUT_USER";
export const SET_SENDING_OTP = "SET_SENDING_OTP";
export const RESET_SENDING_OTP = "RESET_SENDING_OTP";
export const SET_VERIFYING_OTP = "SET_VERIFYING_OTP";
export const RESET_VERIFYING_OTP = "RESET_VERIFYING_OTP";

export const REFRESH_STATE = "REFRESH_STATE";

export const FETCH_PROFILE = "FETCH_PROFILE";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const GET_DOCUMENT_LINK = "GET_DOCUMENT_LINK";
export const GET_DOCUMENT_LINK_SUCCESS = "GET_DOCUMENT_LINK_SUCCESS";
export const GET_DOCUMENT_LINK_FAILURE = "GET_DOCUMENT_LINK_FAILURE";

export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILURE = "UPLOAD_DOCUMENT_FAILURE";
