import React from "react";
import Sidebaritems from "../Sidebar/SidebarItems";
import logo from "../../assets/img/logo/cheetah_broken_logo.png";
import { Icon } from "@iconify/react";
import "./Sidebar.css";
import useViewport from "../../hooks/useViewport";
import { Button } from "reactstrap";
import { toggleContext } from "../../App";
import SidebarUser from "./SidebarUser";

const Sidebar = (props) => {
  const { routes } = props;
  const { width } = useViewport();
  const { isopen, setisOpen } = React.useContext(toggleContext);
  return (
    <React.Fragment>
      {width >= 785 && (
        <div className={isopen ? "sidebar" : "sidebarclose"}>
          <div>
            <span className="d-flex justify-content-between align-items-center">
              <span className={isopen ? "mt-4 mb-2 ml-2" : "d-none"}>
                <img style={{ marginLeft: "73px" }} alt=".." src={logo} width="100" />
                <p
                  className="mt-2"
                  style={{
                    letterSpacing: "1px",
                    marginLeft: "50px",
                    fontWeight: "bolder",
                    color: "#FFA302"
                  }}
                >
                  Cheetah Broker
                </p>
              </span>
              <Icon
                icon="ri:record-circle-line"
                className="m-3 px-1"
                style={{ cursor: "pointer", color: "#FFA302" }}
                onClick={() => setisOpen(!isopen)}
                width="30px"
              />
            </span>
          </div>

          {routes?.map((item, index) => (
            <Sidebaritems key={index} item={item} isopen={isopen} />
          ))}
          <SidebarUser />
        </div>
      )}
      {width < 787 && (
        <div className={isopen ? "sidebar" : "sidebarclose-active"}>
          <div>
            <span className="d-flex justify-content-between align-items-center">
              <span className={isopen ? "mt-4 ml-2" : "d-none"}>
                <img style={{ marginLeft: "73px" }} alt=".." src={logo} width="80" />
                <p
                  className="mt-2"
                  style={{
                    letterSpacing: "1px",
                    marginLeft: "50px",
                    fontWeight: "bolder",
                    color: "#FFA302"
                  }}
                >
                  Cheetah Broker
                </p>
              </span>
              <div onClick={() => setisOpen(!isopen)}>
                {isopen ? (
                  <Icon
                    icon="ri:record-circle-line"
                    className="m-3 px-1"
                    style={{ cursor: "pointer", color: "#FFA302" }}
                    onClick={() => setisOpen(!isopen)}
                    width="30px"
                  />
                ) : (
                  <Button style={{ position: "absolute", zIndex: 9999 }} className="m-1 px-1">
                    <i className="bi bi-list mx-2" />
                  </Button>
                )}
              </div>
            </span>
          </div>

          {routes?.map((item, index) => (
            <Sidebaritems key={index} item={item} isopen={isopen} />
          ))}
          <SidebarUser />
        </div>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
