import axiosInstance from "./axiosInstance";

export const postLoggedInUserApi = (userData) => axiosInstance.post("/api/v1/auth/login", userData);

export const postLoggedOutUserApi = (userData) =>
  axiosInstance.post("/api/v1/auth/logout", userData);

export const sendOtpApi = (userData) => axiosInstance.post("/api/v1/auth/otp-sent", userData);

export const forgotPasswordSendOtpApi = (userData) =>
  axiosInstance.post("/api/v1/auth/forgot-password", userData);

export const verifyOtpApi = (userData) => axiosInstance.post("/api/v1/auth/otp-verify", userData);

export const registerContractorApi = (userData) =>
  axiosInstance.post("/api/v1/auth/register-contractor", userData);

export const resetPasswordApi = (userData) =>
  axiosInstance.post("/api/v1/auth/reset-password", userData);

export const fetchProfileApi = () => axiosInstance.get("api/v1/contractor/profile");

export const updateProfileApi = (userData) =>
  axiosInstance.put("api/v1/contractor/profile", userData);

export const getUploadDocumentApi = (prms) =>
  axiosInstance.get(`api/v1/auth/truckdriver/insurance/document`, {
    params: {
      userId: prms?.userId,
      docId: prms?.docId,
      code: prms?.code
    }
  });

export const uploadDocumentApi = (userData) =>
  axiosInstance.post("api/v1/auth/truckdriver/insurance/document", userData);
