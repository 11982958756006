import React, { useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, InputGroup, Row } from "reactstrap";
import OTPInput from "otp-input-react";
import { useLocation } from "react-router-dom";

import Logo from "../../assets/img/logo/cheetah_broken_logo.png";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  verifyOtpThunkAction,
  sendOtpThunkAction,
  forgotPasswordSendOtpThunkAction
} from "../../redux/User/actions";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/User/selectors";

const VerifyOtp = () => {
  const [OTP, setOTP] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToRegister = () => {
    if (location.state?.forgot) {
      navigate("/auth/resetPassword", { state: { username: location.state?.username, otp: OTP } });
    } else {
      navigate("/auth/contractor/register", { state: { username: location.state?.username } });
    }
  };
  const navigateToVerifyOtp = () => {};
  const { isSending, isVerifying } = useSelector(selectUser);

  const submitHadler = (e) => {
    e.preventDefault();
    if (OTP.length === 6) {
      dispatch(
        verifyOtpThunkAction({ username: location.state?.username, otp: OTP }, navigateToRegister)
      );
    } else {
      toast.error("Please Enter Valid OTP");
    }
  };

  const reSendOtp = () => {
    if (location.state?.forgot) {
      dispatch(
        forgotPasswordSendOtpThunkAction(
          { username: location.state?.username },
          navigateToVerifyOtp
        )
      );
    } else {
      dispatch(sendOtpThunkAction(location.state?.username, navigateToVerifyOtp));
    }
  };
  return (
    <>
      <div className="bg_contractor">
        <div className="bg_shadow">
          <Row
            className="justify-content-md-center contractor_container px-1"
            lg="3"
            md="9"
            xs="12"
          >
            <Col lg="4" md="6" xs="12" className="col-lg-auto">
              <Card className="bg-secondary shadow border-0 justify-content-md-center">
                <div
                  className="text-center"
                  style={{
                    background: "#F9F9F9",
                    borderRadius: "0.375rem 0.375rem 0 0"
                  }}
                >
                  <span>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{
                        width: "70%",
                        padding: "2rem 0",
                        marginLeft: "2rem"
                      }}
                    />
                  </span>
                </div>

                <CardBody className="px-lg-5 py-lg-1 container_card">
                  <div className="text-center  mb-4">
                    <h3 className="text-dark text-center fw-bold">OTP Verification</h3>
                  </div>
                  <Form role="form" onSubmit={submitHadler}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative d-flex justify-content-center">
                        <OTPInput
                          inputStyles={{
                            width: "2.5rem",
                            height: "2.5rem",
                            margin: "0 1rem",
                            fontSize: "2rem",
                            borderRadius: 4,
                            border: "1px solid rgba(0,0,0,0.3)"
                          }}
                          OTPLength={6}
                          otpType="number"
                          disabled={false}
                          value={OTP}
                          onChange={setOTP}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="d-flex justify-content-end">
                      <small
                        onClick={() => (!isSending ? reSendOtp() : "")}
                        style={{ cursor: "pointer" }}
                        className="container_forgot_password"
                      >
                        {isSending ? "Sending..." : "Resend OTP!"}
                      </small>
                    </div>

                    <div className="text-center">
                      <Button
                        disabled={isVerifying}
                        className="my-4 contractor_btn"
                        color="dark"
                        type="submit"
                      >
                        <div style={{ fontWeight: "bold" }}>
                          {isVerifying ? "Verifying..." : "Verify OTP"}
                        </div>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
