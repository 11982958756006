import React, { useEffect, useState } from "react";
import { toggleContext } from "../../App";
import UserIcon from "../../assets/img/sidebarIcon/user.png";
import { Button, Menu, MenuItem, styled } from "@mui/material";
import useViewport from "../../hooks/useViewport";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRole } from "../../redux/User/selectors";
import userRoles from "../../utils/roles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loggingOutUserThunkAction } from "../../redux/User/actions";
import { selectUser } from "../../redux/User/selectors";

import "./Sidebar.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: "#FFA302",
    borderRadius: 6,
    marginTop: theme.spacing(-6),
    minWidth: 200,
    color: "white",
    marginLeft: "10px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "&:hover": {
        color: "#412B15"
      }
    }
  }
}));

const SidebarUser = () => {
  const { isopen } = React.useContext(toggleContext);
  const { width } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { updatedName } = useSelector(selectUser);
  const [userName, setUserName] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    if (user) {
      setUserName(updatedName ? updatedName : user.firstName);
    }
  }, [user]);

  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loginPageRoute = () => {
    if (pathname.includes("/admin/")) {
      return "/auth/admin/login";
    } else {
      return "/auth/contractor/login";
    }
  };

  const profilePageRoute = () => {
    switch (Number(role)) {
      case userRoles.CONTRACTOR:
        return "/contractor/profile";
      default:
        return "/nofound";
    }
  };

  const navigateToLoginPage = (userRole) => {
    navigate(`${loginPageRoute()}`, { replace: true });
  };

  const signOut = (userid) => {
    dispatch(loggingOutUserThunkAction({ userid }, navigateToLoginPage));
  };

  return (
    <footer
      style={
        width > 787 || isopen
          ? {
              position: "absolute",
              bottom: "1%"
            }
          : {
              display: "none"
            }
      }
      className="footer-width"
    >
      <div className="inner mx-2">
        <div style={{ cursor: "pointer" }}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            className="navbar-dropdown-btn"
            style={{
              backgroundColor: "white",
              color: "#FFA302",
              marginLeft: "-10px"
            }}
          >
            <img src={UserIcon} style={{ marginTop: "4px" }} height="30px" />
            <div
              className={isopen ? "mx-2 mt-2" : "d-none"}
              style={{ color: "#FFA302", float: "left" }}
            >
              {userName ? userName : ""}
            </div>
          </Button>

          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button"
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <Link
              to={profilePageRoute()}
              style={{
                textDecoration: "none",
                color: "white"
              }}
            >
              {+role === 2 ? <MenuItem onClick={handleClose}>Profile</MenuItem> : ""}
            </Link>

            <MenuItem
              onClick={() => {
                signOut(user.id);
                handleClose();
              }}
              disableRipple
            >
              Sign out
            </MenuItem>
          </StyledMenu>
        </div>
      </div>
    </footer>
  );
};

export default SidebarUser;
