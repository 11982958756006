import React, { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Button, Col, Container, Input, Jumbotron, Row, Table } from "reactstrap";
import miningDashboard from "../../assets/img/backgroundImages/mining_dashboard.png";
import cheetahProfile from "../../assets/img/backgroundImages/cheetah_profile.png";
import totalDrivers from "../../assets/img/backgroundImages/total_drivers.png";
import totalContractors from "../../assets/img/backgroundImages/total_contractors.png";
import totalCreatedJobs from "../../assets/img/backgroundImages/total_created_jobs.png";
import totalCompletedJobs from "../../assets/img/backgroundImages/total_completed_jobs.png";
import totalPayable from "../../assets/img/backgroundImages/total_payable.png";

import { Icon } from "@iconify/react";
import StatisticsCard from "components/Cards/StatisticsCard";
import StatisticsCardTwo from "components/Cards/StatisticsCardTwo";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardStatisticsThunkAction } from "redux/dashboard/action";
import { selectDashboard } from "redux/dashboard/selectors";
import Loader from "components/Loader/Loader";
import { fetchDashboardStatisticsTwoThunkAction } from "redux/dashboard/action";
import DropDown from "components/Dropdown/Dropdown";
import { fetchLogDetailsThunkAction } from "redux/dashboard/action";
import JobOutstandingCard from "components/Cards/JobOutstandingCard";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUser = JSON.parse(localStorage.getItem("user"));

  const {
    isDashboardStatisticsLoading,
    dashboardStatistics,
    isDashboardStatisticsError,
    isDashboardStatisticsTwoLoading,
    dashboardStatisticsTwo,
    isDashboardStatisticsTwoError,
    isLogDetailsLoading,
    logDetails,
    isLogDetailsError
  } = useSelector(selectDashboard);

  const [statistics, setStatistics] = useState({
    end_date: "",
    start_date: ""
  });
  const [jobOutstanding, setJobOutstanding] = useState({
    end_date: "",
    start_date: ""
  });

  useEffect(() => {
    dispatch(fetchDashboardStatisticsThunkAction());
  }, []);

  useEffect(() => {
    dispatch(fetchDashboardStatisticsTwoThunkAction(statistics));
  }, [statistics]);

  useEffect(() => {
    dispatch(fetchLogDetailsThunkAction(jobOutstanding));
  }, [jobOutstanding]);

  const handleValue = (start_date, end_date, type) => {
    switch (type) {
      case "statistics":
        setStatistics({
          end_date: end_date,
          start_date: start_date
        });
        break;

      case "jobOutstanding":
        setJobOutstanding({
          end_date: end_date,
          start_date: start_date
        });
        break;

      default:
        break;
    }
  };

  return (
    <div
      className="px-3"
      style={{
        backgroundColor: "#F9F9F9"
      }}
    >
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Dashboard
          </h4>
        </Col>
      </Row>
      <Jumbotron
        fluid
        style={{
          backgroundColor: "#FFF6E6",
          borderRadius: "6px"
        }}
      >
        <Container fluid>
          <Row className="">
            <Col
              className="h-100 col-7 d-flex flex-column"
              style={{
                flexGrow: 1
              }}
            >
              <div
                className="mt-2"
                style={{
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "#7B7B7B"
                }}
              >
                Welcome Back!
              </div>
              <div className="position-relative d-flex align-items-center">
                <img
                  src={cheetahProfile}
                  alt=""
                  style={{
                    width: "100px",
                    paddingTop: "10px"
                  }}
                />
                <div className="pl-3">
                  <div
                    style={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: "#565656"
                    }}
                  >
                    Cheetah Broker
                  </div>
                  <div
                    style={{
                      fontSize: "1rem",
                      fontWeight: 400,
                      color: "#565656"
                    }}
                  >
                    {loggedInUser?.email}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              className="h-100 d-none d-lg-block"
              style={{
                marginRight: 0,
                paddingRight: 0
              }}
            >
              <img
                src={miningDashboard}
                alt="miningDashboard"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%"
                }}
              />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Row className="mt-4">
        {isDashboardStatisticsLoading ? (
          <Row>
            <Col
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "100px"
              }}
            >
              <Loader />
            </Col>
          </Row>
        ) : dashboardStatistics ? (
          <>
            <StatisticsCard
              title="Total Drivers"
              value={dashboardStatistics?.truckDriverCount}
              image={totalDrivers}
            />

            <StatisticsCard
              title="Total Contractors"
              value={dashboardStatistics?.contractorCount}
              image={totalContractors}
            />

            <StatisticsCard
              title="Total Created Jobs"
              value={dashboardStatistics?.totalJobs}
              image={totalCreatedJobs}
            />

            <StatisticsCard
              title="Total Completed Jobs"
              value={dashboardStatistics?.completedJobs}
              image={totalCompletedJobs}
            />
          </>
        ) : (
          <div>{isDashboardStatisticsError}</div>
        )}
      </Row>
      <hr />

      <Row>
        <Col className="col-12 col-md-4 col-lg-3">
          <Row>
            <Col
              xs="12"
              className="d-flex align-items-center mb-4"
              style={{
                width: "100%"
              }}
            >
              <DropDown
                handleValue={(start_date, end_date) =>
                  handleValue(start_date, end_date, "statistics")
                }
              />
            </Col>
            {isDashboardStatisticsTwoLoading ? (
              <Row>
                <Col
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{
                    minHeight: "400px"
                  }}
                >
                  <Loader />
                </Col>
              </Row>
            ) : dashboardStatisticsTwo ? (
              <>
                <Col className="col-12">
                  <div
                    className="bg-white p-2 border"
                    style={{
                      borderRadius: "10px"
                    }}
                  >
                    <StatisticsCardTwo
                      title="Active Jobs"
                      value={dashboardStatisticsTwo?.activeJobs}
                      image={totalCreatedJobs}
                    />

                    <StatisticsCardTwo
                      title="Completed Jobs"
                      value={dashboardStatisticsTwo?.completedJobs}
                      image={totalCompletedJobs}
                    />
                  </div>
                </Col>

                <Col className="mt-sm-4">
                  <div
                    className="bg-white p-2 border"
                    style={{
                      borderRadius: "10px"
                    }}
                  >
                    <StatisticsCardTwo
                      title="New Jobs"
                      value={dashboardStatisticsTwo?.newJobs}
                      image={totalCreatedJobs}
                    />

                    <StatisticsCardTwo
                      title="New Contractors"
                      value={dashboardStatisticsTwo?.contractorCount}
                      image={totalContractors}
                    />

                    <StatisticsCardTwo
                      title="New Drivers"
                      value={dashboardStatisticsTwo?.truckDriverCount}
                      image={totalDrivers}
                    />
                  </div>
                </Col>
              </>
            ) : (
              <div>{isDashboardStatisticsTwoError}</div>
            )}
          </Row>
        </Col>
        <Col className="col-12 col-md-8 col-lg-9 mt-3 mt-sm-0">
          <div
            className="bg-white border"
            style={{
              borderRadius: "10px"
            }}
          >
            <Row className="p-2">
              <Col className="col-12 d-flex mt-2 justify-content-between align-items-center flex-wrap">
                <h6
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: 500,
                    color: "#565656"
                  }}
                >
                  Outstanding Payable
                </h6>
                <div
                  className="d-flex"
                  style={{
                    columnGap: "6px"
                  }}
                >
                  <DropDown
                    handleValue={(start_date, end_date) =>
                      handleValue(start_date, end_date, "jobOutstanding")
                    }
                  />
                  <div
                    className="px-4 py-2 d-flex align-items-center"
                    style={{
                      whiteSpace: "nowrap",
                      borderRadius: "10px",
                      cursor: "pointer",
                      columnGap: "6px",
                      background: "linear-gradient(22.53deg, #FFA302 12.1%, #FFF6E7 186.98%)",
                      color: "white"
                    }}
                    onClick={() => navigate("/admin/job-summary")}
                  >
                    <span>View All</span>
                    <Icon icon="teenyicons:right-outline" />
                  </div>
                </div>
              </Col>

              {isLogDetailsLoading ? (
                <Row>
                  <Col
                    className="w-100 d-flex justify-content-center align-items-center"
                    style={{
                      minHeight: "600px"
                    }}
                  >
                    <Loader />
                  </Col>
                </Row>
              ) : logDetails ? (
                <>
                  <Col className="col-12">
                    <Row className="p-3">
                      <div
                        className="d-flex p-3 border"
                        style={{
                          borderRadius: "10px"
                        }}
                      >
                        <JobOutstandingCard
                          title="Total jobs"
                          value={logDetails?.totalJobs}
                          image={totalCompletedJobs}
                        />

                        <JobOutstandingCard
                          title="Total Drivers"
                          value={logDetails?.driverCount}
                          image={totalDrivers}
                        />

                        <JobOutstandingCard
                          title="Total Payable"
                          value={logDetails?.totalPayable?.toFixed(2)}
                          image={totalPayable}
                        />
                      </div>
                    </Row>
                  </Col>

                  <Col
                    className="col-12 pl-5 my-3"
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "500",
                      color: "#565656"
                    }}
                  >
                    Log Details
                  </Col>
                  <Col className="col-12">
                    <div
                      className="log_table_dashboard"
                      style={{
                        maxHeight: "260px",
                        overflowY: "scroll"
                      }}
                    >
                      <Table borderless>
                        <thead
                          style={{
                            backgroundColor: "#FFF6E6"
                          }}
                        >
                          <tr>
                            <th>Job ID</th>
                            <th>Contractor</th>
                            <th>Start Date & Time</th>
                            <th>Total Drivers</th>
                            <th>Duration</th>
                            <th>End Date & Time</th>
                          </tr>
                        </thead>
                        {logDetails?.jobsData?.length ? (
                          <tbody>
                            {logDetails?.jobsData?.map((item) => {
                              return (
                                <tr key={item?.id}>
                                  <td>{item?.id}</td>
                                  <td>{item?.createdByUser?.firstName}</td>
                                  <td
                                    style={{
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item?.startDate
                                      ? moment(item?.startDate).format("MMM  DD, YYYY | hh:mm A")
                                      : "-----"}
                                  </td>
                                  <td>{item?.trucks}</td>
                                  <td>{item?.duration ? item?.duration + " Days" : "-----"}</td>
                                  <td
                                    style={{
                                      whiteSpace: "nowrap"
                                    }}
                                  >
                                    {item?.endDate
                                      ? moment(item?.endDate).format("MMM  DD, YYYY | hh:mm A")
                                      : "-----"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : null}
                      </Table>

                      {logDetails?.jobsData?.length ? null : (
                        <div className="w-100 text-center py-3">No data found.</div>
                      )}
                    </div>
                  </Col>
                </>
              ) : (
                <div>{isLogDetailsError}</div>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
