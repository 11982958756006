import {
  FETCH_CONTRACTOR_LIST,
  FETCH_CONTRACTOR_LIST_SUCCESS,
  FETCH_CONTRACTOR_LIST_FAILURE,
  FETCH_CONTRACTOR_DETAILS,
  FETCH_CONTRACTOR_DETAILS_SUCCESS,
  FETCH_CONTRACTOR_DETAILS_FAILURE,
  UPDATE_CONTRACTOR_STATUS,
  UPDATE_CONTRACTOR_STATUS_SUCCESS,
  UPDATE_CONTRACTOR_STATUS_FAILURE,
  FETCH_TRUCK_DRIVER,
  FETCH_TRUCK_DRIVER_SUCCESS,
  FETCH_TRUCK_DRIVER_FAILURE,
  FETCH_TRUCK_DRIVER_DETAILS,
  FETCH_TRUCK_DRIVER_DETAILS_SUCCESS,
  FETCH_TRUCK_DRIVER_DETAILS_FAILURE,
  UPDATE_TRUCK_DRIVER_STATUS,
  UPDATE_TRUCK_DRIVER_STATUS_SUCCESS,
  UPDATE_TRUCK_DRIVER_STATUS_FAILURE,
  SET_CONTRACTOR_PAGE_SIZE,
  SET_TRUCK_DRIVER_PAGE_SIZE,
  UPDATE_CONTRACTOR_NOTE,
  UPDATE_TRUCK_DRIVER_NOTE
} from "./types";

const initialState = {
  isLoading: false,
  contractorStatusList: [],
  totalPage: 0,
  error: "",
  contractorDetails: [],
  truckDriverStatusList: [],
  truckDriverDetails: [],
  contractorPageSize: 10,
  truckDriverPageSize: 10
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTRACTOR_LIST:
      return { ...state, isLoading: true };
    case FETCH_CONTRACTOR_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contractorStatusList: action.payload.contractorlist,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_CONTRACTOR_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        contractorStatusList: [],
        error: action.payload
      };
    case FETCH_TRUCK_DRIVER:
      return { ...state, isLoading: true };
    case FETCH_TRUCK_DRIVER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        truckDriverStatusList: action.payload.truckDriverList,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_TRUCK_DRIVER_FAILURE:
      return {
        ...state,
        isLoading: false,
        truckDriverStatusList: [],
        error: action.payload
      };
    case FETCH_CONTRACTOR_DETAILS:
      return { ...state, isLoading: true };
    case FETCH_CONTRACTOR_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        contractorDetails: action.payload.contractorDetails,
        error: ""
      };
    case FETCH_CONTRACTOR_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        contractorDetails: [],
        error: action.payload
      };
    case FETCH_TRUCK_DRIVER_DETAILS:
      return { ...state, isLoading: true };
    case FETCH_TRUCK_DRIVER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        truckDriverDetails: action.payload.truckDriverDetails,
        error: ""
      };
    case FETCH_TRUCK_DRIVER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        truckDriverDetails: [],
        error: action.payload
      };
    case UPDATE_CONTRACTOR_STATUS:
      return { ...state, isLoading: true };
    case UPDATE_CONTRACTOR_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: ""
      };
    case UPDATE_CONTRACTOR_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case UPDATE_TRUCK_DRIVER_STATUS:
      return { ...state, isLoading: true };
    case UPDATE_TRUCK_DRIVER_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: ""
      };
    case UPDATE_TRUCK_DRIVER_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case SET_CONTRACTOR_PAGE_SIZE:
      return {
        ...state,
        contractorPageSize: action.payload
      };
    case SET_TRUCK_DRIVER_PAGE_SIZE:
      return {
        ...state,
        truckDriverPageSize: action.payload
      };

    case UPDATE_CONTRACTOR_NOTE:
      return {
        ...state,
        contractorStatusList: state.contractorStatusList.map((contractor) => {
          if (contractor?.id === action?.payload?.userId) {
            return {
              ...contractor,
              userNote: {
                ...contractor?.userNote,
                description: action?.payload?.description,
                updatedAt: new Date().toISOString()
              },
              isNote: true
            };
          }
          return contractor;
        })
      };
    case UPDATE_TRUCK_DRIVER_NOTE:
      return {
        ...state,
        truckDriverStatusList: state.truckDriverStatusList.map((driver) => {
          if (driver?.id === action?.payload?.userId) {
            return {
              ...driver,
              userNote: {
                ...driver?.userNote,
                description: action?.payload?.description,
                updatedAt: new Date().toISOString()
              },
              isNote: true
            };
          }
          return driver;
        })
      };
    default:
      return state;
  }
};

export default reducer;
