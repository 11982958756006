import React from "react";
import userRoles from "../../utils/roles";
import "../NotFound/NotFound.css";
import { Link } from "react-router-dom";

const Unauthorized = () => {
  const role = localStorage.getItem("role");

  const defaultHomePage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.CONTRACTOR:
        return "/contractor/foreman-list";
      default:
        return "/nofound";
    }
  };

  return (
    <>
      <main className="body">
        <div className="main-wrapper">
          <div className="error-text">
            <h2>401 - Unauthorized!</h2>
            <p>You are not Authorized for this Page!</p>
            <span className="input-group-btn">
              <Link to={defaultHomePage()}>
                <button className="back-btn">Go Back</button>
              </Link>
            </span>
          </div>
        </div>
      </main>
    </>
  );
};

export default Unauthorized;
