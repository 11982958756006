export const JOB_SUMMARY_LISTING_REQUEST = "JOB_SUMMARY_LISTING_REQUEST";
export const JOB_SUMMARY_LISTING_SUCCESS = "JOB_SUMMARY_LISTING_SUCCESS";
export const JOB_SUMMARY_LISTING_FAILURE = "JOB_SUMMARY_LISTING_FAILURE";

export const JOB_SUMMARY_LOGS_REQUEST = "JOB_SUMMARY_LOGS_REQUEST";
export const JOB_SUMMARY_LOGS_SUCCESS = "JOB_SUMMARY_LOGS_SUCCESS";
export const JOB_SUMMARY_LOGS_FAILURE = "JOB_SUMMARY_LOGS_FAILURE";

export const JOB_DETAILS_REQUEST = "JOB_DETAILS_REQUEST";
export const JOB_DETAILS_SUCCESS = "JOB_DETAILS_SUCCESS";
export const JOB_DETAILS_FAILURE = "JOB_DETAILS_FAILURE";
