import { toggleContext } from "App";
import useViewport from "hooks/useViewport";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table } from "reactstrap";
import calenderIcon from "../../assets/img/backgroundImages/calendar_icon.png";
import JobSummaryCard from "components/Cards/JobSummaryCard";
import DriverLogsCard from "components/Cards/DriverLogsCard";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobDetailsThunkAction } from "redux/jobSummary/action";
import { selectJobSummary } from "redux/jobSummary/selectors";
import Loader from "components/Loader/Loader";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { Icon } from "@iconify/react";
import { formatPhoneNumber } from "utils/helper";

const initialDateFilter = {
  end_date: "",
  start_date: ""
};

function JobSummaryDetails() {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const dispatch = useDispatch();
  const { isLoading, error, jobDetails: details } = useSelector(selectJobSummary);
  const { jobId, driverId } = useParams();

  const [jobDetails, setJobDetails] = useState(null);

  const [dateFilter, setDateFilter] = useState({ ...initialDateFilter });

  useEffect(() => {
    dispatch(
      fetchJobDetailsThunkAction(jobId, {
        driver_id: driverId,
        start_date: dateFilter?.start_date,
        end_date: dateFilter?.end_date
      })
    );
  }, [dateFilter]);

  useEffect(() => {
    if (details) {
      let data = {
        ...details,
        loads: details.loads.map((item) => {
          return {
            ...item,
            createdAt: moment(item?.clockIn).format("MMM DD, YYYY")
          };
        })
      };

      data = {
        ...data,
        loads: data.loads.reduce((acc, item) => {
          if (acc[item.createdAt]) {
            acc[item.createdAt].push(item);
          } else {
            acc[item.createdAt] = [item];
          }
          return acc;
        }, {})
      };

      setJobDetails(data);
    }
    () => {
      setJobDetails(null);
    };
  }, [details]);

  const handleValue = (start_date, end_date) => {
    setDateFilter({
      end_date: end_date,
      start_date: start_date
    });
  };

  const showJobStatus = (status) => {
    if (status === null) return "Pending";
    if (status) return "Completed";
    if (!status) return "In Progress";
  };

  return (
    <div className="px-3 px-4">
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Driver Logs
          </h4>
        </Col>
      </Row>

      <Row
        className="bg-white"
        style={{
          borderRadius: "12px"
        }}
      >
        {isLoading ? (
          <Row className="w-100">
            <Col
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "500px"
              }}
            >
              <Loader />
            </Col>
          </Row>
        ) : jobDetails ? (
          <>
            <Col className="d-flex justify-content-between mt-4">
              <h5
                style={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  color: "#565656"
                }}
              >
                {jobDetails?.jobsData?.jobTitle}
              </h5>
              <div
                className="p-2"
                style={{
                  border: "1px solid #FFA302",
                  color: "#FFA302",
                  borderRadius: "10px",
                  backgroundColor: "#FFF6E6"
                }}
              >
                {showJobStatus(jobDetails?.jobsData?.jobStatus)}
              </div>
            </Col>

            <Col className="col-12">
              <JobSummaryCard
                jobId={jobDetails?.jobsData?.id}
                contractor={jobDetails?.jobsData?.createdByUser?.firstName}
                dateTime={
                  jobDetails?.jobsData?.startDate
                    ? moment(jobDetails?.jobsData?.startDate).format("MMM  DD, YYYY | hh:mm A")
                    : "-----"
                }
                totalDrivers={jobDetails?.jobsData?.trucks}
                duration={
                  jobDetails?.jobsData?.duration
                    ? jobDetails?.jobsData?.duration + " days"
                    : "-----"
                }
                totalHours={
                  jobDetails?.jobsData?.endDate
                    ? moment(jobDetails?.jobsData?.endDate).format("MMM  DD, YYYY | hh:mm A")
                    : "-----"
                }
              />

              <hr
                style={{
                  color: "#dbd5d5"
                }}
              />

              <Col
                className="col-12 pl-5 my-3"
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "700",
                  color: "#565656"
                }}
              >
                Driver Details
              </Col>

              <div
                className="d-flex w-100 mt-4 flex-wrap"
                style={{
                  gap: "1rem"
                }}
              >
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#7B7B7B",
                      fontWeight: 700
                    }}
                  >
                    Driver ID
                  </p>
                  <h6
                    style={{
                      fontSize: "1rem",
                      color: "#565656",
                      fontWeight: 700
                    }}
                  >
                    {driverId}
                  </h6>
                  <hr />
                </div>
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#7B7B7B",
                      fontWeight: 700
                    }}
                  >
                    Driver Name
                  </p>
                  <h6
                    style={{
                      fontSize: "1rem",
                      color: "#565656",
                      fontWeight: 700
                    }}
                  >
                    {jobDetails?.firstName}
                  </h6>
                  <hr />
                </div>
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#7B7B7B",
                      fontWeight: 700
                    }}
                  >
                    Company
                  </p>
                  <h6
                    style={{
                      fontSize: "1rem",
                      color: "#565656",
                      fontWeight: 700
                    }}
                  >
                    {jobDetails?.truckDriverDetails?.companyName}
                  </h6>
                  <hr />
                </div>
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#7B7B7B",
                      fontWeight: 700
                    }}
                  >
                    Mobile Number
                  </p>
                  <h6
                    style={{
                      fontSize: "1rem",
                      color: "#565656",
                      fontWeight: 700
                    }}
                  >
                    {formatPhoneNumber(jobDetails?.phone)}
                  </h6>
                  <hr />
                </div>
                <div
                  style={{
                    flexGrow: 1
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#7B7B7B",
                      fontWeight: 700
                    }}
                  >
                    Truck No.
                  </p>
                  <h6
                    style={{
                      fontSize: "1rem",
                      color: "#565656",
                      fontWeight: 700
                    }}
                  >
                    {jobDetails?.truckDriverDetails?.truckNumber}
                  </h6>
                  <hr />
                </div>
              </div>
            </Col>
            <div className="px-3">
              <hr
                style={{
                  color: "#dbd5d5"
                }}
              />
              <Col
                className="col-12 d-flex justify-content-center my-2 p-2"
                style={{
                  backgroundColor: "#F9F9F9",
                  borderRadius: "12px"
                }}
              >
                <div className="d-flex position-relative">
                  <img src={calenderIcon} alt="calender icon" />
                  <Flatpickr
                    required={true}
                    options={{
                      mode: "range",
                      dateFormat: "m-d-Y",
                      disableMobile: true,
                      allowInput: true,
                      position: "below left",
                      onClose: (selectedDates) => {
                        if (selectedDates?.length === 2) {
                          handleValue(
                            moment.utc(moment(selectedDates[0]).startOf("day").format()).format(),
                            moment.utc(moment(selectedDates[1]).endOf("day").format()).format()
                          );
                        }
                      }
                    }}
                    className="flatpickr datetimepicker form-control"
                    placeholder="MM-DD-YYYY"
                    style={{
                      cursor: "pointer"
                    }}
                  />
                </div>
                <div
                  className="text-center"
                  style={{
                    flexGrow: "1",
                    fontSize: "1.1rem",
                    fontWeight: 700,
                    color: "#565656"
                  }}
                >
                  {dateFilter?.start_date ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        columnGap: "10px"
                      }}
                    >
                      <div>
                        {moment(dateFilter.start_date).format("DD MMM, YYYY") +
                          " to " +
                          moment(dateFilter.end_date).format("DD MMM, YYYY")}
                      </div>
                      <Icon
                        icon="ph:eraser-bold"
                        className="pl-4 inline-block"
                        style={{
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setDateFilter({ ...initialDateFilter });
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center">
                      <div>Filter by Date range</div>
                    </div>
                  )}
                </div>
              </Col>
            </div>

            <Col
              className="col-12 p-3"
              style={{
                overflowX: "scroll"
              }}
            >
              <Row
                className="p-0 m-0"
                style={{
                  minWidth: "900px",
                  rowGap: "10px"
                }}
              >
                <Row
                  className="p-2 m-0 w-100"
                  style={{
                    borderRadius: "12px 12px 0 0",
                    backgroundColor: "#FFF6E6"
                  }}
                >
                  <Col className="col-3">Date</Col>
                  <Col className="col-1">Load</Col>
                  <Col className="col-1">Trip Time</Col>
                  <Col className="col-4">Material Name</Col>
                  <Col className="col-1">Loaded</Col>
                  <Col className="col-2">Load Dumped</Col>
                </Row>

                {details?.loads?.map((item) => {
                  return (
                    <Fragment key={item?.date}>
                      {item?.loads?.length !== 0 ? <DriverLogsCard data={item} /> : null}
                    </Fragment>
                  );
                })}

                {Object.keys(jobDetails?.loads).length === 0 ? (
                  <Row className="w-100">
                    <Col
                      className="w-100 d-flex justify-content-center align-items-center"
                      style={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "#565656",
                        minHeight: "100px"
                      }}
                    >
                      No data found.
                    </Col>
                  </Row>
                ) : null}
              </Row>
            </Col>
          </>
        ) : (
          <Row className="w-100">
            <Col
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "500px"
              }}
            >
              {error}
            </Col>
          </Row>
        )}
      </Row>
    </div>
  );
}

export default JobSummaryDetails;
