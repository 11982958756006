import axiosInstance from "./axiosInstance";

export const jobSummaryListingApi = (params) =>
  axiosInstance.get(`/api/v1/admin/allJobs`, {
    params: {
      ...params
    }
  });

export const jobSummaryLogsApi = (jobId, params) =>
  axiosInstance.get(`/api/v1/admin/job-summary/${jobId}`, {
    params: {
      ...params
    }
  });

export const jobSummaryDetailsApi = (jobId, params) =>
  axiosInstance.get(`/api/v1/admin/job-logs/${jobId}`, {
    params: {
      ...params
    }
  });
