import { Button, Badge } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";

export const getColumns = (onClickViewHandler) => {
  return [
    {
      Header: "Id",
      accessor: "id",
      disableSortBy: true
    },
    {
      Header: "Foreman Name",
      accessor: "firstName",
      disableSortBy: true
    },
    {
      Header: "Contact Number",
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.phone)}</span>;
      }
    },
    {
      Header: "Email Id",
      accessor: "email",
      disableSortBy: true
    },
    {
      Header: "Created At",
      accessor: "created_at",
      Cell: ({ row }) => {
        return <span>{moment(row.original.createdAt).format("D MMM YYYY")}</span>;
      }
    },
    {
      Header: "status",
      accessor: "has_job",
      Cell: ({ cell: value }) => {
        if (value.row.original.hasJob === false) {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge pill style={{ color: "white", backgroundColor: "#F03C00" }}>
                No Job
              </Badge>
            </div>
          );
        } else {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge pill style={{ color: "white", backgroundColor: "#00B868" }}>
                Active Job
              </Badge>
            </div>
          );
        }
      }
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              color={row?.original?.hasJob === true ? "secondary" : "danger"}
              outline
              onClick={() => onClickViewHandler(row?.original?.id)}
              disabled={row?.original?.hasJob === true ? true : false}
            >
              <i className="bi bi-trash3"></i>
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
};
