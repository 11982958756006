import React from "react";

import NumberFormat from "react-number-format";
import "../ContractorLogin/ContractorLogin.css";
import usePasswordShow from "../../hooks/usePasswordShow";

import {
  Col,
  FormGroup,
  Row,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";
import "./ContractorRegistration.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../redux/User/selectors";
import { registerUserThunkAction } from "../../redux/User/actions";
import { useNavigate } from "react-router-dom";

import showPasswordIcon from "../../assets/img/theme/showPassword.png";
import hidePasswordIcon from "../../assets/img/theme/hidePassword.png";
import { Icon } from "@iconify/react";

function ContractorRegistration() {
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();
  const { confirmPasswordShown, toggleConfirmPasswordVisibility, type1 } = usePasswordShow();
  const { isSigning } = useSelector(selectUser);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [file, setFile] = React.useState(null);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const onSuccess = () => {
    navigate("/auth/contractor/login");
  };

  const formik = useFormik({
    initialValues: {
      username: location.state?.username,
      email: location.state?.username,
      phone: "",
      password: "",
      confirmPassword: "",
      companyName: "",
      contractorName: "",
      address: "",
      city: "",
      state: "",
      einNumber: "",
      // accountNumber: "",
      serviceContract: ""
    },
    validationSchema: Yup.object().shape({
      contractorName: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Contractor Name Required")
        .min(4, "Name must be atleast 4 Characters"),
      companyName: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Company Name Required")
        .min(4, "Name must be atleast 4 Characters"),
      email: Yup.string().email("Must be a Valid Email").required("Email Required"),
      phone: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Phone Number Required")
        .min(10, "Enter Appropriate Phone Number"),
      address: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Address Required"),
      city: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("City Required"),
      state: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("State Required"),
      password: Yup.string()
        .required("Password Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
          "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmPassword: Yup.string()
        .required("Confirm Password Required")
        .oneOf([Yup.ref("password"), null], "Passwords must match."),
      einNumber: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("EIN Number Required"),
      // accountNumber: Yup.string()
      //   .trim("Cannot include leading and trailing spaces")
      //   .min(16, "Bank Account must be of 16 Characters")
      //   .required("Account Number Required"),
      serviceContract: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Service Contract Required")
    }),
    onSubmit: (values) => {
      const requestBodyFormData = new FormData();

      requestBodyFormData.append("username", values.username);
      requestBodyFormData.append("email", values.email);
      requestBodyFormData.append("phone", values.phone);
      requestBodyFormData.append("password", values.password);
      requestBodyFormData.append("first_name", values.contractorName);
      requestBodyFormData.append("company_name", values.companyName);
      requestBodyFormData.append("dispatcher_name", values.contractorName);
      requestBodyFormData.append("address", values.address);
      requestBodyFormData.append("city", values.city);
      requestBodyFormData.append("state", values.state);
      requestBodyFormData.append("ein_number", values.einNumber);
      // requestBodyFormData.append("bank_account_number", values.accountNumber);
      requestBodyFormData.append("service_contract", values.serviceContract);
      requestBodyFormData.append("file", file);

      dispatch(registerUserThunkAction(requestBodyFormData, onSuccess));
    }
  });

  return (
    <>
      <div className="bg_contractor">
        <div className="bg_shadow">
          <div className="register_form mx-auto">
            <Row className="d-flex text-center justify-content-between px-5 pt-3">
              <h3 className="register_form_title">Register Yourself</h3>
            </Row>
            <form className="register_form_tag" onSubmit={formik.handleSubmit}>
              <Row className="px-lg-5 px-md-4 px-3 py-3">
                <Col lg="6">
                  <FormGroup>
                    <input
                      type="text"
                      className="form-control my-2 container_input"
                      placeholder="Contractor Name"
                      id="contractorName"
                      name="contractorName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contractorName}
                    />
                  </FormGroup>
                  {formik.touched.contractorName && formik.errors.contractorName ? (
                    <div className="text-danger">{formik.errors.contractorName}</div>
                  ) : null}
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <input
                      type="text"
                      className="form-control my-2 container_input"
                      placeholder="Company Name"
                      id="companyName"
                      name="companyName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.companyName}
                    />
                  </FormGroup>
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-danger mt-2">{formik.errors.companyName}</div>
                  ) : null}
                </Col>

                <Col lg="12">
                  <FormGroup className="d-flex">
                    <span className="logo">Company Logo</span>
                    <input
                      type="file"
                      className="form-control my-2 file_attach"
                      placeholder="Company Logo"
                      id="file"
                      name="file"
                      accept="image/*"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <NumberFormat
                      className="form-control my-2 container_input"
                      placeholder="Enter your Phone Number"
                      format="+1(###) ###-##-##"
                      mask="_"
                      id="phone"
                      name="phone"
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          "phone",
                          value
                            .replaceAll("-", "")
                            .replace("(", "")
                            .replace(")", "")
                            .replace(" ", "")
                            .replace("_", "")
                            .replace("+1", "")
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                  </FormGroup>
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-2">{formik.errors.phone}</div>
                  ) : null}
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <input
                      style={{ backgroundColor: "white" }}
                      type="text"
                      className="form-control my-2 container_input"
                      placeholder="Enter Your E-mail"
                      id="email"
                      name="email"
                      disabled
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                  </FormGroup>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-2">{formik.errors.email}</div>
                  ) : null}
                </Col>

                <Col lg="12">
                  <FormGroup>
                    <textarea
                      type="textarea"
                      className="form-control my-2 container_input"
                      placeholder="Enter your Address"
                      id="address"
                      name="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                    />
                  </FormGroup>
                  {formik.touched.address && formik.errors.address ? (
                    <div className="text-danger mt-2">{formik.errors.address}</div>
                  ) : null}
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <input
                      type="text"
                      className="form-control my-2 container_input"
                      placeholder="City"
                      id="city"
                      name="city"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city}
                    />
                  </FormGroup>
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-danger mt-2">{formik.errors.city}</div>
                  ) : null}
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <input
                      type="text"
                      className="form-control my-2 container_input"
                      placeholder="State"
                      id="state"
                      name="state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.state}
                    />
                  </FormGroup>
                  {formik.touched.state && formik.errors.state ? (
                    <div className="text-danger mt-2">{formik.errors.state}</div>
                  ) : null}
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <InputGroup className="input-group-alternative my-2">
                      <Input
                        className="container_input"
                        placeholder="Password"
                        autoComplete="password"
                        type={type}
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <InputGroupAddon
                        addonType="append"
                        style={{ cursor: "pointer", opacity: "0.9" }}
                        onClick={togglePasswordVisiblity}
                      >
                        <InputGroupText className="password_icon">
                          {passwordShown ? (
                            <img
                              style={{ height: "20px" }}
                              alt="hidepassword"
                              src={hidePasswordIcon}
                            />
                          ) : (
                            <img
                              style={{ height: "20px" }}
                              alt="showpassword"
                              src={showPasswordIcon}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mt-2">{formik.errors.password}</div>
                  ) : null}
                </Col>

                <Col lg="6">
                  <FormGroup>
                    <InputGroup className="input-group-alternative my-2">
                      <Input
                        className="container_input"
                        placeholder="Confirm Password"
                        autoComplete="confirmPassword"
                        type={type1}
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />
                      <InputGroupAddon
                        addonType="append"
                        style={{ cursor: "pointer", opacity: "0.9" }}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <InputGroupText className="password_icon">
                          {confirmPasswordShown ? (
                            <img
                              style={{ height: "20px" }}
                              alt="hidepassword"
                              src={hidePasswordIcon}
                            />
                          ) : (
                            <img
                              style={{ height: "20px" }}
                              alt="showpassword"
                              src={showPasswordIcon}
                            />
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="text-danger mt-2">{formik.errors.confirmPassword}</div>
                  ) : null}
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <input
                      type="text"
                      className="form-control my-2 container_input"
                      placeholder="Enter EIN-Number"
                      id="einNumber"
                      name="einNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.einNumber}
                    />
                  </FormGroup>
                  {formik.touched.einNumber && formik.errors.einNumber ? (
                    <div className="text-danger mt-2">{formik.errors.einNumber}</div>
                  ) : null}
                </Col>

                {/* <Col lg="6">
                  <FormGroup>
                    <InputGroup className="input-group-alternative my-2">
                      <InputGroupAddon
                        addonType="prepend"
                        style={{ cursor: "pointer", opacity: "0.9" }}
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <InputGroupText className="password_icon account_tooltip">
                          <UncontrolledTooltip delay={0} placement="left" target="tooltip611234743">
                            Tooltip Content
                          </UncontrolledTooltip>

                          <div data-placement="top" id="tooltip611234743">
                            <Icon icon="mdi:information-variant" color="white" width="24" />
                          </div>
                        </InputGroupText>
                      </InputGroupAddon>

                      <NumberFormat
                        className="form-control container_input"
                        format="#### #### #### #### ####"
                        placeholder="Enter Bank account number"
                        id="accountNumber"
                        name="accountNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.accountNumber}
                      />
                    </InputGroup>
                  </FormGroup>
                  {formik.touched.accountNumber && formik.errors.accountNumber ? (
                    <div className="text-danger mt-2">{formik.errors.accountNumber}</div>
                  ) : null}
                </Col> */}

                <Col lg="6">
                  <FormGroup>
                    <input
                      type="number"
                      className="form-control my-2 container_input"
                      placeholder="Service Contract in Years"
                      min="0.5"
                      step={0.1}
                      id="serviceContract"
                      name="serviceContract"
                      onWheel={(e) => e.target.blur()}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceContract}
                    />
                  </FormGroup>
                  {formik.touched.serviceContract && formik.errors.serviceContract ? (
                    <div className="text-danger mt-2">{formik.errors.serviceContract}</div>
                  ) : null}
                </Col>
              </Row>
              <Row lg="4">
                <button
                  disabled={!formik.isValid || isSigning}
                  className="btn contractor_register_btn mx-auto mb-5 mt-2 "
                  type="submit"
                >
                  {isSigning ? "Registering..." : "Submit Your Details"}
                </button>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContractorRegistration;
