import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];
  while (i <= to) {
    range.push(i);
    i += step;
  }
  return range;
};
const Paginate = ({ pageCount, pageIndex, gotoPage, previousPage, nextPage }) => {
  const fetchPageNumbers = () => {
    const pageNeighbours = 2;
    const totalNumbers = pageNeighbours;
    const totalBlocks = Math.min(totalNumbers, pageCount);
    const currentPage = pageIndex;
    const totalPages = pageCount;
    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);
      let pages = range(startPage, endPage);
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);
      switch (true) {
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }
      return [...pages];
    }
    return range(1, totalPages);
  };
  const pages = fetchPageNumbers();
  return (
    <>
      <Fragment>
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
          style={{
            backgroundColor: "#E7F1FD",
            borderRadius: "20px",
            position: "relative",
            marginRight: "2.4rem"
          }}
        >
          {pages.map((page) => {
            if (page === LEFT_PAGE)
              return (
                <PaginationItem key={page}>
                  <PaginationLink
                    onClick={() => previousPage()}
                    style={{
                      backgroundColor: "#E7F1FD",
                      color: "#1273EB",
                      border: "none",
                      position: "absolute",
                      borderRadius: "50%",
                      left: "-50px"
                    }}
                  >
                    <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
                  </PaginationLink>
                </PaginationItem>
              );
            if (page === RIGHT_PAGE)
              return (
                <PaginationItem key={page}>
                  <PaginationLink
                    onClick={() => nextPage()}
                    style={{
                      backgroundColor: "#E7F1FD",
                      color: "#1273EB",
                      border: "none",
                      position: "absolute",
                      borderRadius: "50%",
                      right: "-50px"
                    }}
                  >
                    <Icon icon="material-symbols:arrow-forward-ios-rounded" />
                  </PaginationLink>
                </PaginationItem>
              );
            return (
              <PaginationItem
                key={page}
                className={page - 1 === pageIndex ? "active" : ""}
                style={
                  page - 1 === pageIndex
                    ? {
                        backgroundColor: "#1273EB",
                        color: "white !important",
                        transform: "scale(1.1)",
                        borderRadius: "50%",
                        width: "100%"
                      }
                    : null
                }
              >
                <PaginationLink
                  onClick={() => gotoPage(page - 1)}
                  style={
                    page - 1 === pageIndex
                      ? {
                          border: "none",
                          background: "transparent",
                          color: "#fff",
                          borderRadius: "50%",
                          lineHeight: "1"
                        }
                      : {
                          border: "none",
                          background: "transparent",
                          color: "#363636",
                          borderRadius: "50%"
                        }
                  }
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            );
          })}
        </Pagination>
      </Fragment>
    </>
  );
};
export default Paginate;
