import moment from "moment";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";

function DropDown({ direction = "down", handleValue, ...args }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("All");

  const [cutumDateRange, setCutumDateRange] = useState(null);

  let isSelected = false;

  return (
    <div
      className="w-full"
      style={{
        width: "100%",
        minWidth: "10rem"
      }}
    >
      <Dropdown
        color="primary"
        isOpen={dropdownOpen}
        toggle={() => {
          if (isSelected) {
            if (dropdownOpen === false) {
              setDropdownOpen(true);
            }
          } else {
            setDropdownOpen((preState) => !preState);
          }
        }}
        style={{
          width: "100%"
        }}
        direction={direction}
      >
        <DropdownToggle
          style={{ backgroundColor: "white", color: "black", width: "100%" }}
          className="d-flex justify-content-between"
        >
          {selectedValue ? selectedValue : "Select"}
          <span className="inline-block ml-3">
            {"  "}
            <Icon icon="teenyicons:down-outline" />
          </span>
        </DropdownToggle>
        <DropdownMenu
          {...args}
          // right
          // positionFixed
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            width: "100%"
          }}
        >
          <DropdownItem
            onClick={(e) => {
              isSelected = true;
              e.stopPropagation();
              handleValue("", "");
              setSelectedValue("All");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            All
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              isSelected = true;
              e.stopPropagation();
              handleValue(
                moment.utc(moment().startOf("day")).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Today");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Today
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(1, "month").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last Month");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last Month
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(3, "month").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last 3 Month");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last 3 Month
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(6, "month").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last 6 Month");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last 6 Month
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              isSelected = true;
              handleValue(
                moment.utc(moment().subtract(1, "year").startOf("day").format()).format(),
                moment.utc(moment().endOf("day")).format()
              );
              setSelectedValue("Last Year");
              setDropdownOpen(false);
              if (cutumDateRange) {
                setCutumDateRange(null);
              }
            }}
          >
            Last Year
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

DropDown.propTypes = {
  direction: PropTypes.string
};

export default DropDown;
