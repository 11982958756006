import React from "react";

function Label({ children }) {
  return (
    <p
      style={{
        fontSize: "0.9rem",
        fontWeight: 500,
        color: "#7B7B7B"
      }}
    >
      {children}
    </p>
  );
}

function Value({ children }) {
  return (
    <h6
      style={{
        fontSize: "1rem",
        fontWeight: 700,
        color: "#565656"
      }}
    >
      {children}
    </h6>
  );
}

function JobSummaryCard({ jobId, contractor, dateTime, totalDrivers, duration, totalHours }) {
  return (
    <>
      <div
        className="d-flex w-100 mt-4 flex-wrap"
        style={{
          gap: "1rem"
        }}
      >
        <div
          style={{
            flexGrow: 1
          }}
        >
          <Label>Job ID</Label>
          <Value>{jobId}</Value>
          <hr />
        </div>
        <div
          style={{
            flexGrow: 1
          }}
        >
          <Label>Contractor</Label>
          <Value>{contractor}</Value>
          <hr />
        </div>
        <div
          style={{
            flexGrow: 1
          }}
        >
          <Label>Start Date & Time</Label>
          <Value>{dateTime}</Value>
          <hr />
        </div>

        <div
          style={{
            flexGrow: 1
          }}
        >
          <Label>End Date & Time</Label>
          <Value>{totalHours}</Value>
          <hr />
        </div>
        <div
          style={{
            flexGrow: 1
          }}
        >
          <Label>Total Drivers</Label>
          <Value>{totalDrivers}</Value>
          <hr />
        </div>
        <div
          style={{
            flexGrow: 1
          }}
        >
          <Label>Duration</Label>
          <Value>{duration}</Value>
          <hr />
        </div>
      </div>
    </>
  );
}

export default JobSummaryCard;
