import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Button, Form, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

function NotesModal({ open, closeModal, noteUser, submit }) {
  const [note, setNote] = useState("");
  const { pathname } = useLocation();
  const isContractorPage = pathname.includes("contractor");
  const closeBtn = (
    <div
      onClick={clearNoteHandler}
      type="button"
      style={{
        color: "#F03C00"
      }}
    >
      Clear Notes
    </div>
  );

  function submitHandler(event) {
    event.preventDefault();
    submit(note);
  }

  useEffect(() => {
    if (noteUser?.isNote) {
      setNote(noteUser?.userNote?.description);
    } else {
      setNote("");
    }
  }, [noteUser]);

  async function clearNoteHandler() {
    try {
      const result = await Swal.fire({
        showCancelButton: true,
        confirmButtonText: "Cancel",
        cancelButtonText: "Yes",
        position: "top",
        width: "28rem",
        customClass: {
          confirmButton: "custom-confirm-button",
          cancelButton: "custom-cancel-button",
          title: "title-class",
          icon: "icon-class",
          htmlContainer: "custom-html-container",
          actions: "custom-actions",
          popup: "custom-popup"
        },
        html: `
          <div>
          <h4 style="text-align:left;color:#7B7B7B;">Are You Sure?</h4>
          <p style="text-align:left;color:#7B7B7B;">Confirm clearing Notes for this user?</p>
          </div>
        `
      });

      if (!result.isConfirmed && result.dismiss !== "backdrop") {
        setNote("");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal isOpen={open} centered toggle={closeModal} backdrop={"static"}>
      <Form inline onSubmit={submitHandler}>
        <ModalHeader
          toggle={closeModal}
          close={closeBtn}
          style={{
            color: "#7B7B7B",
            borderBottom: "0px"
          }}
        >
          Notes
        </ModalHeader>
        <ModalBody>
          <div
            className="w-100 mb-4 px-3 p-1 d-flex flex-column justify-content-between"
            style={{
              borderRadius: "0.8rem",
              backgroundColor: "#FFF6E6",
              minHeight: "4.5rem"
            }}
          >
            <div style={{ color: "#7B7B7B" }}>#{noteUser?.id}</div>
            <div className="d-flex gap-1" style={{ color: "#565656" }}>
              <h5>{noteUser?.firstName}</h5> |{" "}
              <h5>
                {isContractorPage
                  ? noteUser?.contractorDetails?.companyName
                  : noteUser?.companyName}
              </h5>
            </div>
          </div>

          <Input
            className="notes_textarea"
            type="textarea"
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Add your notes here..."
            rows={10}
            maxLength={10000}
          />

          {noteUser?.userNote ? (
            <div
              className="mt-2 d-flex justify-content-between"
              style={{
                fontSize: "0.7rem",
                color: "#7B7B7B"
              }}
            >
              <div>
                Last Updated :
                {moment(noteUser?.userNote?.updatedAt).format("MMM  DD, YYYY | hh:mm A")}
              </div>
              <div>
                <i>{note?.length + "/" + 10000}</i>
              </div>
            </div>
          ) : (
            <div
              className="mt-2 d-flex justify-content-end"
              style={{
                fontSize: "0.7rem",
                color: "#7B7B7B"
              }}
            >
              <i>{note?.length + "/" + 10000}</i>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between" style={{ border: "0px" }}>
          <Button color="btn btn-outline-danger" type="button" onClick={closeModal}>
            Discard
          </Button>
          <Button
            color="btn"
            type="submit"
            className="px-4 py-2 d-flex align-items-center"
            style={{
              whiteSpace: "nowrap",
              borderRadius: "10px",
              cursor: "pointer",
              columnGap: "6px",
              backgroundColor: "#FFA302",
              color: "white"
            }}
          >
            <b> Save / Update</b>
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default NotesModal;
