export const DASHBOARD_STATISTICS_REQUEST = "DASHBOARD_STATISTICS_REQUEST";
export const DASHBOARD_STATISTICS_SUCCESS = "DASHBOARD_STATISTICS_SUCCESS";
export const DASHBOARD_STATISTICS_FAILURE = "DASHBOARD_STATISTICS_FAILURE";

export const DASHBOARD_STATISTICS_TWO_REQUEST = "DASHBOARD_STATISTICS_TWO_REQUEST";
export const DASHBOARD_STATISTICS_TWO_SUCCESS = "DASHBOARD_STATISTICS_TWO_SUCCESS";
export const DASHBOARD_STATISTICS_TWO_FAILURE = "DASHBOARD_STATISTICS_TWO_FAILURE";

export const LOG_DETAILS_REQUEST = "LOG_DETAILS_REQUEST";
export const LOG_DETAILS_SUCCESS = "LOG_DETAILS_SUCCESS";
export const LOG_DETAILS_FAILURE = "LOG_DETAILS_FAILURE";
