import React from "react";
import { Col } from "reactstrap";

function StatisticsCardTwo({ image, title, value }) {
  return (
    <>
      <div
        className="d-flex mb-3"
        style={{
          columnGap: "2.5rem"
        }}
      >
        <img
          src={image}
          alt="total active jobs"
          style={{
            height: "100%"
          }}
        />
        <div>
          <h3
            style={{
              color: "#FFA302"
            }}
          >
            {value}
          </h3>
          <h5
            style={{
              color: "#7B7B7B",
              fontSize: "1rem"
            }}
          >
            {title}
          </h5>
        </div>
      </div>
    </>
  );
}

export default StatisticsCardTwo;
