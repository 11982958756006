import React from "react";

const usePasswordShow = () => {
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = React.useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown((preValue) => !preValue);
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown((preValue) => !preValue);
  };

  const type = passwordShown ? "text" : "password";
  const type1 = confirmPasswordShown ? "text" : "password";

  return {
    passwordShown,
    togglePasswordVisiblity,
    type,
    confirmPasswordShown,
    toggleConfirmPasswordVisibility,
    type1
  };
};

export default usePasswordShow;
