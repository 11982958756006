import { toggleContext } from "../../App";
import React from "react";
import { NavLink } from "react-router-dom";
import useViewport from "../../hooks/useViewport";
import { Icon } from "@iconify/react";

const Sidebaritems = ({ item }) => {
  const [open, setOpen] = React.useState(false);
  const { isopen, setisOpen } = React.useContext(toggleContext);
  const { width } = useViewport();

  if (item.childrens) {
    return (
      <div className={open ? "open" : "sidebar-item"}>
        <div className="sidebar-title" onClick={() => setOpen(!open)}>
          <span>
            {item.icon && <i className={item.icon}></i>}
            <span className={isopen ? "mx-2" : "d-none"}>{item.title}</span>
          </span>
          <i className="bi-chevron-down toggle-btn"></i>
        </div>
        <div className="sidebar-content">
          {item.childrens.map((item, index) => (
            <NavLink
              key={index}
              to={item.path || "/"}
              className={(navData) =>
                navData.isActive ? "sidebar-item plain childactive" : "sidebar-item plain"
              }
              onClick={() => setisOpen(false)}
            >
              {item.icon && <i className={`${item.icon} small ml-2`}></i>}
              <span className={isopen ? "mx-2" : "d-none"}>{item.title}</span>
            </NavLink>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <NavLink
        to={item.path || "/"}
        className={(navData) =>
          navData.isActive ? "sidebar-item plain active align-items-center" : "sidebar-item plain"
        }
        onClick={() => {
          width < 785 ? setisOpen(false) : setisOpen(true);
        }}
      >
        {({ isActive }) =>
          isActive ? (
            <>
              {item && <img style={{ marginLeft: "-5px" }} src={item.active} width="25px" />}
              <span className={isopen ? "mx-2" : "d-none"}>{item.title}</span>
            </>
          ) : (
            <>
              {item && <img style={{ marginLeft: "-5px" }} src={item.inActive} width="25px" />}
              <span className={isopen ? "mx-2" : "d-none"}>{item.title}</span>
            </>
          )
        }
      </NavLink>
    );
  }
};

export default Sidebaritems;
