import { Icon } from "@iconify/react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { toggleContext } from "App";
import useViewport from "hooks/useViewport";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Input, Row, Table } from "reactstrap";
import "./jobSummary.css";
import { useNavigate } from "react-router-dom";
import JobSummaryCard from "components/Cards/JobSummaryCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobSummaryListingThunkAction } from "redux/jobSummary/action";
import { selectJobSummary } from "redux/jobSummary/selectors";
import Paginate from "components/Pagination/Pagination";
import Loader from "components/Loader/Loader";
import { debounce } from "lodash";
import moment from "moment";
import { fetchJobSummaryLogsThunkAction } from "redux/jobSummary/action";
import { convertToReadableTime } from "utils/helper";

function JobSummary() {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [summaryLogsCurrentPage, setSummaryLogsCurrentPage] = useState(0);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(undefined);
  const [jobId, setJobId] = useState(null);
  const [accordionState, setAccordionState] = useState(null);

  const dispatch = useDispatch();
  const {
    isLoading,
    jobs,
    error,
    totalPages,
    isSummaryLogsLoading,
    summaryLogs,
    summaryLogsTotalPages,
    summaryLogsError
  } = useSelector(selectJobSummary);

  useEffect(() => {
    dispatch(
      fetchJobSummaryListingThunkAction({
        page: currentPage,
        search: search,
        limit: 10,
        filter: null
      })
    );
  }, []);

  const handleCustomChange = (id) => {
    setJobId(id.split("_")[0]);
    setSummaryLogsCurrentPage(0);
    setAccordionState((preId) => (preId === id ? null : id));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);

    dispatch(
      fetchJobSummaryListingThunkAction({
        page: page,
        search: search,
        limit: 10,
        filter: filter
      })
    );
  };

  const handlePreviousPage = () => {
    if (currentPage === 0) {
      return;
    }

    dispatch(
      fetchJobSummaryListingThunkAction({
        page: currentPage - 1,
        search: search,
        limit: 10,
        filter: filter
      })
    );

    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage === totalPages - 1) {
      return;
    }

    dispatch(
      fetchJobSummaryListingThunkAction({
        page: currentPage + 1,
        search: search,
        limit: 10,
        filter: filter
      })
    );

    setCurrentPage(currentPage + 1);
  };

  const handleSummaryLogsPageChange = (page) => {
    setSummaryLogsCurrentPage(page);
  };

  const handleSummaryLogsPreviousPage = () => {
    if (summaryLogsCurrentPage === 0) {
      return;
    }
    setSummaryLogsCurrentPage(summaryLogsCurrentPage - 1);
  };

  const handleSummaryLogsNextPage = () => {
    if (summaryLogsCurrentPage === summaryLogsTotalPages - 1) {
      return;
    }
    setSummaryLogsCurrentPage(summaryLogsCurrentPage + 1);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      dispatch(
        fetchJobSummaryListingThunkAction({
          page: 0,
          search: nextValue,
          limit: 10,
          filter: filter
        })
      );

      setCurrentPage(0);
      setSearch(nextValue);
    }, 500),
    [filter]
  );

  function onSearchHandler(event) {
    setValue(event.target.value);
    debouncedSave(event.target.value);
  }

  function filterHandler(e) {
    let filterValue;
    if (e.target.value === "all") {
      filterValue = undefined;
    } else {
      filterValue = e.target.value;
    }
    setFilter(filterValue);
    setCurrentPage(0);

    dispatch(
      fetchJobSummaryListingThunkAction({
        page: 0,
        search: search,
        limit: 10,
        filter: filterValue
      })
    );
  }

  const filterValue = () => {
    if (filter === undefined) return "all";
    return filter;
  };

  const showJobStatus = (status) => {
    if (status === null) return "Pending";
    if (status) return "Completed";
    if (!status) return "In Progress";
  };

  useEffect(() => {
    if (jobId) {
      dispatch(
        fetchJobSummaryLogsThunkAction(jobId, {
          page: summaryLogsCurrentPage,
          limit: 5
        })
      );
    }
  }, [jobId, summaryLogsCurrentPage]);

  return (
    <div className="px-3">
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col className="d-flex flex-wrap justify-content-between w-100 align-items-center mt-4">
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center"
                  }
            }
          >
            Job Summary
          </h4>

          <div
            className="d-flex align-items-center"
            style={{
              columnGap: "6px"
            }}
          >
            <Form
              role="form"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="position-relative">
                <Icon
                  icon="ion:search-outline"
                  width={24}
                  className="position-absolute"
                  style={{
                    top: "7px",
                    left: "5px",
                    color: "#FFA302"
                  }}
                />
                <Input
                  placeholder="Search job title or contractor name"
                  type="text"
                  style={{
                    width: "15rem",
                    paddingLeft: "2rem"
                  }}
                  value={value}
                  onChange={(e) => onSearchHandler(e)}
                />
              </div>
            </Form>
            <Input
              type="select"
              style={{
                appearance: "auto",
                borderRadius: "10px",
                cursor: "pointer",
                minWidth: "100px"
              }}
              value={filterValue()}
              onChange={filterHandler}
              placeholder="Filter"
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="inprogress">In Progress</option>
              <option value="completed">Completed</option>
            </Input>
          </div>
        </Col>

        {isLoading ? (
          <Row className="w-100">
            <Col
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "500px"
              }}
            >
              <Loader />
            </Col>
          </Row>
        ) : jobs ? (
          <>
            <Col className="w-100 mt-4 col-12 px-2 mx-0">
              {jobs?.map((job, index) => {
                const accordianId = job?.id + "_panel";
                return (
                  <Accordion key={job?.id} expanded={accordionState === accordianId}>
                    <AccordionSummary
                      aria-controls={accordianId + "bh-content"}
                      id={accordianId + "bh-header"}
                      style={{
                        cursor: "default"
                      }}
                    >
                      <div className="w-100">
                        <div className="w-100 d-flex justify-content-between align-items-center flex-wrap">
                          <div
                            style={{
                              fontSize: "1.3rem",
                              fontWeight: 700,
                              color: "#565656"
                            }}
                          >
                            {job?.jobTitle}
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              columnGap: "5px"
                            }}
                          >
                            <div
                              className="p-2 px-3"
                              style={{
                                borderRadius: "50px",
                                border: "1px solid #FFA302",
                                backgroundColor: "#FFF6E6",
                                color: "#FFA302"
                              }}
                            >
                              {showJobStatus(job?.jobStatus)}
                            </div>
                            {accordionState === accordianId ? (
                              <div
                                className="p-2 px-3"
                                style={{
                                  borderRadius: "50px",
                                  background:
                                    "linear-gradient(22.53deg, #FFA302 12.1%, #FFF6E7 186.98%)",
                                  color: "#ffffff",
                                  cursor: "pointer"
                                }}
                                onClick={() => handleCustomChange(accordianId)}
                              >
                                View Less
                                <Icon icon="icon-park-outline:up" width={24} />
                              </div>
                            ) : (
                              <div
                                className="p-2 px-3"
                                style={{
                                  borderRadius: "50px",
                                  background:
                                    "linear-gradient(22.53deg, #FFA302 12.1%, #FFF6E7 186.98%)",
                                  color: "#ffffff",
                                  cursor: "pointer"
                                }}
                                onClick={() => handleCustomChange(accordianId)}
                              >
                                View More
                                <Icon icon="icon-park-outline:down" width={24} />
                              </div>
                            )}
                          </div>
                        </div>
                        <JobSummaryCard
                          jobId={job?.id}
                          contractor={job?.createdByUser?.firstName}
                          dateTime={
                            job?.startDate
                              ? moment(job?.startDate).format("MMM  DD, YYYY | hh:mm A")
                              : "-----"
                          }
                          totalDrivers={job?.trucks}
                          duration={job?.duration ? job?.duration + " days" : "-----"}
                          totalHours={
                            job?.endDate
                              ? moment(job?.endDate).format("MMM  DD, YYYY | hh:mm A")
                              : "-----"
                          }
                        />
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        overflowX: "scroll"
                      }}
                    >
                      {isSummaryLogsLoading ? (
                        <Row className="w-100">
                          <Col
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{
                              minHeight: "200px"
                            }}
                          >
                            <Loader />
                          </Col>
                        </Row>
                      ) : summaryLogs ? (
                        <>
                          <Table borderless>
                            <thead
                              style={{
                                backgroundColor: "#FFF6E6"
                              }}
                            >
                              <tr>
                                <th>ID</th>
                                <th>Driver Name</th>
                                <th>Loads</th>
                                <th>Effective Hours</th>
                                <th>Net Payable</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {summaryLogs?.map((log) => {
                                return (
                                  <tr key={log?.id}>
                                    <td>{log?.userId}</td>
                                    <td>{log?.user?.firstName}</td>
                                    <td>{log?.loads ? log?.loads : "-----"}</td>
                                    <td>
                                      {log?.hours ? convertToReadableTime(log?.hours) : "-----"}
                                    </td>
                                    <td>
                                      {log?.totalPayable ? "$ " + log?.totalPayable : "-----"}
                                    </td>
                                    <td>
                                      <div
                                        className="p-2"
                                        style={{
                                          backgroundColor: "#FFF6E6",
                                          borderRadius: "20px",
                                          textAlign: "center",
                                          color: "#FFA302",
                                          cursor: "pointer"
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/admin/job/${job?.id}/driver-logs/${log?.userId}`
                                          )
                                        }
                                      >
                                        View
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>

                          <Col className="col-12 w-100">
                            <div className="d-flex justify-content-end p-2">
                              {isSummaryLogsLoading === false && summaryLogsError === "" ? (
                                <nav aria-label="..." className="post_footer">
                                  <Paginate
                                    pageCount={summaryLogsTotalPages}
                                    pageIndex={summaryLogsCurrentPage}
                                    previousPage={handleSummaryLogsPreviousPage}
                                    nextPage={handleSummaryLogsNextPage}
                                    gotoPage={handleSummaryLogsPageChange}
                                  />
                                </nav>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      ) : (
                        <Row className="w-100">
                          <Col
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{
                              minHeight: "500px"
                            }}
                          >
                            {summaryLogsError}
                          </Col>
                        </Row>
                      )}

                      {isSummaryLogsLoading === false && summaryLogs?.length === 0 ? (
                        <Row className="w-100">
                          <Col
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{
                              fontSize: "1rem",
                              fontWeight: 500,
                              color: "#565656"
                            }}
                          >
                            No data found.
                          </Col>
                        </Row>
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Col>
          </>
        ) : (
          <Row className="w-100">
            <Col
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "500px"
              }}
            >
              {error}
            </Col>
          </Row>
        )}

        {isLoading === false && jobs?.length === 0 ? (
          <Row className="w-100">
            <Col
              className="w-100 d-flex justify-content-center align-items-center"
              style={{
                minHeight: "500px"
              }}
            >
              No data found.
            </Col>
          </Row>
        ) : null}

        <Col className="col-12 w-100">
          <div className="d-flex justify-content-end p-2">
            {isLoading === false && error === "" ? (
              <nav aria-label="..." className="post_footer">
                <Paginate
                  pageCount={totalPages}
                  pageIndex={currentPage}
                  previousPage={handlePreviousPage}
                  nextPage={handleNextPage}
                  gotoPage={handlePageChange}
                />
              </nav>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default JobSummary;
