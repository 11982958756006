import React, { useState, useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Col, Row, Input, Button, Card, CardBody } from "reactstrap";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DeleteJobModal from "./DeleteJobModal";
import { fetchForemenList } from "../../redux/Contractor/selectors";
import { JobDetailsThunkAction, updateJobThunkAction } from "../../redux/Contractor/action";
import Loader from "../../components/Loader/Loader";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";

const JobPostingDetails = () => {
  const { isLoading, jobDetails } = useSelector(fetchForemenList);

  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const [isEdit, setIsEdit] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [foremanId, setForemanId] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [error, setError] = useState("");
  const [rateError, setRateError] = useState("");
  const [payment, setPayment] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobError, setJobError] = useState("");

  const paymentMethod = ["Daily", "Weekly", "Monthly"];

  const dispatch = useDispatch();

  const { id: jobId } = useParams();

  useEffect(() => {
    dispatch(JobDetailsThunkAction(jobId));
  }, []);

  useEffect(() => {
    if (jobDetails) {
      setForemanId(jobDetails?.assignedToUser?.id);
      setHourlyRate(jobDetails?.hourlyRate);
      setPayment(jobDetails?.paymentMethod);
      setJobTitle(jobDetails?.jobTitle);
    }
  }, [jobDetails]);

  const onSuccess = () => {
    dispatch(JobDetailsThunkAction(jobId));
    setIsEdit(false);
  };

  const UpdateJob = (event) => {
    event.preventDefault();
    if (jobTitle.trim() === "") {
      setJobError("Please Enter Job Title!");
    } else if (hourlyRate === "") {
      setRateError("Please Enter Hourly Rate!");
    } else {
      const requestBody = {
        foreman_id: foremanId,
        hourly_rate: +hourlyRate,
        payment_method: payment,
        job_title: jobTitle
      };
      dispatch(updateJobThunkAction(jobId, requestBody, onSuccess));
    }
  };

  return (
    <div className="px-3" style={{ height: "100%", backgroundColor: "#F9F9F9" }}>
      <div
        className={width > 786 || isopen ? "d-flex justify-content-between my-3" : "mx-4 header"}
      >
        <h4
          style={
            width > 786 || isopen
              ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
              : {
                  color: "#1A1A1A",
                  fontWeight: "bolder",
                  textAlign: "center",
                  marginTop: "17px",
                  paddingTop: "10px"
                }
          }
        >
          Job Details
        </h4>

        <h4
          style={
            width > 786 || isopen
              ? { color: "#667080", fontWeight: "bolder", marginTop: "20px" }
              : {
                  textAlign: "end",
                  marginRight: "-6%",
                  marginTop: "20px"
                }
          }
        >
          {!isEdit ? (
            <Button
              size="md"
              color="secondary"
              outline
              onClick={() => {
                setIsEdit(true);
              }}
            >
              <i
                style={width > 786 || isopen ? { marginRight: "8px" } : { display: "none" }}
                className="bi bi-pencil-square"
              ></i>
              Edit
            </Button>
          ) : (
            ""
          )}
        </h4>
      </div>
      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <form onSubmit={UpdateJob}>
            <Card className="mt-4" style={{ background: "#ffffff" }}>
              <CardBody>
                <Row>
                  <Col className="mx-3" style={{ fontStyle: "normal" }}>
                    <Row className="my-2">
                      <Col xs="12" lg="6" md="6" className="mb-2">
                        <Row>
                          <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                            Foreman Name
                          </Col>
                          <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="6" lg="6">
                            <div>{jobDetails?.assignedToUser?.firstName}</div>
                          </Col>
                          <div className="text-danger mt-2">{error}</div>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-2">
                      <Col xs="12" lg="6" md="6" className="mb-2">
                        <Row>
                          <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                            Foreman Contact Number
                          </Col>
                          <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="6" lg="6">
                            <div>{formatPhoneNumber(jobDetails?.assignedToUser?.phone)}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col xs="12" lg="6" md="6" className="mb-2">
                        <Row>
                          <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                            Job Title
                          </Col>
                          <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="6" lg="6">
                            {isEdit ? (
                              <Input
                                type="text"
                                placeholder="Job Title"
                                name="jobTitle"
                                value={jobTitle}
                                onChange={(e) => {
                                  setJobTitle(e.target.value);
                                  setJobError("");
                                }}
                              />
                            ) : (
                              <div>{jobDetails?.jobTitle}</div>
                            )}
                          </Col>
                          <div className="text-danger mt-2">{jobError}</div>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col xs="12" lg="6" md="6" className="mb-2">
                        <Row>
                          <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                            Hourly Rate ($)
                          </Col>
                          <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="6" lg="6">
                            {isEdit ? (
                              <Input
                                type="number"
                                placeholder="Hourly Rate"
                                name="hourly_rate"
                                min="1"
                                step="0.01"
                                value={hourlyRate}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => {
                                  setHourlyRate(e.target.value);
                                  setRateError("");
                                }}
                              />
                            ) : (
                              <div>{jobDetails?.hourlyRate}</div>
                            )}
                          </Col>
                          <div className="text-danger mt-2">{rateError}</div>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="my-3">
                      <Col xs="12" lg="6" md="6" className="mb-2">
                        <Row>
                          <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                            Payment Method
                          </Col>
                          <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className={isEdit ? "" : "value"} xs="6" md="6" lg="6">
                            {isEdit ? (
                              <Input
                                type="select"
                                style={{ cursor: "pointer", appearance: "auto" }}
                                onChange={(e) => {
                                  setPayment(e.target.value);
                                }}
                                defaultValue={payment}
                              >
                                {paymentMethod.map((pageSize) => (
                                  <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                  </option>
                                ))}
                              </Input>
                            ) : (
                              jobDetails?.paymentMethod
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mt-4" style={{ background: "#ffffff" }}>
              <CardBody>
                <Row>
                  <Col className="mx-3" style={{ fontStyle: "normal" }}>
                    <Row className="mt-2 ">
                      <Col xs="12" lg="12" md="12" className="mb-2">
                        <Row className="my-3">
                          <Col className="label" xs="5" md="2" lg="2">
                            Job Id
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.id}
                          </Col>
                          <Col className="label" xs="5" md="2" lg="2">
                            Material name (Type)
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.materialList ? jobDetails?.materialList : "-----"}
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col className="label" xs="5" md="2" lg="2">
                            Start Date
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.startDate
                              ? moment(jobDetails?.startDate).format("D MMM YYYY")
                              : "-----"}
                          </Col>

                          <Col className="label" xs="5" md="2" lg="2">
                            Experience
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.jobExperience
                              ? jobDetails?.jobExperience + " Years"
                              : "-----"}
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col className="label" xs="5" md="2" lg="2">
                            Duration
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.duration ? jobDetails?.duration + " days" : "-----"}
                          </Col>

                          <Col className="label" xs="5" md="2" lg="2">
                            Equipment Type
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.equipmentList ? jobDetails?.equipmentList : "-----"}
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col className="label" xs="5" md="2" lg="2">
                            Material Pick up site Location
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.pickupLocation?.length > 0
                              ? jobDetails?.pickupLocation?.map((item, index) => (
                                  <div key={item.id}>
                                    <b>Location{` ${index + 1} : `}</b>
                                    {item.location}
                                  </div>
                                ))
                              : "-----"}
                          </Col>

                          <Col className="label" xs="5" md="2" lg="2">
                            Dump site Location
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.dumpLocation?.length > 0
                              ? jobDetails?.dumpLocation?.map((item, index) => (
                                  <div key={item.id}>
                                    <b>Location{` ${index + 1} : `}</b>
                                    {item.location}
                                  </div>
                                ))
                              : "-----"}
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col className="label" xs="5" md="2" lg="2">
                            Weekend Required
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {jobDetails?.weekendRequired === true ? "Yes" : "No"}
                          </Col>

                          <Col className="label" xs="5" md="2" lg="2">
                            Number of Trucks required
                          </Col>
                          <Col xs="1" md="1" lg="1">
                            :
                          </Col>
                          <Col className="value" xs="6" md="3" lg="3">
                            {JSON.parse(jobDetails?.trucks || "[]")?.length ? (
                              <>
                                {JSON.parse(jobDetails?.trucks)?.map((truck) => {
                                  return (
                                    <>
                                      <div>
                                        {truck.name} - {truck.value}/{truck.total}
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            ) : (
                              "-----"
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="mt-3" style={{ background: "#ffffff" }}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <Button
                    size={width > 786 ? "md" : "sm"}
                    color="danger"
                    outline
                    onClick={() => setDeleteModalShow(true)}
                  >
                    <i style={{ marginRight: "4px" }} className="bi bi-trash3"></i>
                    Delete
                  </Button>

                  {isEdit ? (
                    <Button
                      size={width > 786 ? "md" : "sm"}
                      style={{ backgroundColor: "#FFA302", border: "#FFA302" }}
                      type="submit"
                    >
                      <Icon icon="dashicons:update-alt" style={{ marginRight: "4px" }} />
                      Update
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </Card>
          </form>
        </React.Fragment>
      )}

      <DeleteJobModal
        isOpen={deleteModalShow}
        toggleModal={() => setDeleteModalShow(false)}
        id={jobId}
        navigate={true}
      />
    </div>
  );
};

export default JobPostingDetails;
