import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

function DriverLogsCard({ data }) {
  return (
    <>
      <div
        className="pb-0"
        style={{
          padding: 0,
          border: "1px solid #D1D1D1",
          overflow: "hidden",
          borderRadius: "12px",
          fontWeight: 400,
          color: "#565656",
          fontSize: "0.9rem"
        }}
      >
        <div className="p-2">
          <Fragment>
            {data?.loads?.map((item, index) => {
              return (
                <Row className="mb-2" key={index}>
                  <Col className="col-3">
                    {index === 0 ? moment(data?.date).format("MMM DD, YYYY") : ""}
                  </Col>
                  <Col className="col-1">
                    <div
                      className="p-2 d-flex justify-content-center align-items-center"
                      style={{
                        backgroundColor: "#FFF6E6",
                        color: "#FFA302",
                        width: "2rem",
                        height: "2rem",
                        borderRadius: "12px"
                      }}
                    >
                      {item?.jobLogs[0]?.serialNumber}
                    </div>
                  </Col>
                  <Col className="col-1">
                    {item?.tripTime?.split(":")[0] + " : " + item?.tripTime?.split(":")[1]}
                  </Col>
                  <Col className="col-4">{item?.materialType}</Col>
                  <Col className="col-1">
                    {moment(item?.jobLogs[2]?.actionTime).format("HH:mm")}
                  </Col>
                  <Col className="col-2">
                    {moment(item?.jobLogs[5]?.actionTime).format("HH:mm")}
                  </Col>
                </Row>
              );
            })}
          </Fragment>
        </div>

        <div
          className="p-2"
          style={{
            backgroundColor: "#F9F9F9"
          }}
        >
          <Col
            className="col-12 d-flex justify-content-around"
            style={{
              fontWeight: 500
            }}
          >
            <div>Effective Hours : {data?.effectiveHours}</div>
            <div>Total Hours : {data?.totalHours}</div>
          </Col>
        </div>
      </div>
    </>
  );
}

export default DriverLogsCard;
