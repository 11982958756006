import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalFooter } from "reactstrap";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  fetchContractorListThunkAction,
  updateContractorStatusThunkAction
} from "../../redux/Admin/action";

const RequestDenyModal = ({ toggleModal, isOpen, id }) => {
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();
  const navigator = useNavigate();

  const onSuccess = () => {
    setError(null);
  };

  const onNavigate = () => {
    navigator("/admin/contractor-account");
    dispatch(
      fetchContractorListThunkAction(
        {
          limit: 10
        },
        onSuccess,
        onError
      )
    );
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  const DenyModal = () => {
    const userData = { status: false };
    toggleModal(false);
    dispatch(updateContractorStatusThunkAction(id, userData, onNavigate, onError));
  };

  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Are you sure you want to Deny Contractor ?</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              DenyModal();
            }}
          >
            Deny
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RequestDenyModal;
