import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import {
  deleteForemanThunkAction,
  fetchForemanListThunkAction
} from "../../redux/Contractor/action";
import toast from "react-hot-toast";

const INITIAL_PAGE_SIZE = 10;

export const DeleteForemanModal = ({ toggleModal, isOpen, id }) => {
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();
  const onDeleteForeman = () => {
    dispatch(
      fetchForemanListThunkAction(
        {
          limit: INITIAL_PAGE_SIZE
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Delete Foreman</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalBody>
          Are you sure you want to delete the Foreman? Deleted foremen cannot be restored.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              dispatch(deleteForemanThunkAction(id, onDeleteForeman));
              toggleModal();
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteForemanModal;
