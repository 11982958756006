import { Button } from "reactstrap";
import { formatPhoneNumber } from "../../utils/helper";
import moment from "moment";

export const getColumns = (onClickDeleteHandler, onClickViewHandler) => {
  return [
    {
      Header: "Job Id",
      accessor: "id"
    },
    {
      Header: "Job Title",
      accessor: "jobTitle"
    },
    {
      Header: "Foreman Name",
      accessor: "foremanName"
    },
    {
      Header: "Foreman Contact Number",
      accessor: "foremanPhone",
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.foremanPhone)}</span>;
      }
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.startDate ? (
              <div>{moment(row.original.startDate).format("D MMM YYYY")}</div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">-----</div>
            )}
          </span>
        );
      }
    },
    {
      Header: "Duration",
      accessor: "duration",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.startDate ? (
              <div>{row.original.duration + " Days"}</div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">-----</div>
            )}
          </span>
        );
      }
    },
    {
      Header: "Material Name",
      accessor: "materialList",
      Cell: ({ row }) => {
        return (
          <span>
            {row.original.startDate ? (
              <div>{row.original.materialList}</div>
            ) : (
              <div className="d-flex justify-content-center align-items-center">-----</div>
            )}
          </span>
        );
      }
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-around">
            <Button
              size="sm"
              color="primary"
              outline
              onClick={() => onClickViewHandler(row?.original?.id)}
            >
              <i className="bi bi-person" />
              View
            </Button>

            <Button
              size="sm"
              color="danger"
              outline
              onClick={() => onClickDeleteHandler(row?.original?.id)}
              disabled={row?.original?.isActive === true ? true : false}
            >
              <i className="bi bi-trash3"></i>
              Delete
            </Button>
          </div>
        );
      }
    }
  ];
};
