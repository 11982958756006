import React from "react";
import { Col } from "reactstrap";

function StatisticsCard({ image, title, value }) {
  return (
    <>
      <Col className="col-12 col-sm-12 col-md-6 col-lg-3">
        <div className="p-2">
          <div
            className="border p-2 d-flex bg-white justify-content-between align-items-center"
            style={{
              borderRadius: "10px"
            }}
          >
            <div>
              <h6
                style={{
                  fontSize: "1rem",
                  fontWeight: 500,
                  color: "#7B7B7B"
                }}
              >
                {title}
              </h6>
              <h2
                style={{
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  color: "#565656"
                }}
              >
                {value}
              </h2>
            </div>
            <div>
              <img src={image} alt="total drivers" />
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}

export default StatisticsCard;
