import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import toast from "react-hot-toast";
import { deleteJobThunkAction, fetchCurrentJobThunkAction } from "../../redux/Contractor/action";
import { useNavigate } from "react-router-dom";

const INITIAL_PAGE_SIZE = 10;

const DeleteJobModal = ({ toggleModal, isOpen, id, navigate }) => {
  const [error, setError] = React.useState(null);
  const navigator = useNavigate();

  const dispatch = useDispatch();
  const onDeleteJob = () => {
    if (navigate) {
      navigator("/contractor/current-job-posting");
    } else {
      dispatch(
        fetchCurrentJobThunkAction(
          {
            limit: INITIAL_PAGE_SIZE
          },
          onSuccess,
          onError
        )
      );
    }
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Delete Job</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalBody>Do you really want to delete the Job ? Once done Cannot be undone.</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              dispatch(deleteJobThunkAction(id, onDeleteJob));
              toggleModal();
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteJobModal;
