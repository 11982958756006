import React, { useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Col, Row, Input, Table } from "reactstrap";
import { getColumns } from "./JobPostingList";
import "../ContractorAccount/ContractorAccount.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import Paginate from "../../components/Pagination/Pagination";
import { fetchForemenList } from "../../redux/Contractor/selectors";
import { selectUserToken } from "../../redux/User/selectors";
import { usePagination, useSortBy, useGlobalFilter } from "react-table";
import { fetchCurrentJobThunkAction } from "../../redux/Contractor/action";
import { setCurrentJobPageSize } from "../../redux/Contractor/action";

import { useTable } from "react-table";

import Search from "../../components/Search/Search";
import DeleteJobModal from "./DeleteJobModal";
import { useNavigate } from "react-router-dom";

const INITIAL_PAGE_INDEX = 0;

const CurrentJobPosting = () => {
  const dispatch = useDispatch();

  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const [currentJob, setCurrentJob] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [id, setId] = React.useState("");
  const navigate = useNavigate();

  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  const {
    currentJobPosting,
    isLoading,
    totalPage,
    currentJobPageSize: customPageSize
  } = useSelector(fetchForemenList);

  const [size, setSize] = React.useState(customPageSize);

  useEffect(() => {
    setCurrentJob(currentJobPosting);
  }, [currentJobPosting, currentJobPosting?.length]);

  const onClickDeleteHandler = (id) => {
    setDeleteModalShow(true);
    setId(id);
  };
  const onClickViewHandler = (id) => {
    navigate(`${id}/details`);
  };

  const columns = React.useMemo(() => getColumns(onClickDeleteHandler, onClickViewHandler), []);
  const data = React.useMemo(() => currentJob, [currentJob]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: size
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  useEffect(() => {
    if (token) {
      dispatch(
        fetchCurrentJobThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  return (
    <div className="px-3">
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Current Job Status
          </h4>
        </Col>
      </Row>

      <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
        <Col xs="12" md="10" className="d-flex align-items-center">
          <span>Show</span>
          <Input
            type="select"
            style={{
              appearance: "auto",
              width: "8rem",
              margin: "10px",
              cursor: "pointer"
            }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              dispatch(setCurrentJobPageSize(Number(e.target.value)));
            }}
          >
            {[5, 10, 20, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs="12" md="2">
          <Search
            keyword={globalFilter}
            onSubmitHandler={onSubmitHandler}
            setKeyword={setGlobalFilter}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        {isLoading ? (
          <React.Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div className="post_error_loading">{error}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table
                  {...getTableProps()}
                  className="align-items-center table-flush Firms_table_container"
                  responsive
                  bordered
                >
                  <thead className="thead-light Firms_table_header">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((column) => (
                          <th key={column.id} {...column.getHeaderProps()}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fas fa-arrow-down ml-2"></i>
                                ) : (
                                  <i className="fas fa-arrow-up ml-2"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell) => {
                            return (
                              <td key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!error && currentJob?.length === 0 && (
                  <div className="post_error_loading">No Jobs found</div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {isLoading === false && error === null ? (
          <nav aria-label="..." className="post_footer">
            <Paginate
              pageIndex={pageIndex}
              pageCount={pageCount}
              previousPage={previousPage}
              nextPage={nextPage}
              gotoPage={gotoPage}
            />
          </nav>
        ) : null}
      </Row>
      <DeleteJobModal
        isOpen={deleteModalShow}
        toggleModal={() => setDeleteModalShow(false)}
        id={id}
        navigate={false}
      />
    </div>
  );
};

export default CurrentJobPosting;
