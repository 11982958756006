import React from "react";

import {
  Col,
  Modal,
  ModalBody,
  Input,
  Row,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from "reactstrap";
import NumberFormat from "react-number-format";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addForemanThunkAction, fetchForemanListThunkAction } from "../../redux/Contractor/action";
import showPasswordIcon from "../../assets/img/theme/showPassword.png";
import hidePasswordIcon from "../../assets/img/theme/hidePassword.png";

import "./ForemanModal.css";
import toast from "react-hot-toast";
import usePasswordShow from "../../hooks/usePasswordShow";

const AddForemanModal = ({ isOpen, toggleModal }) => {
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();
  const dispatch = useDispatch();
  const [error, setError] = React.useState(null);

  const onAddForeman = () => {
    dispatch(
      fetchForemanListThunkAction(
        {
          limit: 10
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().trim().required("First Name Required"),
      lastName: Yup.string().trim().required("Last Name Required"),
      email: Yup.string().email("Must be a Valid Email").required("Email Required"),
      phoneNumber: Yup.string()
        .trim("Cannot include leading and trailing spaces")
        .required("Phone Number Required")
        .min(10, "Enter Appropriate Phone Number"),
      password: Yup.string()
        .required("Password Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
          "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
    }),
    onSubmit: (values, { resetForm }) => {
      const userData = {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phoneNumber
          .replace("+1", "")
          .replace("(", "")
          .replace(")", "")
          .replace(" ", "")
          .replaceAll("-", ""),
        email: values.email.toLowerCase(),
        password: values.password
      };
      dispatch(addForemanThunkAction(userData, onAddForeman));
      toggleModal(false);
      resetForm();
    }
  });

  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered modal-lg"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h5 className="modal-title">Add Foreman</h5>
              <button
                onClick={() => {
                  formik.resetForm();
                  toggleModal(false);
                }}
                type="button"
                className="close"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <ModalBody>
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col xs="12" lg="12" md="12" className="my-3">
                    <Row>
                      <Col className="text" xs="12" md="6" lg="6">
                        <b>First Name</b>
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="text"
                              placeholder="First Name"
                              id="firstName"
                              name="firstName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                            />
                          </Col>
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="text-danger mt-2">{formik.errors.firstName}</div>
                          ) : null}
                        </Row>
                      </Col>
                      <Col className="text" xs="12" md="6" lg="6">
                        <b>Last Name</b>
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="text"
                              placeholder="Last Name"
                              id="lastName"
                              name="lastName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName}
                            />
                          </Col>
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger mt-2">{formik.errors.lastName}</div>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text" xs="12" md="6" lg="6">
                        <b>Phone Number</b>
                        <Row className="my-2">
                          <Col>
                            <NumberFormat
                              className="form-control Numberinput"
                              format="+1(###) ###-##-##"
                              mask="_"
                              placeholder="Phone Number"
                              id="phoneNumber"
                              name="phoneNumber"
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "phoneNumber",
                                  value
                                    .replace("-", "")
                                    .replace("(", "")
                                    .replace(")", "")
                                    .replace(" ", "")
                                    .replace("_", "")
                                    .replace("+1", "")
                                );
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.phoneNumber}
                            />
                          </Col>
                          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                            <div className="text-danger mt-2">{formik.errors.phoneNumber}</div>
                          ) : null}
                        </Row>
                      </Col>
                      <Col className="text" xs="12" md="6" lg="6">
                        <b>Email</b>
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="email"
                              placeholder="Email"
                              name="email"
                              id="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                          </Col>
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-2">{formik.errors.email}</div>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text" xs="12" md="12" lg="12">
                        <b>Password</b>
                        <Row className="my-2">
                          <Col>
                            <InputGroup>
                              <Input
                                type={type}
                                placeholder="Password"
                                name="password"
                                id="password"
                                autoComplete="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                              />
                              <InputGroupAddon
                                addonType="append"
                                style={{ cursor: "pointer", opacity: "0.9" }}
                                onClick={togglePasswordVisiblity}
                              >
                                <InputGroupText className="foremanpassword_icon">
                                  {passwordShown ? (
                                    <img
                                      style={{ height: "20px" }}
                                      alt="hidepassword"
                                      src={hidePasswordIcon}
                                    />
                                  ) : (
                                    <img
                                      style={{ height: "20px" }}
                                      alt="showpassword"
                                      src={showPasswordIcon}
                                    />
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger mt-2">{formik.errors.password}</div>
                          ) : null}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="submit"
                    disabled={!formik.isValid}
                    className="mx-3"
                    size="lg"
                    style={
                      !formik.isValid
                        ? { backgroundColor: "#6c757d", border: "#6c757d" }
                        : { backgroundColor: "#FFA302", border: "#FFA302" }
                    }
                  >
                    Add Foreman
                  </Button>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default AddForemanModal;
