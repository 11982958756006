import {
  DASHBOARD_STATISTICS_FAILURE,
  DASHBOARD_STATISTICS_REQUEST,
  DASHBOARD_STATISTICS_SUCCESS,
  DASHBOARD_STATISTICS_TWO_FAILURE,
  DASHBOARD_STATISTICS_TWO_REQUEST,
  DASHBOARD_STATISTICS_TWO_SUCCESS,
  LOG_DETAILS_FAILURE,
  LOG_DETAILS_REQUEST,
  LOG_DETAILS_SUCCESS
} from "./types";

import { foremanListApi } from "../../services/contractorServices";
import toast from "react-hot-toast";
import { dashboardStatisticsApi } from "services/dashbaardServices";
import { dashboardStatisticsTwoApi } from "services/dashbaardServices";
import { logDetailsApi } from "services/dashbaardServices";

export const dashboardStatisticsRequest = () => {
  return {
    type: DASHBOARD_STATISTICS_REQUEST
  };
};

export const dashboardStatisticsSuccess = (data) => {
  return {
    type: DASHBOARD_STATISTICS_SUCCESS,
    payload: data
  };
};

export const dashboardStatisticsFailure = (error) => {
  return {
    type: DASHBOARD_STATISTICS_FAILURE,
    payload: error
  };
};

//---------------------------------------------------

export const dashboardStatisticsTwoRequest = () => {
  return {
    type: DASHBOARD_STATISTICS_TWO_REQUEST
  };
};

export const dashboardStatisticsTwoSuccess = (data) => {
  return {
    type: DASHBOARD_STATISTICS_TWO_SUCCESS,
    payload: data
  };
};

export const dashboardStatisticsTwoFailure = (error) => {
  return {
    type: DASHBOARD_STATISTICS_TWO_FAILURE,
    payload: error
  };
};

//---------------------------------------------------

export const logDetailsRequest = () => {
  return {
    type: LOG_DETAILS_REQUEST
  };
};

export const logDetailsSuccess = (data) => {
  return {
    type: LOG_DETAILS_SUCCESS,
    payload: data
  };
};

export const logDetailsFailure = (error) => {
  return {
    type: LOG_DETAILS_FAILURE,
    payload: error
  };
};

//---------------------------------------------------

export const fetchDashboardStatisticsThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(dashboardStatisticsRequest());
      const { data } = await dashboardStatisticsApi();

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(dashboardStatisticsSuccess(data?.data));
    } catch (error) {
      dispatch(dashboardStatisticsFailure(error?.response?.data?.message || error?.message));
    }
  };
};

export const fetchDashboardStatisticsTwoThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(dashboardStatisticsTwoRequest());
      const { data } = await dashboardStatisticsTwoApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(dashboardStatisticsTwoSuccess(data?.data));
    } catch (error) {
      dispatch(dashboardStatisticsTwoFailure(error?.response?.data?.message || error?.message));
    }
  };
};

export const fetchLogDetailsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(logDetailsRequest());
      const { data } = await logDetailsApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(logDetailsSuccess(data?.data));
      // onSuccess();
    } catch (error) {
      dispatch(logDetailsFailure(error?.response?.data?.message || error?.message));
      // onError(error?.response?.data?.message || error?.message);
    }
  };
};
