import React from "react";
import { Col } from "reactstrap";

function JobOutstandingCard({ image, value, title }) {
  return (
    <>
      <Col className="col-4">
        <div className="d-flex align-items-center justify-content-around px-lg-4">
          <div
            className=" p-2"
            style={{
              borderRadius: "8px",
              backgroundColor: "#F9F9F9"
            }}
          >
            {" "}
            <img src={image} alt="total payable" />{" "}
          </div>
          <div>
            <h3
              style={{
                color: "#FFA302"
              }}
            >
              {value}
            </h3>
            <div
              style={{
                color: "#7B7B7B"
              }}
            >
              {title}
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}

export default JobOutstandingCard;
