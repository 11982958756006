import React, { useEffect, useRef, useState } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Col, Row, Button, Card, CardBody, FormGroup } from "reactstrap";
import NumberFormat from "react-number-format";
import { selectUser } from "../../redux/User/selectors";
import { formatPhoneNumber } from "../../utils/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileThunkAction, updateProfileThunkAction } from "../../redux/User/actions";
import Contractor from "../../assets/img/sidebarIcon/contractor.jpg";
import Loader from "../../components/Loader/Loader";
import "./Profile.css";

const Profile = () => {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = React.useState(null);
  const [url, setUrl] = React.useState(null);

  const dispatch = useDispatch();

  const { isLoading, contractorProfile, isSigning } = useSelector(selectUser);

  useEffect(() => {
    dispatch(fetchProfileThunkAction());
  }, []);

  const formik = useFormik({
    initialValues: {
      contractorName: contractorProfile?.firstName,
      companyName: contractorProfile?.contractorDetails?.companyName,
      email: contractorProfile?.email,
      phone: contractorProfile?.phone,
      address: contractorProfile?.contractorDetails?.address,
      file: contractorProfile?.contractorDetails?.logo,
      city: contractorProfile?.contractorDetails?.city,
      state: contractorProfile?.contractorDetails?.state,
      einNumber: contractorProfile?.contractorDetails?.einNumber,
      // accountNumber: contractorProfile?.contractorDetails?.bankAccountNumber,
      serviceContract: contractorProfile?.contractorDetails?.serviceContract
    },
    validationSchema: Yup.object().shape({
      contractorName: Yup.string()
        .required("Contractor Name Required")
        .min(4, "Name must be atleast 4 Characters"),
      companyName: Yup.string()
        .required("Company Name Required")
        .min(4, "Name must be atleast 4 Characters"),
      email: Yup.string().email("Must be a Valid Email").required("Email Required"),
      phone: Yup.string()
        .required("Phone Number Required")
        .min(10, "Enter Appropriate Phone Number"),
      address: Yup.string().required("Address Required"),
      city: Yup.string().required("City Required"),
      state: Yup.string().required("State Required"),
      einNumber: Yup.string().required("EIN Number Required"),
      // accountNumber: Yup.string().required("Account Number Required"),
      serviceContract: Yup.string().required("Service Contract Required")
    }),
    onSubmit: (values) => {
      const requestBodyFormData = new FormData();

      if (file) {
        requestBodyFormData.append("first_name", values.contractorName);
        requestBodyFormData.append("phone", values.phone);
        requestBodyFormData.append("company_name", values.companyName);
        requestBodyFormData.append("dispatcher_name", values.contractorName);
        requestBodyFormData.append("address", values.address);
        requestBodyFormData.append("city", values.city);
        requestBodyFormData.append("state", values.state);
        requestBodyFormData.append("ein_number", values.einNumber);
        // requestBodyFormData.append("bank_account_number", values.accountNumber);
        requestBodyFormData.append("service_contract", values.serviceContract.toString());
        requestBodyFormData.append("file", file);
      } else {
        requestBodyFormData.append("first_name", values.contractorName);
        requestBodyFormData.append("phone", values.phone);
        requestBodyFormData.append("company_name", values.companyName);
        requestBodyFormData.append("dispatcher_name", values.contractorName);
        requestBodyFormData.append("address", values.address);
        requestBodyFormData.append("city", values.city);
        requestBodyFormData.append("state", values.state);
        requestBodyFormData.append("ein_number", values.einNumber);
        // requestBodyFormData.append("bank_account_number", values.accountNumber);
        requestBodyFormData.append("service_contract", values.serviceContract.toString());
      }

      dispatch(updateProfileThunkAction(requestBodyFormData, values.contractorName, onSuccess));
    },
    enableReinitialize: true
  });

  const onSuccess = () => {
    dispatch(fetchProfileThunkAction());
    setIsEdit(false);
  };

  function handleChange(event) {
    setFile(event.target.files[0]);
    setUrl(URL.createObjectURL(event.target.files[0]));
  }

  return (
    <div className="px-3">
      <div
        className={width > 786 || isopen ? "d-flex justify-content-between my-3" : "mx-4 header"}
      >
        <h4
          style={
            width > 786 || isopen
              ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
              : {
                  color: "#1A1A1A",
                  fontWeight: "bolder",
                  textAlign: "center",
                  marginTop: "17px",
                  paddingTop: "10px"
                }
          }
        >
          Profile Details
        </h4>

        <h4
          style={
            width > 786 || isopen
              ? { color: "#667080", fontWeight: "bolder", marginTop: "20px" }
              : {
                  textAlign: "end",
                  marginRight: "-6%",
                  marginTop: "20px"
                }
          }
        >
          {!isEdit ? (
            <Button
              size="md"
              color="secondary"
              outline
              onClick={() => {
                setIsEdit(true);
              }}
            >
              <i
                style={width > 786 || isopen ? { marginRight: "8px" } : { display: "none" }}
                className="bi bi-pencil-square"
              ></i>
              Edit
            </Button>
          ) : (
            ""
          )}
        </h4>
      </div>

      {isLoading ? (
        <React.Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </React.Fragment>
      ) : width > 947 ? (
        <Card className="mt-4" style={{ background: "#ffffff" }}>
          <CardBody>
            <form
              className="register_form_tag"
              style={{ fontStyle: "normal" }}
              onSubmit={formik.handleSubmit}
            >
              <Row className="px-lg-5 px-md-4 px-3 py-3">
                <div className="d-flex">
                  <div style={{ width: "50%" }}>
                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                          Address
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <textarea
                                  type="textarea"
                                  className="form-control my-2 "
                                  placeholder="Enter your Address"
                                  id="address"
                                  name="address"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.address}
                                />
                              </FormGroup>
                              {formik.touched.address && formik.errors.address ? (
                                <div className="text-danger mt-2">{formik.errors.address}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.contractorDetails?.address
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                          City
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  type="text"
                                  className="form-control my-2 "
                                  placeholder="City"
                                  id="city"
                                  name="city"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.city}
                                />
                              </FormGroup>
                              {formik.touched.city && formik.errors.city ? (
                                <div className="text-danger mt-2">{formik.errors.city}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.contractorDetails?.city
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                          State
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  type="text"
                                  className="form-control my-2 "
                                  placeholder="State"
                                  id="state"
                                  name="state"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.state}
                                />
                              </FormGroup>
                              {formik.touched.state && formik.errors.state ? (
                                <div className="text-danger mt-2">{formik.errors.state}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.contractorDetails?.state
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                          EIN Number
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  type="text"
                                  className="form-control my-2 "
                                  placeholder="Enter EIN-Number"
                                  id="einNumber"
                                  name="einNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.einNumber}
                                />
                              </FormGroup>
                              {formik.touched.einNumber && formik.errors.einNumber ? (
                                <div className="text-danger mt-2">{formik.errors.einNumber}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.contractorDetails?.einNumber
                          )}
                        </Col>
                      </Row>
                    </Col>

                    {/* <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                          Bank Account Number
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <NumberFormat
                                  className="form-control my-2 "
                                  format="#### #### #### #### ####"
                                  mask="_"
                                  placeholder="Enter Bank account number"
                                  id="accountNumber"
                                  name="accountNumber"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.accountNumber}
                                />
                              </FormGroup>
                              {formik.touched.accountNumber && formik.errors.accountNumber ? (
                                <div className="text-danger mt-2">
                                  {formik.errors.accountNumber}
                                </div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            <div className="my-3">
                              {contractorProfile?.contractorDetails?.bankAccountNumber}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col> */}

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                          Service Contract
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  type="number"
                                  className="form-control my-2"
                                  placeholder="Service Contract"
                                  min="0.5"
                                  step="0.1"
                                  id="serviceContract"
                                  name="serviceContract"
                                  onWheel={(e) => e.target.blur()}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.serviceContract}
                                />
                              </FormGroup>
                              {formik.touched.serviceContract && formik.errors.serviceContract ? (
                                <div className="text-danger mt-2">
                                  {formik.errors.serviceContract}
                                </div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.contractorDetails?.serviceContract + " Years"
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </div>
                  <div
                    style={{
                      borderLeft: "2px solid #D1D1D1",
                      height: "100%",
                      marginLeft: "50px",
                      width: "50%"
                    }}
                  >
                    <Col
                      xs="12"
                      lg="12"
                      md="12"
                      className="mb-4"
                      style={{ textAlign: "-webkit-center" }}
                    >
                      <Row className="imageStyle" style={{ position: "relative" }}>
                        {contractorProfile?.logo && (
                          <img className="my-2" src={url ? url : `${contractorProfile?.logo}`} />
                        )}
                        {!contractorProfile?.logo && (
                          <img className="my-2" src={url ? url : Contractor} />
                        )}
                        {isEdit ? (
                          <>
                            <label
                              htmlFor="file"
                              style={{
                                position: "absolute",
                                top: "-16px",
                                right: "-24px",
                                display: "flex",
                                justifyContent: "flex-end",
                                backgroundColor: "#FFAB1B",
                                width: "40px",
                                height: "41px",
                                borderRadius: "20px",
                                cursor: "pointer"
                              }}
                            >
                              <i className="mt-2 text-white bi bi-pencil-square"></i>
                            </label>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="file"
                              accept="image/*"
                              onChange={handleChange}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Col>

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col
                          style={{ paddingLeft: "30px" }}
                          className="d-flex align-items-center label"
                          xs="5"
                          md="5"
                          lg="5"
                        >
                          Contractor Name
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Contractor Name"
                                  id="contractorName"
                                  name="contractorName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.contractorName}
                                />
                              </FormGroup>
                              {formik.touched.contractorName && formik.errors.contractorName ? (
                                <div className="text-danger">{formik.errors.contractorName}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.firstName
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col
                          style={{ paddingLeft: "30px" }}
                          className="d-flex align-items-center label"
                          xs="5"
                          md="5"
                          lg="5"
                        >
                          Company Name
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Company Name"
                                  id="companyName"
                                  name="companyName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.companyName}
                                />
                              </FormGroup>
                              {formik.touched.companyName && formik.errors.companyName ? (
                                <div className="text-danger mt-2">{formik.errors.companyName}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.contractorDetails?.companyName
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col
                          style={{ paddingLeft: "30px" }}
                          className="d-flex  align-items-center label"
                          xs="5"
                          md="5"
                          lg="5"
                        >
                          Phone Number
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <NumberFormat
                                className="form-control"
                                format="+1(###) ###-##-##"
                                mask="_"
                                placeholder="Phone Number"
                                id="phone"
                                name="phone"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  formik.setFieldValue(
                                    "phone",
                                    value
                                      .replaceAll("-", "")
                                      .replace("(", "")
                                      .replace(")", "")
                                      .replace(" ", "")
                                      .replace("_", "")
                                      .replace("+1", "")
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                              />
                              {formik.touched.phone && formik.errors.phone ? (
                                <div className="text-danger mt-2">{formik.errors.phone}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            formatPhoneNumber(contractorProfile?.phone)
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col xs="12" lg="12" md="12" className="mb-4">
                      <Row>
                        <Col
                          style={{ paddingLeft: "30px" }}
                          className="d-flex align-items-center label"
                          xs="5"
                          md="5"
                          lg="5"
                        >
                          Email
                        </Col>
                        <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {isEdit ? (
                            <React.Fragment>
                              <FormGroup>
                                <input
                                  style={{ backgroundColor: "white" }}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Your E-mail"
                                  id="email"
                                  name="email"
                                  disabled
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.email}
                                />
                              </FormGroup>
                              {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger mt-2">{formik.errors.email}</div>
                              ) : null}
                            </React.Fragment>
                          ) : (
                            contractorProfile?.email
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </div>
              </Row>
              {isEdit ? (
                <Row lg="4">
                  <button
                    disabled={!formik.isValid || isSigning}
                    className="btn contractor_register_btn mx-auto mb-3 mt-2 "
                    type="submit"
                  >
                    {isSigning ? "Updating..." : "Update"}
                  </button>
                </Row>
              ) : (
                ""
              )}
            </form>
          </CardBody>
        </Card>
      ) : (
        <Card className="mt-4" style={{ background: "#ffffff" }}>
          <CardBody>
            <form
              className="register_form_tag"
              style={{ fontStyle: "normal" }}
              onSubmit={formik.handleSubmit}
            >
              <Row className="px-lg-5 px-md-4 px-3 py-3">
                <Col
                  xs="12"
                  lg="12"
                  md="12"
                  className="mb-4"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <Row className="imageStyle" style={{ position: "relative" }}>
                    {contractorProfile?.logo && (
                      <img className="my-2" src={url ? url : `${contractorProfile?.logo}`} />
                    )}
                    {!contractorProfile?.logo && (
                      <img className="my-2" src={url ? url : Contractor} />
                    )}
                    {isEdit ? (
                      <>
                        <label
                          htmlFor="file"
                          style={{
                            position: "absolute",
                            top: "-16px",
                            right: "-24px",
                            display: "flex",
                            justifyContent: "flex-end",
                            backgroundColor: "#FFAB1B",
                            width: "40px",
                            height: "41px",
                            borderRadius: "20px",
                            cursor: "pointer"
                          }}
                        >
                          <i className="mt-2 text-white bi bi-pencil-square"></i>
                        </label>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          id="file"
                          accept="image/*"
                          onChange={handleChange}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </Row>
                </Col>

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      Contractor Name
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Contractor Name"
                              id="contractorName"
                              name="contractorName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contractorName}
                            />
                          </FormGroup>
                          {formik.touched.contractorName && formik.errors.contractorName ? (
                            <div className="text-danger">{formik.errors.contractorName}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.firstName
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      Company Name
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              id="companyName"
                              name="companyName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.companyName}
                            />
                          </FormGroup>
                          {formik.touched.companyName && formik.errors.companyName ? (
                            <div className="text-danger mt-2">{formik.errors.companyName}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.contractorDetails?.companyName
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex  align-items-center label" xs="5" md="5" lg="5">
                      Phone Number
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <NumberFormat
                            className="form-control"
                            format="+1(###) ###-##-##"
                            mask="_"
                            placeholder="Phone Number"
                            id="phone"
                            name="phone"
                            onChange={(e) => {
                              const value = e.target.value;
                              formik.setFieldValue(
                                "phone",
                                value
                                  .replaceAll("-", "")
                                  .replace("(", "")
                                  .replace(")", "")
                                  .replace(" ", "")
                                  .replace("_", "")
                                  .replace("+1", "")
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger mt-2">{formik.errors.phone}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        formatPhoneNumber(contractorProfile?.phone)
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      Email
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              style={{ backgroundColor: "white" }}
                              type="text"
                              className="form-control"
                              placeholder="Enter Your E-mail"
                              id="email"
                              name="email"
                              disabled
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                          </FormGroup>
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger mt-2">{formik.errors.email}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.email
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      Address
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <textarea
                              type="textarea"
                              className="form-control my-2 "
                              placeholder="Enter your Address"
                              id="address"
                              name="address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.address}
                            />
                          </FormGroup>
                          {formik.touched.address && formik.errors.address ? (
                            <div className="text-danger mt-2">{formik.errors.address}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.contractorDetails?.address
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      City
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              type="text"
                              className="form-control my-2 "
                              placeholder="City"
                              id="city"
                              name="city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                            />
                          </FormGroup>
                          {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger mt-2">{formik.errors.city}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.contractorDetails?.city
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      State
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              type="text"
                              className="form-control my-2 "
                              placeholder="State"
                              id="state"
                              name="state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                            />
                          </FormGroup>
                          {formik.touched.state && formik.errors.state ? (
                            <div className="text-danger mt-2">{formik.errors.state}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.contractorDetails?.state
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      EIN Number
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              type="text"
                              className="form-control my-2 "
                              placeholder="Enter EIN-Number"
                              id="einNumber"
                              name="einNumber"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.einNumber}
                            />
                          </FormGroup>
                          {formik.touched.einNumber && formik.errors.einNumber ? (
                            <div className="text-danger mt-2">{formik.errors.einNumber}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.contractorDetails?.einNumber
                      )}
                    </Col>
                  </Row>
                </Col>

                {/* <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      Bank Account Number
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <NumberFormat
                              className="form-control my-2 "
                              format="#### #### #### #### ####"
                              mask="_"
                              placeholder="Enter Bank account number"
                              id="accountNumber"
                              name="accountNumber"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.accountNumber}
                            />
                          </FormGroup>
                          {formik.touched.accountNumber && formik.errors.accountNumber ? (
                            <div className="text-danger mt-2">{formik.errors.accountNumber}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        <div className="my-3">
                          {contractorProfile?.contractorDetails?.bankAccountNumber}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col> */}

                <Col xs="12" lg="12" md="12" className="mb-4">
                  <Row>
                    <Col className="d-flex align-items-center label" xs="5" md="5" lg="5">
                      Service Contract
                    </Col>
                    <Col className="d-flex align-items-center" xs="1" md="1" lg="1">
                      :
                    </Col>
                    <Col className="value" xs="6" md="6" lg="6">
                      {isEdit ? (
                        <React.Fragment>
                          <FormGroup>
                            <input
                              type="number"
                              className="form-control my-2"
                              placeholder="Service Contract"
                              min="0.5"
                              step="0.1"
                              id="serviceContract"
                              name="serviceContract"
                              onWheel={(e) => e.target.blur()}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.serviceContract}
                            />
                          </FormGroup>
                          {formik.touched.serviceContract && formik.errors.serviceContract ? (
                            <div className="text-danger mt-2">{formik.errors.serviceContract}</div>
                          ) : null}
                        </React.Fragment>
                      ) : (
                        contractorProfile?.contractorDetails?.serviceContract + " Years"
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {isEdit ? (
                <Row lg="4">
                  <button
                    disabled={!formik.isValid || isSigning}
                    className="btn contractor_register_btn mx-auto mb-3 mt-2 "
                    type="submit"
                  >
                    {isSigning ? "Updating..." : "Update"}
                  </button>
                </Row>
              ) : (
                ""
              )}
            </form>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default Profile;
