export const formatPhoneNumber = (phone) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return "+1(" + match[1] + ") " + match[2] + "-" + match[3] + "-" + match[4];
  }
  return null;
};

export function convertNumToTime(number) {
  let hour = Math.floor(number);
  let decPart = number - hour;

  let min = 1 / 60;
  decPart = min * Math.round(decPart / min);

  let minute = Math.floor(decPart * 60) + "";

  if (minute.length < 2) {
    minute = "0" + minute;
  }

  let time = hour + "h " + minute + "m";
  return time;
}

export function convertToReadableTime(inputTime) {
  const timeComponents = inputTime.split(":");

  const hours = parseInt(timeComponents[0]);
  const minutes = parseInt(timeComponents[1]);

  const formattedTime = `${hours}h ${minutes}m`;

  return formattedTime;
}
