import React, { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Col, Row, Input, Table } from "reactstrap";
import { getColumns } from "./ContractorAccountList";
import { fetchContractorListThunkAction, noteAdminThunkAction } from "../../redux/Admin/action";
import { setContractorPageSize } from "../../redux/Admin/action";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import Paginate from "../../components/Pagination/Pagination";
import "./ContractorAccount.css";

import { useTable, usePagination, useSortBy, useGlobalFilter } from "react-table";
import { fetchContractorList } from "../../redux/Admin/selectors";
import { selectUserToken } from "../../redux/User/selectors";
import { useNavigate } from "react-router-dom";

import Search from "../../components/Search/Search";
import { fetchForemenList } from "redux/Contractor/selectors";
import NotesModal from "components/Modals/NotesModal";

const INITIAL_PAGE_INDEX = 0;

const ContractorAccount = () => {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const [contractorList, setContractorList] = useState([]);
  const [sort, setSort] = useState({ sortBy: "created_at", sortDirection: "asc" });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const {
    contractorStatusList,
    isLoading,
    totalPage,
    contractorPageSize: customPageSize
  } = useSelector(fetchContractorList);

  const [size, setSize] = useState(customPageSize);

  useEffect(() => {
    setContractorList(contractorStatusList);
  }, [contractorStatusList, contractorStatusList?.length]);

  const onClickViewHandler = (id) => {
    navigate(`${id}/details`);
  };

  const columns = React.useMemo(() => getColumns(onClickViewHandler, handleNote), []);
  const data = React.useMemo(() => contractorList, [contractorList]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: size
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  useEffect(() => {
    if (token) {
      dispatch(
        fetchContractorListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            sort: sort?.sortBy,
            order: sort?.sortDirection
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  function handleNote(data) {
    setSelectedContractor(data);
    setIsOpen(true);
  }

  function submitNote(data) {
    setIsOpen(false);
    dispatch(
      noteAdminThunkAction(
        {
          userId: selectedContractor?.id,
          description: data
        },
        "contractor"
      )
    );
  }

  return (
    <div className="px-3">
      <NotesModal
        open={isOpen}
        closeModal={() => {
          setIsOpen(false);
          setSelectedContractor(null);
        }}
        noteUser={selectedContractor}
        submit={submitNote}
      />
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Contractor Account
          </h4>
        </Col>
      </Row>

      <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
        <Col xs="12" md="10" className="d-flex align-items-center">
          <span>Show</span>
          <Input
            type="select"
            style={{
              appearance: "auto",
              width: "8rem",
              margin: "10px",
              cursor: "pointer"
            }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              dispatch(setContractorPageSize(Number(e.target.value)));
            }}
          >
            {[5, 10, 20, 30, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs="12" md="2">
          <Search
            keyword={globalFilter}
            onSubmitHandler={onSubmitHandler}
            setKeyword={setGlobalFilter}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        {isLoading ? (
          <React.Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div className="post_error_loading">{error}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table
                  {...getTableProps()}
                  className="align-items-center table-flush Firms_table_container"
                  responsive
                  bordered
                >
                  <thead className="thead-light Firms_table_header">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((column) => (
                          <th
                            key={column.id}
                            style={!column.disableSortBy ? { cursor: "pointer" } : {}}
                            onClick={() => {
                              if (!column.disableSortBy) {
                                const desc =
                                  column.isSortedDesc === true
                                    ? undefined
                                    : column.isSortedDesc === false
                                    ? true
                                    : false;

                                setSort({
                                  sortBy: column.id,
                                  sortDirection: desc ? "asc" : "desc"
                                });

                                setSortBy([{ id: column.id, desc }]);
                              }
                            }}
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="bi bi-arrow-down mx-2"></i>
                                ) : (
                                  <i className="bi bi-arrow-up mx-2"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell) => {
                            return (
                              <td key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!error && contractorList?.length === 0 && (
                  <div className="post_error_loading">No Contractors found</div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {isLoading === false && error === null ? (
          <nav aria-label="..." className="post_footer">
            <Paginate
              pageIndex={pageIndex}
              pageCount={pageCount}
              previousPage={previousPage}
              nextPage={nextPage}
              gotoPage={gotoPage}
            />
          </nav>
        ) : null}
      </Row>
    </div>
  );
};

export default ContractorAccount;
