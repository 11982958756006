import React from "react";
import RequireAuth from "../components/RequireAuth";
import { Navigate, Route, Routes } from "react-router-dom";
import { toggleContext } from "../App";
import { contractorSidebarRoutes } from "../data/sidebar";
import userRoles from "../utils/roles";

import CurrentJobPosting from "../pages/JobPosting/CurrentJobPosting";
import NewJob from "../pages/NewJob/NewJob";
import Foreman from "../pages/Foreman/Foreman";
import JobPostingDetails from "../pages/JobPosting/JobPostingDetails";
import Profile from "../pages/Profile/Profile";
import NotFound from "../pages/NotFound/NotFound";
import Unauthorized from "../pages/Unauthorized/Unauthorized";

import Sidebar from "../components/Sidebar/Sidebar";

const ContractorLayout = () => {
  const [isopen, setisOpen] = React.useState(true);
  return (
    <>
      <toggleContext.Provider
        value={{
          isopen,
          setisOpen
        }}
      >
        <Sidebar routes={contractorSidebarRoutes} />
        <div className={isopen ? "sidecontainer" : "sidecontainerclose"}>
          <div>
            <Routes>
              <Route element={<RequireAuth allowedRoles={[userRoles.CONTRACTOR]} />}>
                <Route path="current-job-posting" element={<CurrentJobPosting />} />
                <Route path="current-job-posting/:id/details" element={<JobPostingDetails />} />
                <Route path="post-new-job" element={<NewJob />} />
                <Route path="foreman-list" element={<Foreman />} />
                <Route path="profile" element={<Profile />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </div>
        </div>
      </toggleContext.Provider>
    </>
  );
};

export default ContractorLayout;
