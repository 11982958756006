import { combineReducers } from "redux";
import userReducer from "./User/reducer";
import ContractListReducer from "./Admin/reducer";
import ForemanListReducer from "./Contractor/reducer";
import dashboardReducer from "./dashboard/reducer";
import jobSummaryReducer from "./jobSummary/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  fetchContractorList: ContractListReducer,
  fetchForemenList: ForemanListReducer,
  dashboard: dashboardReducer,
  jobSummary: jobSummaryReducer
});

export default rootReducer;
