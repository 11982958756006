import {
  FETCH_CONTRACTOR_LIST,
  FETCH_CONTRACTOR_LIST_SUCCESS,
  FETCH_CONTRACTOR_LIST_FAILURE,
  FETCH_CONTRACTOR_DETAILS,
  FETCH_CONTRACTOR_DETAILS_SUCCESS,
  FETCH_CONTRACTOR_DETAILS_FAILURE,
  UPDATE_CONTRACTOR_STATUS,
  UPDATE_CONTRACTOR_STATUS_SUCCESS,
  UPDATE_CONTRACTOR_STATUS_FAILURE,
  FETCH_TRUCK_DRIVER,
  FETCH_TRUCK_DRIVER_SUCCESS,
  FETCH_TRUCK_DRIVER_FAILURE,
  FETCH_TRUCK_DRIVER_DETAILS,
  FETCH_TRUCK_DRIVER_DETAILS_SUCCESS,
  FETCH_TRUCK_DRIVER_DETAILS_FAILURE,
  UPDATE_TRUCK_DRIVER_STATUS,
  UPDATE_TRUCK_DRIVER_STATUS_SUCCESS,
  UPDATE_TRUCK_DRIVER_STATUS_FAILURE,
  SET_CONTRACTOR_PAGE_SIZE,
  SET_TRUCK_DRIVER_PAGE_SIZE,
  UPDATE_CONTRACTOR_NOTE,
  UPDATE_TRUCK_DRIVER_NOTE
} from "./types";

import {
  contractorListApi,
  contractDetailsApi,
  contractorStatusUpdateApi,
  truckDriverListApi,
  truckDriverDetailsApi,
  updateTruckDriverStatusApi,
  noteAdminApi
} from "../../services/adminServices";
import toast from "react-hot-toast";

export const fetchContractorListRequest = () => {
  return {
    type: FETCH_CONTRACTOR_LIST
  };
};

export const fetchContractorListSuccess = (contractorlist, totalPage) => {
  return {
    type: FETCH_CONTRACTOR_LIST_SUCCESS,
    payload: { contractorlist, totalPage }
  };
};

export const fetchContractorListFailure = (error) => {
  return {
    type: FETCH_CONTRACTOR_LIST_FAILURE,
    payload: error
  };
};

export const fetchTruckDriverList = () => {
  return {
    type: FETCH_TRUCK_DRIVER
  };
};

export const fetchTruckDriverListSuccess = (truckDriverList, totalPage) => {
  return {
    type: FETCH_TRUCK_DRIVER_SUCCESS,
    payload: { truckDriverList, totalPage }
  };
};

export const fetchTruckDriverListFailure = (error) => {
  return {
    type: FETCH_TRUCK_DRIVER_FAILURE,
    payload: error
  };
};

export const fetchContractorDetails = () => {
  return {
    type: FETCH_CONTRACTOR_DETAILS
  };
};

export const fetchContractorDetailsSuccess = (contractorDetails) => {
  return {
    type: FETCH_CONTRACTOR_DETAILS_SUCCESS,
    payload: { contractorDetails }
  };
};

export const fetchContractorDetailsFailure = (error) => {
  return {
    type: FETCH_CONTRACTOR_DETAILS_FAILURE,
    payload: error
  };
};

export const fetchTruckDriverDetails = () => {
  return {
    type: FETCH_TRUCK_DRIVER_DETAILS
  };
};

export const fetchTruckDriverDetailsSuccess = (truckDriverDetails) => {
  return {
    type: FETCH_TRUCK_DRIVER_DETAILS_SUCCESS,
    payload: { truckDriverDetails }
  };
};

export const fetchTruckDriverDetailsFailure = (error) => {
  return {
    type: FETCH_TRUCK_DRIVER_DETAILS_FAILURE,
    payload: error
  };
};

export const updateContractorStatus = () => {
  return {
    type: UPDATE_CONTRACTOR_STATUS
  };
};

export const updateContractorStatusSuccess = () => {
  return {
    type: UPDATE_CONTRACTOR_STATUS_SUCCESS
  };
};

export const updateContractorStatusFailure = (error) => {
  return {
    type: UPDATE_CONTRACTOR_STATUS_FAILURE,
    payload: error
  };
};

export const updateTruckDriverStatus = () => {
  return {
    type: UPDATE_TRUCK_DRIVER_STATUS
  };
};

export const updateTruckDriverStatusSuccess = () => {
  return {
    type: UPDATE_TRUCK_DRIVER_STATUS_SUCCESS
  };
};

export const updateTruckDriverStatusFailure = (error) => {
  return {
    type: UPDATE_TRUCK_DRIVER_STATUS_FAILURE,
    payload: error
  };
};

export const setContractorPageSize = (size) => {
  return {
    type: SET_CONTRACTOR_PAGE_SIZE,
    payload: size
  };
};
export const setTruckDriverPageSize = (size) => {
  return {
    type: SET_TRUCK_DRIVER_PAGE_SIZE,
    payload: size
  };
};

export const updateContractorNote = (contractorDetails) => {
  return {
    type: UPDATE_CONTRACTOR_NOTE,
    payload: contractorDetails
  };
};

export const updateTruckDriverNote = (truckDriverDetails) => {
  return {
    type: UPDATE_TRUCK_DRIVER_NOTE,
    payload: truckDriverDetails
  };
};

export const fetchContractorListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchContractorListRequest());
      const { data } = await contractorListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(
        fetchContractorListSuccess(data?.data?.contractorList, data?.data?.total / params?.limit)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchContractorListFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchTruckDriverListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTruckDriverList());
      const { data } = await truckDriverListApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(
        fetchTruckDriverListSuccess(data?.data?.truckDriverList, data?.data?.total / params?.limit)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchTruckDriverListFailure());
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchContractorDetailsThunkAction = (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchContractorDetails());
      const { data } = await contractDetailsApi(id);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(fetchContractorDetailsSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchContractorDetailsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchTruckDriverDetailsThunkAction = (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTruckDriverDetails());
      const { data } = await truckDriverDetailsApi(id);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(fetchTruckDriverDetailsSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchTruckDriverDetailsFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateContractorStatusThunkAction = (id, userData, onNavigate, onError) => {
  return async (dispatch) => {
    try {
      dispatch(updateContractorStatus());
      const { data } = await contractorStatusUpdateApi(id, userData);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(updateContractorStatusSuccess());
      toast.success("Status Updated!");
      onNavigate();
    } catch (error) {
      dispatch(updateContractorStatusSuccess(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const updateTruckDriverStatusThunkAction = (id, userData, onNavigate, onError) => {
  return async (dispatch) => {
    try {
      dispatch(updateTruckDriverStatus());
      const { data } = await updateTruckDriverStatusApi(id, userData);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(updateTruckDriverStatusSuccess());
      toast.success("Status Updated!");
      onNavigate();
    } catch (error) {
      dispatch(updateTruckDriverStatusFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const noteAdminThunkAction = (requestBody, userRole) => {
  return async (dispatch) => {
    try {
      const promise = noteAdminApi(requestBody);

      toast.promise(promise, {
        loading: "Saving...",
        success: "Note saved successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }

      if (userRole === "contractor") {
        dispatch(updateContractorNote(requestBody));
      } else {
        dispatch(updateTruckDriverNote(requestBody));
      }
    } catch (error) {
      console.log(error?.message || error?.response?.data?.message);
    }
  };
};
