import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import Logo from "../../assets/img/logo/cheetah_broken_logo.png";
import Loader from "../../components/Loader/Loader";
import FileUpload from "../../components/Modals/FileUpload";
import { getDocumentLinkThunkAction, uploadDocumentThunkAction } from "../../redux/User/actions";
import { selectUser } from "../../redux/User/selectors";

const InsuranceFileUpload = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { isLoading, documentUpload, error } = useSelector(selectUser);

  useEffect(() => {
    const prms = {
      userId: searchParams.get("userId"),
      docId: searchParams.get("docId"),
      code: searchParams.get("code")
    };
    dispatch(getDocumentLinkThunkAction(prms));
  }, []);

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  };

  const addNewImageHandler = (files = []) => {
    const requestBodyFormData = new FormData();
    requestBodyFormData.append("userId", searchParams.get("userId"));
    requestBodyFormData.append("docId", searchParams.get("docId"));
    requestBodyFormData.append("code", searchParams.get("code"));

    files?.map((item) => {
      requestBodyFormData.append("file", item);
      requestBodyFormData.append("docType", files[0].name.split(".")[1]);
    });

    dispatch(uploadDocumentThunkAction(requestBodyFormData));
  };

  return (
    <>
      <div className="bg_contractor">
        <div className="bg_shadow">
          <Row
            className="justify-content-md-center contractor_container px-3"
            lg="3"
            md="9"
            xs="12"
          >
            <Col lg="3" md="8" xs="12" className="col-lg-auto">
              <Card className="bg-secondary shadow border-0 justify-content-md-center">
                <div
                  className="text-center"
                  style={{
                    background: "#F9F9F9",
                    borderRadius: "0.375rem 0.375rem 0 0"
                  }}
                >
                  <span>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{
                        width: "70%",
                        padding: "2rem 0",
                        marginLeft: "2rem"
                      }}
                    />
                  </span>
                </div>

                <CardBody className="px-lg-5 py-lg-1 container_card">
                  <div className="text-center  mb-4">
                    <h3 className="text-dark text-center fw-bold">Insurance Upload</h3>
                  </div>

                  {isLoading ? (
                    <React.Fragment>
                      <div className="my-2 text-center">
                        <Loader />
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="text-center">
                      {!error && documentUpload.length === 0 && (
                        <h5 className="text-danger text-center fw-bold">
                          {error?.response?.data?.message}
                        </h5>
                      )}
                      {error && (
                        <h5 className="text-danger text-center fw-bold">
                          {error?.response?.data?.message}
                        </h5>
                      )}
                      <Button
                        className="my-3 contractor_btn"
                        color="dark"
                        onClick={handleOpen}
                        disabled={
                          !documentUpload.length === undefined || documentUpload.length === 0
                        }
                      >
                        <div style={{ fontWeight: "bold" }}>Upload</div>
                      </Button>
                      <FileUpload
                        addNewImageHandler={addNewImageHandler}
                        handleClose={handleClose}
                        modalOpen={modalOpen}
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default InsuranceFileUpload;
