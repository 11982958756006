import React, { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import "../ContractorDetails/ContractorDetails.css";
import { fetchContractorList } from "../../redux/Admin/selectors";
import Loader from "../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchTruckDriverDetailsThunkAction } from "../../redux/Admin/action";
import toast from "react-hot-toast";
import { formatPhoneNumber } from "../../utils/helper";
import RequestDeniedModal from "./RequestDeniedModal";
import RequestApprovedModal from "./RequestApprovedModal";
import moment from "moment";
import { env } from "../../env";

const TruckDriverDetails = () => {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const [error, setError] = React.useState(null);
  const [ModalShow, setModalShow] = React.useState(false);
  const [approveModalShow, setApprovedModalShow] = React.useState(false);

  const dispatch = useDispatch();

  const { id: truckDriverId } = useParams();

  const { isLoading, truckDriverDetails } = useSelector(fetchContractorList);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  useEffect(() => {
    if (truckDriverId) {
      dispatch(fetchTruckDriverDetailsThunkAction(truckDriverId, onSuccess, onError));
    }
  }, [truckDriverId]);

  const healthCard = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "health_card") {
      return item;
    }
  });

  const w9FormDoc = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "w_9_form") {
      return item;
    }
  });

  const vehicleRegistration = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "vehicle_registration") {
      return item;
    }
  });

  const truckingVenderForm = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "trucking_vender_form") {
      return item;
    }
  });

  const drivingLicense = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "driving_license") {
      return item;
    }
  });

  const drugTestCertificate = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "drug_test_certificate") {
      return item;
    }
  });

  const insurance = truckDriverDetails?.truckerDocuments?.filter((item) => {
    if (item.type === "insurance" && item.url !== null) {
      return item;
    }
  });

  const ImageBaseUrl = env.REACT_APP_DEV_IMAGE_URL || process.env.REACT_APP_DEV_IMAGE_URL;

  return (
    <div className="px-3" style={{ height: "100%", backgroundColor: "#F9F9F9" }}>
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Truck Driver Details
          </h4>
        </Col>
      </Row>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <Card className="mt-4" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <div className="d-flex align-items-center">
                <i className="bi bi-blockquote-right"></i>
                <div className="mx-2 basic_details">Basic Details</div>
              </div>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row className="my-3">
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Truck driver Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.firstName ? truckDriverDetails?.firstName : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          Request Date
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.createdAt
                            ? moment(truckDriverDetails?.createdAt).format("D MMM YYYY")
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          DOT No.
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.usDotId ? truckDriverDetails?.usDotId : "-----"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Mobile Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.phone
                            ? formatPhoneNumber(truckDriverDetails?.phone)
                            : "-----"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Total Experience
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.totalExperience
                            ? truckDriverDetails?.totalExperience + " Years"
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mt-3" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <div className="d-flex align-items-center">
                <i className="bi bi-info-circle"></i>
                <div className="mx-2 basic_details">Trucker&apos;s Information</div>
              </div>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row className="my-3">
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Company Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.companyName
                            ? truckDriverDetails?.companyName
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          SWIFT Vendor Id
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.venderId ? truckDriverDetails?.venderId : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Contact Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.companyContactPerson
                            ? truckDriverDetails?.companyContactPerson
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          US DOT #
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.usDotId ? truckDriverDetails?.usDotId : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Street Address
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.address ? truckDriverDetails?.address : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          Federal Tax ID #
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.federalTaxId
                            ? truckDriverDetails?.federalTaxId
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          City State Zip
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.zip ? truckDriverDetails?.zip : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          State Tax ID #
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.stateTaxId
                            ? truckDriverDetails?.stateTaxId
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Phone Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.companyPhone
                            ? truckDriverDetails?.companyPhone
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          License Plate Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.licensePlateNumber
                            ? truckDriverDetails?.licensePlateNumber
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Email Address
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.companyEmail
                            ? truckDriverDetails?.companyEmail
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          Truck Company
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.truckCompany
                            ? truckDriverDetails?.truckCompany
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Account Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.accountNumber
                            ? truckDriverDetails?.accountNumber
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          Truck Company Phone
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.truckCompanyPhone
                            ? truckDriverDetails?.truckCompanyPhone
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Routing Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.routingNumber
                            ? truckDriverDetails?.routingNumber
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          Truck Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.truckNumber
                            ? truckDriverDetails?.truckNumber
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Preffered Method to Contact
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.methodOfContact
                            ? truckDriverDetails?.methodOfContact
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          VIN Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.vinNumber ? truckDriverDetails?.vinNumber : "-----"}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" lg="12" md="12" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="2" lg="2">
                          Emergency Contact Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.emergencyContactName
                            ? truckDriverDetails?.emergencyContactName
                            : "-----"}
                        </Col>
                        <Col className="label" xs="5" md="2" lg="2">
                          Emergency Contact Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="3" lg="3">
                          {truckDriverDetails?.emergencyContact
                            ? formatPhoneNumber(truckDriverDetails?.emergencyContact)
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mt-4" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <div className="d-flex align-items-center">
                <i className="bi bi-file-earmark-text"></i>
                <div className="mx-2 basic_details">Documentation</div>
              </div>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          Health Card
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>

                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {healthCard?.length > 0 ? (
                            healthCard?.map((item) => (
                              <div className="d-flex align-items-center" key={item.id}>
                                <span style={healthCard[0] === item ? {} : { display: "none" }}>
                                  Attached
                                </span>
                                <div className="col-xs-1">
                                  <a
                                    href={`${ImageBaseUrl + item.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                      size="sm"
                                      color="secondary"
                                      outline
                                    >
                                      <i
                                        style={{ marginRight: "4px" }}
                                        className="bi bi-paperclip"
                                      />
                                      View File
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          W-9
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>

                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {w9FormDoc?.length > 0 ? (
                            w9FormDoc?.map((item) => (
                              <div className="d-flex align-items-center" key={item.id}>
                                <span style={w9FormDoc[0] === item ? {} : { display: "none" }}>
                                  Attached
                                </span>
                                <div className="col-xs-1">
                                  <a
                                    href={`${ImageBaseUrl + item.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                      size="sm"
                                      color="secondary"
                                      outline
                                    >
                                      <i
                                        style={{ marginRight: "4px" }}
                                        className="bi bi-paperclip"
                                      />
                                      View File
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          Registration
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {vehicleRegistration?.length > 0 ? (
                            vehicleRegistration?.map((item) => (
                              <div className="d-flex align-items-center" key={item.id}>
                                <span
                                  style={vehicleRegistration[0] === item ? {} : { display: "none" }}
                                >
                                  Attached
                                </span>
                                <div className="col-xs-1">
                                  <a
                                    href={`${ImageBaseUrl + item.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                      size="sm"
                                      color="secondary"
                                      outline
                                    >
                                      <i
                                        style={{ marginRight: "4px" }}
                                        className="bi bi-paperclip"
                                      />
                                      View File
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          Trucking Vendor Form
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {truckingVenderForm?.length > 0 ? (
                            truckingVenderForm?.map((item) => (
                              <div className="d-flex align-items-center" key={item.id}>
                                <span
                                  style={truckingVenderForm[0] === item ? {} : { display: "none" }}
                                >
                                  Attached
                                </span>
                                <div className="col-xs-1">
                                  <a
                                    href={`${ImageBaseUrl + item.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                      size="sm"
                                      color="secondary"
                                      outline
                                    >
                                      <i
                                        style={{ marginRight: "4px" }}
                                        className="bi bi-paperclip"
                                      />
                                      View File
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          Driver&apos;s License
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {drivingLicense?.length > 0 ? (
                            drivingLicense?.map((item) => (
                              <div className="d-flex align-items-center" key={item.id}>
                                <span style={drivingLicense[0] === item ? {} : { display: "none" }}>
                                  Attached
                                </span>
                                <div className="col-xs-1">
                                  <a
                                    href={`${ImageBaseUrl + item.url}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Button
                                      style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                      size="sm"
                                      color="secondary"
                                      outline
                                    >
                                      <i
                                        style={{ marginRight: "4px" }}
                                        className="bi bi-paperclip"
                                      />
                                      View File
                                    </Button>
                                  </a>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          Insurance
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>

                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {insurance?.length > 0 ? (
                            insurance?.map((item) => {
                              if (!item?.url) {
                                return (
                                  <span
                                    style={{ fontWeight: "600" }}
                                    className="d-flex align-items-center"
                                    key={item?.id}
                                  >
                                    Not Attached
                                  </span>
                                );
                              }
                              return (
                                <div className="d-flex align-items-center" key={item.id}>
                                  <span style={insurance[0] === item ? {} : { display: "none" }}>
                                    Attached
                                  </span>
                                  <div className="col-xs-1">
                                    <a
                                      href={`${ImageBaseUrl + item.url}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Button
                                        style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                        size="sm"
                                        color="secondary"
                                        outline
                                      >
                                        <i
                                          style={{ marginRight: "4px" }}
                                          className="bi bi-paperclip"
                                        />
                                        View File
                                      </Button>
                                    </a>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="d-flex align-items-center">
                <i className="bi bi-capsule-pill"></i>
                <div className="mx-2 basic_details">Drug Consortium</div>
              </div>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row className="d-flex align-items-center">
                        <Col className="label" xs="5" md="5" lg="5">
                          Testing Report
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="d-flex flex-row value" xs="6" md="6" lg="6">
                          {drugTestCertificate?.length > 0 ? (
                            drugTestCertificate?.map((item) => {
                              if (!item?.url) {
                                return (
                                  <span
                                    style={{ fontWeight: "600" }}
                                    className="d-flex align-items-center"
                                    key={item?.id}
                                  >
                                    Not Attached
                                  </span>
                                );
                              }
                              return (
                                <div className="d-flex align-items-center" key={item.id}>
                                  <span
                                    style={
                                      drugTestCertificate[0] === item ? {} : { display: "none" }
                                    }
                                  >
                                    Attached
                                  </span>
                                  <div className="col-xs-1">
                                    <a
                                      href={`${ImageBaseUrl + item.url}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <Button
                                        style={{ marginLeft: "0.5rem", whiteSpace: "nowrap" }}
                                        size="sm"
                                        color="secondary"
                                        outline
                                      >
                                        <i
                                          style={{ marginRight: "4px" }}
                                          className="bi bi-paperclip"
                                        />
                                        View File
                                      </Button>
                                    </a>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div
                              style={{ fontWeight: "600" }}
                              className="d-flex align-items-center"
                            >
                              Not Attached
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.labName ? truckDriverDetails?.labName : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Contact name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.labContactPerson
                            ? truckDriverDetails?.labContactPerson
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Contact number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.labPhone
                            ? formatPhoneNumber(truckDriverDetails?.labPhone)
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Email
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.labEmail ? truckDriverDetails?.labEmail : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mt-4" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <div className="d-flex align-items-center">
                <i className="bi bi-file-earmark-medical"></i>
                <div className="mx-2 basic_details">Trucker Insurance</div>
              </div>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row className="my-3">
                    <Col xs="12" lg="6" md="6" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Agency Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.agencyName
                            ? truckDriverDetails?.agencyName
                            : "-----"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Contact Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.agencyContactPerson
                            ? truckDriverDetails?.agencyContactPerson
                            : "-----"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Phone Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.agencyPhone
                            ? formatPhoneNumber(truckDriverDetails?.agencyPhone)
                            : "-----"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Fax Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.agencyFax ? truckDriverDetails?.agencyFax : "-----"}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Insurance Provider
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {truckDriverDetails?.agencyEmail
                            ? truckDriverDetails?.agencyEmail
                            : "-----"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="mt-3" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <div className="d-flex justify-content-between">
                {truckDriverDetails?.isApproved === true ||
                truckDriverDetails?.isApproved === null ? (
                  <Button
                    size="md"
                    color="danger"
                    outline
                    onClick={() => {
                      setModalShow(true);
                    }}
                  >
                    <i style={{ marginRight: "4px" }} className="bi bi-x"></i>
                    Deny
                  </Button>
                ) : (
                  ""
                )}

                {truckDriverDetails?.isApproved === false ||
                truckDriverDetails?.isApproved === null ? (
                  <Button
                    size="md"
                    color="primary"
                    outline
                    onClick={() => {
                      setApprovedModalShow(true);
                    }}
                  >
                    <i style={{ marginRight: "4px" }} className="bi bi-check" />
                    Approve
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </CardBody>
          </Card>
        </React.Fragment>
      )}
      <RequestDeniedModal
        isOpen={ModalShow}
        toggleModal={() => setModalShow(false)}
        id={truckDriverId}
      />
      <RequestApprovedModal
        isOpen={approveModalShow}
        toggleModal={() => setApprovedModalShow(false)}
        id={truckDriverId}
      />
    </div>
  );
};

export default TruckDriverDetails;
