import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import AdminLayout from "./layouts/AdminLayout";
import ContractorLayout from "./layouts/ContractorLayout";
import userRoles from "./utils/roles";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRole, selectUserToken } from "../src/redux/User/selectors";
import dotenv from "dotenv";

import "../src/assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "flatpickr/dist/themes/material_orange.css";
import InsuranceFileUpload from "./pages/FileUpload/InsuranceFileUpload";

dotenv.config();

export const toggleContext = React.createContext();

function App() {
  const location = useLocation();
  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const defaultHomePage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/dashboard";
      case userRoles.CONTRACTOR:
        return "/contractor/current-job-posting";
      default:
        return "/admin/contractor-account";
    }
  };

  const defaultUnauthorizedPage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/unauthorized";
      case userRoles.CONTRACTOR:
        return "/contractor/unauthorized";
      default:
        return "/unauthorized";
    }
  };

  const defaultNotFoundPage = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/notfound";
      case userRoles.CONTRACTOR:
        return "/contractor/notfound";
      default:
        return "/nofound";
    }
  };

  return (
    <Routes>
      <Route path="/auth/*" element={<AuthLayout />} />
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/contractor/*" element={<ContractorLayout />} />
      <Route path="/truckdriver/insurance" element={<InsuranceFileUpload />} />
      {token ? (
        <Route
          path="/"
          element={<Navigate to={`${defaultHomePage()}`} replace state={{ from: location }} />}
        />
      ) : (
        <Route path="/" element={<Navigate to="/auth/admin/login" />} />
      )}
      <Route
        path="*"
        element={<Navigate to={`${defaultNotFoundPage()}`} replace state={{ from: location }} />}
      />
      <Route
        path="/unauthorized"
        element={
          <Navigate to={`${defaultUnauthorizedPage()}`} replace state={{ from: location }} />
        }
      />
    </Routes>
  );
}

export default App;
