import React from "react";
import { ClipLoader } from "react-spinners";

function Loader() {
  return (
    <>
      <ClipLoader color="#4d5f5b" />
    </>
  );
}

export default Loader;
