import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavLink,
  Row
} from "reactstrap";
import "./ContractorLogin.css";
import Logo from "../../assets/img/logo/cheetah_broken_logo.png";
import { Link } from "react-router-dom";
import usePasswordShow from "../../hooks/usePasswordShow";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginUserThunkAction } from "../../redux/User/actions";
import { selectUser } from "../../redux/User/selectors";

import showPasswordIcon from "../../assets/img/theme/showPassword.png";
import hidePasswordIcon from "../../assets/img/theme/hidePassword.png";

const ContractorLogin = () => {
  const { passwordShown, togglePasswordVisiblity, type } = usePasswordShow();

  const dispatch = useDispatch();

  const { isSigning } = useSelector(selectUser);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Must be a Valid Email").required("Email Required"),
      password: Yup.string().required("Password is Required")
    }),
    onSubmit: (values) => {
      const requestBody = {
        email: values.email,
        password: values.password
      };
      dispatch(fetchLoginUserThunkAction(requestBody));
    }
  });

  return (
    <>
      <div className="bg_contractor">
        <div className="bg_shadow">
          <Row
            className="justify-content-md-center contractor_container px-3"
            lg="3"
            md="7"
            xs="12"
          >
            <Col lg="3" md="7" xs="12" className="col-lg-auto">
              <Card className="bg-secondary shadow border-0 justify-content-md-center">
                <div
                  className="text-center"
                  style={{
                    background: "#F9F9F9",
                    borderRadius: "0.375rem 0.375rem 0 0"
                  }}
                >
                  <span>
                    <img
                      src={Logo}
                      alt="logo"
                      style={{
                        width: "70%",
                        padding: "2rem 0",
                        marginLeft: "2rem"
                      }}
                    />
                  </span>
                </div>

                <CardBody className="px-lg-5 py-lg-1 container_card">
                  <div className="text-center  mb-4">
                    <h3 className="text-dark text-center fw-bold">Welcome back!</h3>
                    <p>
                      Login below or &nbsp;
                      <Link
                        to="/auth/contractor/sign-in"
                        style={{
                          color: "black",
                          fontWeight: "500"
                        }}
                      >
                        <span>create an account</span>
                      </Link>
                    </p>
                  </div>
                  <Form role="form" onSubmit={formik.handleSubmit}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <input
                          type="text"
                          className="form-control container_input"
                          placeholder="Enter Your E-mail"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                      </InputGroup>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger mt-2">{formik.errors.email}</div>
                      ) : null}
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <Input
                          className="container_input"
                          placeholder="Password"
                          type={type}
                          autoComplete="password"
                          id="password"
                          name="password"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <InputGroupAddon
                          addonType="append"
                          style={{ cursor: "pointer", opacity: "0.9" }}
                          onClick={togglePasswordVisiblity}
                        >
                          <InputGroupText className="password_icon">
                            {passwordShown ? (
                              <img
                                style={{ height: "20px" }}
                                alt="hidepassword"
                                src={hidePasswordIcon}
                              />
                            ) : (
                              <img
                                style={{ height: "20px" }}
                                alt="showpassword"
                                src={showPasswordIcon}
                              />
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger mt-2">{formik.errors.password}</div>
                      ) : null}
                      <Row className="mt-3 justify-content-md-center">
                        <Col xs="12" className="container_forgot_password">
                          <NavLink to="/auth/forgotpassword" tag={Link}>
                            <small>Forgot Password?</small>
                          </NavLink>
                        </Col>
                      </Row>
                    </FormGroup>

                    <div className="text-center">
                      <Button
                        disabled={!formik.isValid || isSigning}
                        className="my-4 contractor_btn"
                        color="dark"
                        type="submit"
                      >
                        <div style={{ fontWeight: "bold" }}>
                          {isSigning ? "Logging in..." : "Login"}
                        </div>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ContractorLogin;
