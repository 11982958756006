import React, { useCallback, useState } from "react";
import { Form, Input } from "reactstrap";
import debounce from "lodash.debounce";

const Search = ({ onSubmitHandler, keyword, setKeyword }) => {
  const [value, setValue] = useState("");

  const debouncedSave = useCallback(
    debounce((nextValue) => setKeyword(nextValue), 200),
    []
  );

  const handleChange = (event) => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
    debouncedSave(nextValue);
  };

  return (
    <>
      <Form
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitHandler(keyword);
        }}
      >
        <Input placeholder="Search" type="text" value={value || ""} onChange={handleChange} />
      </Form>
    </>
  );
};

export default Search;
