import { Button, Badge } from "reactstrap";
import moment from "moment";
import { formatPhoneNumber } from "../../utils/helper";
import notesIcon from "../../assets/img/backgroundImages/notes_icon.png";

export const getColumns = (onClickViewHandler, handleNotes) => {
  return [
    {
      Header: "Id",
      accessor: "id",
      disableSortBy: true
    },
    {
      Header: "Contractor Name",
      accessor: "firstName",
      disableSortBy: true
    },
    {
      Header: "Mobile Number",
      accessor: "phone",
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span>{formatPhoneNumber(row.original.phone)}</span>;
      }
    },
    {
      Header: "Email Id",
      accessor: "email",
      disableSortBy: true
    },
    {
      Header: "EIN Number",
      accessor: "einNumber",
      disableSortBy: true,
      Cell: ({ row }) => {
        return <span>{row.original.contractorDetails?.einNumber}</span>;
      }
    },
    {
      Header: "Request Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        return <span>{moment(row.original.createdAt).format("D MMM YYYY")}</span>;
      }
    },
    {
      Header: "status",
      accessor: "is_approved",
      Cell: ({ row: value }) => {
        if (value.original.isApproved === true) {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge pill style={{ color: "white", backgroundColor: "#00B868" }}>
                Approved
              </Badge>
            </div>
          );
        } else if (value.original.isApproved === null) {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge pill style={{ color: "white", backgroundColor: "#A9A9A9" }}>
                Pending
              </Badge>
            </div>
          );
        } else {
          return (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "4%" }}
            >
              <Badge pill style={{ color: "white", backgroundColor: "#F03C00" }}>
                Denied
              </Badge>
            </div>
          );
        }
      }
    },
    {
      Header: "Notes",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center mt-2 ">
            <img
              src={notesIcon}
              alt="notes"
              style={{ cursor: "pointer" }}
              onClick={() => handleNotes(row?.original)}
            />
          </div>
        );
      }
    },
    {
      Header: "Actions",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <Button
              size="sm"
              color="primary"
              outline
              onClick={() => onClickViewHandler(row?.original?.id)}
            >
              <i className="bi bi-person" />
              View
            </Button>
          </div>
        );
      }
    }
  ];
};
