import axiosInstance from "./axiosInstance";

export const contractorListApi = (prms) =>
  axiosInstance.get(`/api/v1/admin/contractor-list`, {
    params: {
      limit: prms.limit || 10,
      page: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "created_at",
      order: prms?.order || "asc"
    }
  });

export const contractDetailsApi = (id) =>
  axiosInstance.get(`/api/v1/admin/contractor-detail/${id}`);

export const contractorStatusUpdateApi = (id, userData) =>
  axiosInstance.post(`/api/v1/admin/contractor-status/${id}`, userData);

export const truckDriverListApi = (prms) =>
  axiosInstance.get(`/api/v1/admin/truck-driver-list`, {
    params: {
      limit: prms.limit || 10,
      page: prms?.page || 0,
      search: prms?.search || "",
      sort: prms?.sort || "created_at",
      order: prms?.order || "asc"
    }
  });

export const truckDriverDetailsApi = (id) =>
  axiosInstance.get(`/api/v1/admin/truck-driver-detail/${id}`);

export const updateTruckDriverStatusApi = (id, userData) =>
  axiosInstance.post(`/api/v1/admin/truck-driver-status/${id}`, userData);

export const noteAdminApi = (userData) => axiosInstance.post("api/v1/admin/note", userData);
