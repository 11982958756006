import {
  FETCH_FOREMAN_LIST,
  FETCH_FOREMAN_LIST_SUCCESS,
  FETCH_FOREMAN_LIST_FAILURE,
  ADD_FOREMAN,
  ADD_FOREMAN_SUCCESS,
  ADD_FOREMAN_FAILURE,
  DELETE_FOREMAN,
  DELETE_FOREMAN_SUCCESS,
  DELETE_FOREMAN_FAILURE,
  ADD_JOB,
  ADD_JOB_SUCCESS,
  ADD_JOB_FAILURE,
  FETCH_CURRENT_JOB,
  FETCH_CURRENT_JOB_SUCCESS,
  FETCH_CURRENT_JOB_FAILURE,
  DELETE_JOB,
  DELETE_JOB_SUCCESS,
  DELETE_JOB_FAILURE,
  JOB_DETAILS,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAILURE,
  UPDATE_JOB,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
  SET_FOREMAN_PAGE_SIZE,
  SET_CURRENT_JOB_PAGE_SIZE
} from "./types";

const initialState = {
  isLoading: false,
  foremanStatusList: [],
  totalPage: 0,
  error: "",
  isAdding: false,
  currentJobPosting: [],
  jobDetails: [],
  foremanPageSize: 10,
  currentJobPageSize: 10
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FOREMAN_LIST:
      return { ...state, isLoading: true };
    case FETCH_FOREMAN_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        foremanStatusList: action.payload.foremanList,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_FOREMAN_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        foremanStatusList: [],
        error: action.payload
      };

    case FETCH_CURRENT_JOB:
      return { ...state, isLoading: true };

    case FETCH_CURRENT_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentJobPosting: action.payload.currentJobPosting,
        totalPage: action.payload.totalPage,
        error: ""
      };
    case FETCH_CURRENT_JOB_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentJobPosting: [],
        error: action.payload
      };

    case JOB_DETAILS:
      return { ...state, isLoading: true };

    case JOB_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobDetails: action.payload.jobDetails,
        error: ""
      };
    case JOB_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        jobDetails: [],
        error: action.payload
      };

    case ADD_FOREMAN:
      return {
        ...state,
        isLoading: true
      };
    case ADD_FOREMAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: ""
      };
    case ADD_FOREMAN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case DELETE_FOREMAN:
      return {
        ...state
      };
    case DELETE_FOREMAN_SUCCESS:
      return {
        ...state
      };
    case DELETE_FOREMAN_FAILURE:
      return {
        ...state
      };
    case ADD_JOB:
      return {
        ...state,
        isAdding: true
      };
    case ADD_JOB_SUCCESS:
      return {
        ...state,
        isAdding: false
      };
    case ADD_JOB_FAILURE:
      return {
        ...state,
        isAdding: false
      };

    case DELETE_JOB:
      return {
        ...state
      };
    case DELETE_JOB_SUCCESS:
      return {
        ...state
      };
    case DELETE_JOB_FAILURE:
      return {
        ...state
      };

    case UPDATE_JOB:
      return {
        ...state
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state
      };
    case UPDATE_JOB_FAILURE:
      return {
        ...state
      };
    case SET_FOREMAN_PAGE_SIZE:
      return {
        ...state,
        foremanPageSize: action.payload
      };
    case SET_CURRENT_JOB_PAGE_SIZE:
      return {
        ...state,
        currentJobPageSize: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
