import {
  DASHBOARD_STATISTICS_FAILURE,
  DASHBOARD_STATISTICS_REQUEST,
  DASHBOARD_STATISTICS_SUCCESS,
  DASHBOARD_STATISTICS_TWO_REQUEST,
  DASHBOARD_STATISTICS_TWO_FAILURE,
  DASHBOARD_STATISTICS_TWO_SUCCESS,
  LOG_DETAILS_REQUEST,
  LOG_DETAILS_SUCCESS,
  LOG_DETAILS_FAILURE
} from "./types";

const initialState = {
  isDashboardStatisticsLoading: false,
  isDashboardStatisticsTwoLoading: false,
  isLogDetailsLoading: false,
  dashboardStatistics: null,
  dashboardStatisticsTwo: null,
  logDetails: null,
  isDashboardStatisticsError: "",
  isDashboardStatisticsTwoError: "",
  isLogDetailsError: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_STATISTICS_REQUEST:
      return { ...state, isDashboardStatisticsLoading: true };
    case DASHBOARD_STATISTICS_SUCCESS:
      return {
        ...state,
        isDashboardStatisticsLoading: false,
        dashboardStatistics: action.payload,
        isDashboardStatisticsError: ""
      };
    case DASHBOARD_STATISTICS_FAILURE:
      return {
        ...state,
        isDashboardStatisticsLoading: false,
        dashboardStatistics: null,
        isDashboardStatisticsError: action.payload
      };

    case DASHBOARD_STATISTICS_TWO_REQUEST:
      return {
        ...state,
        isDashboardStatisticsTwoLoading: true
      };
    case DASHBOARD_STATISTICS_TWO_SUCCESS:
      return {
        ...state,
        isDashboardStatisticsTwoLoading: false,
        dashboardStatisticsTwo: action.payload,
        isDashboardStatisticsTwoError: ""
      };
    case DASHBOARD_STATISTICS_TWO_FAILURE:
      return {
        ...state,
        isDashboardStatisticsTwoLoading: false,
        dashboardStatisticsTwo: null,
        isDashboardStatisticsTwoError: action.payload
      };

    case LOG_DETAILS_REQUEST:
      return {
        ...state,
        isLogDetailsLoading: true
      };
    case LOG_DETAILS_SUCCESS:
      return {
        ...state,
        isLogDetailsLoading: false,
        isLogDetailsError: "",
        logDetails: action.payload
      };
    case LOG_DETAILS_FAILURE:
      return {
        ...state,
        isLogDetailsLoading: false,
        isLogDetailsError: action.payload,
        logDetails: null
      };

    default:
      return state;
  }
};

export default reducer;
