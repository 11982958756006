import {
  JOB_DETAILS_FAILURE,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_SUMMARY_LISTING_FAILURE,
  JOB_SUMMARY_LISTING_REQUEST,
  JOB_SUMMARY_LISTING_SUCCESS,
  JOB_SUMMARY_LOGS_FAILURE,
  JOB_SUMMARY_LOGS_REQUEST,
  JOB_SUMMARY_LOGS_SUCCESS
} from "./types";

import toast from "react-hot-toast";
import { jobSummaryDetailsApi } from "services/jobSummaryServices";
import { jobSummaryLogsApi } from "services/jobSummaryServices";
import { jobSummaryListingApi } from "services/jobSummaryServices";

export const jobSummaryListingRequest = () => {
  return {
    type: JOB_SUMMARY_LISTING_REQUEST
  };
};

export const jobSummaryListingSuccess = (data) => {
  return {
    type: JOB_SUMMARY_LISTING_SUCCESS,
    payload: data
  };
};

export const jobSummaryListingFailure = (error) => {
  return {
    type: JOB_SUMMARY_LISTING_FAILURE,
    payload: error
  };
};

//---------------------------------------------------

export const jobSummaryLogsRequest = () => {
  return {
    type: JOB_SUMMARY_LOGS_REQUEST
  };
};

export const jobSummaryLogsSuccess = (data) => {
  return {
    type: JOB_SUMMARY_LOGS_SUCCESS,
    payload: data
  };
};

export const jobSummaryLogsFailure = (error) => {
  return {
    type: JOB_SUMMARY_LOGS_FAILURE,
    payload: error
  };
};

//---------------------------------------------------

export const jobDetailsRequest = () => {
  return {
    type: JOB_DETAILS_REQUEST
  };
};

export const jobDetailsSuccess = (data) => {
  return {
    type: JOB_DETAILS_SUCCESS,
    payload: data
  };
};

export const jobDetailsFailure = (error) => {
  return {
    type: JOB_DETAILS_FAILURE,
    payload: error
  };
};

//---------------------------------------------------

export const fetchJobSummaryListingThunkAction = (params) => {
  return async (dispatch) => {
    try {
      dispatch(jobSummaryListingRequest());
      const { data } = await jobSummaryListingApi(params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(
        jobSummaryListingSuccess({
          jobsData: data?.data?.jobsData,
          total: Math.ceil(data.data?.total / 10)
        })
      );
    } catch (error) {
      dispatch(jobSummaryListingFailure(error?.response?.data?.message || error?.message));
    }
  };
};

export const fetchJobSummaryLogsThunkAction = (jobId, params) => {
  return async (dispatch) => {
    try {
      dispatch(jobSummaryLogsRequest());
      const { data } = await jobSummaryLogsApi(jobId, params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(
        jobSummaryLogsSuccess({
          jobsData: data?.data?.driverData,
          total: Math.ceil(data.data?.total / 5)
        })
      );
    } catch (error) {
      dispatch(jobSummaryLogsFailure(error?.response?.data?.message || error?.message));
    }
  };
};

export const fetchJobDetailsThunkAction = (jobId, params) => {
  return async (dispatch) => {
    try {
      dispatch(jobDetailsRequest());
      const { data } = await jobSummaryDetailsApi(jobId, params);

      if (data.success !== true) {
        throw new Error(data?.message);
      }

      dispatch(jobDetailsSuccess(data?.data));
    } catch (error) {
      dispatch(jobDetailsFailure(error?.response?.data?.message || error?.message));
    }
  };
};
