import Foreman from "../assets/img/sidebarIcon/foreman.png";
import CurrentJob from "../assets/img/sidebarIcon/currentJob.png";
import AddJob from "../assets/img/sidebarIcon/addJob.png";
import InactiveForeman from "../assets/img/sidebarIcon/inactiveForeman.png";
import InactiveCurrentJob from "../assets/img/sidebarIcon/inactiveCurrentJob.png";
import InactivePostJob from "../assets/img/sidebarIcon/inactivePostJob.png";
import TruckDriver from "../assets/img/sidebarIcon/TruckDriver.png";
import TruckDriverActive from "../assets/img/sidebarIcon/TruckDriverActive.png";

import dashboard from "../assets/img/sidebarIcon/dashboard_icon.png";
import dashboardActive from "../assets/img/sidebarIcon/active_dashboard_icon.png";

import jobSummary from "../assets/img/sidebarIcon/job_summury.png";
import ActiveJobSummary from "../assets/img/sidebarIcon/active_job_summury.png";

const adminSidebarRoutes = [
  {
    title: "Dashboard",
    active: dashboardActive,
    inActive: dashboard,
    path: "/admin/dashboard"
  },
  {
    title: "Contractor",
    active: Foreman,
    inActive: InactiveForeman,
    path: "/admin/contractor-account"
  },
  {
    title: "Truck Driver",
    active: TruckDriverActive,
    inActive: TruckDriver,
    path: "/admin/truckdriver-account"
  },
  {
    title: "Job Summary",
    active: ActiveJobSummary,
    inActive: jobSummary,
    path: "/admin/job-summary"
  }
];

const contractorSidebarRoutes = [
  {
    title: "List of Foreman",
    active: Foreman,
    inActive: InactiveForeman,
    path: "/contractor/foreman-list"
  },
  {
    title: "Current Job Posting",
    active: CurrentJob,
    inActive: InactiveCurrentJob,
    path: "/contractor/current-job-posting"
  },
  {
    title: "Post a New Job",
    active: AddJob,
    inActive: InactivePostJob,
    path: "/contractor/post-new-job"
  }
];

export { contractorSidebarRoutes };

export default adminSidebarRoutes;
