import React, { useEffect, useState } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Row, Col, Card, CardBody, Button, Input } from "reactstrap";
import NumberFormat from "react-number-format";
import axiosInstance from "../../services/axiosInstance";
import { fetchForemenList } from "../../redux/Contractor/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./NewJob.css";
import { addJobThunkAction } from "../../redux/Contractor/action";

const NewJob = () => {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);

  const [foreman, setForeman] = useState([]);
  const [foremanPhone, setForemanPhone] = useState("");
  const [foremanId, setForemanId] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobError, setJobError] = useState(null);
  const [hourlyRate, setHourlyRate] = useState("");
  const [error, setError] = useState(null);
  const [rateError, setRateError] = useState(null);
  const [payment, setPayment] = useState("Daily");
  const [prevailing, setPrevailing] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    axiosInstance
      .get("/api/v1/contractor/foreman-list-data")
      .then((response) => setForeman(response?.data?.data));
  }, []);
  const foremanList = foreman?.map((item) => {
    return capitalizeFirstLetter(item?.firstName) + " " + capitalizeFirstLetter(item?.lastName);
  });

  const paymentMethod = ["Daily", "Weekly"];

  const AddJob = (event) => {
    event.preventDefault();
    if (foremanId === "") {
      setError("Please Select Foreman!");
    }
    if (jobTitle.trim() === "") {
      setJobError("Please Enter Job Title!");
    }
    if (hourlyRate.trim() === "") {
      setRateError("Please Enter Hourly Rate!");
    }

    if (error === "" && rateError === "" && jobError === "") {
      const requestBody = {
        prevalling: prevailing,
        foreman_id: foremanId,
        hourly_rate: +hourlyRate,
        payment_method: payment,
        job_title: jobTitle
      };

      dispatch(addJobThunkAction(requestBody, onSuccess));
      setForeman([]);
      setHourlyRate("");
      setPayment("Daily");
    }
  };

  const onSuccess = () => {
    navigate("/contractor/current-job-posting");
  };
  const { isAdding } = useSelector(fetchForemenList);

  return (
    <div className="px-3">
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Post New Jobs
          </h4>
        </Col>
      </Row>
      <Card className="mt-4" style={{ background: "#ffffff" }}>
        <CardBody>
          <Row>
            <form onSubmit={AddJob}>
              <Col className="mx-3" style={{ fontStyle: "normal" }}>
                <Row>
                  <Col xs="12" lg="9" md="8" className="mb-2">
                    <Row className="d-flex align-items-center">
                      <Col className="label" xs="4" md="4" lg="2">
                        Prevailing
                      </Col>
                      <Col className="label" xs="1" md="1" lg="1">
                        <Input
                          type="radio"
                          name="prevailing"
                          defaultChecked
                          value={prevailing === true}
                          onChange={(e) => setPrevailing(!prevailing)}
                        />
                      </Col>
                      <Col className="label" xs="5" md="4" lg="2">
                        Non Prevailing
                      </Col>
                      <Col className="label" xs="1" md="1" lg="1">
                        <Input
                          type="radio"
                          name="prevailing"
                          value={prevailing === false}
                          onChange={(e) => setPrevailing(!prevailing)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="12" md="12" className="my-3">
                    <Row>
                      <Col className="text" xs="12" md="6" lg="6">
                        Job Foreman name <span style={{ color: "red" }}>*</span>
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="select"
                              style={{ appearance: "auto", cursor: "pointer" }}
                              defaultValue={"DEFAULT"}
                              className="form-control"
                              onChange={(value) => {
                                const selectedForeman = foreman.find(
                                  ({ firstName }) =>
                                    firstName.toLocaleLowerCase().trim() ===
                                    value.target.value.split(" ")[0].toLocaleLowerCase()
                                );
                                setForemanPhone(selectedForeman.phone);
                                setForemanId(selectedForeman.id);
                                setError("");
                              }}
                            >
                              <option disabled value="DEFAULT">
                                {" "}
                                -- Select Foreman --{" "}
                              </option>
                              {foremanList.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <div className="text-danger mt-2">{error}</div>
                        </Row>
                      </Col>
                      <Col className="text" xs="12" md="6" lg="6">
                        Job Foreman contact number
                        <Row>
                          <Col className="my-2">
                            <NumberFormat
                              style={{ backgroundColor: "white" }}
                              className="form-control"
                              format="+1(###) ###-##-##"
                              mask="_"
                              placeholder="Phone Number"
                              id="phone"
                              name="phone"
                              disabled
                              value={foremanPhone}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="12" md="12">
                    <Row>
                      <Col className="text" xs="12" md="12" lg="12">
                        Job Title <span style={{ color: "red" }}>*</span>
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="text"
                              placeholder="Job Title"
                              onChange={(e) => {
                                setJobTitle(e.target.value);
                                setJobError("");
                              }}
                            />
                          </Col>
                          <div className="text-danger mt-2">{jobError}</div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="12" md="12">
                    <Row>
                      <Col className="text" xs="12" md="12" lg="12">
                        Hourly rate <span style={{ color: "red" }}>*</span>
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="number"
                              placeholder="Hourly Rate (In $)"
                              min="1"
                              step="0.01"
                              name="hourly_rate"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                setHourlyRate(e.target.value);
                                setRateError("");
                              }}
                            />
                          </Col>
                          <div className="text-danger mt-2">{rateError}</div>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" lg="12" md="12">
                    <Row>
                      <Col className="text" xs="12" md="12" lg="12">
                        Payment method/Payout
                        <Row className="my-2">
                          <Col>
                            <Input
                              type="select"
                              style={{ appearance: "auto", cursor: "pointer" }}
                              onChange={(e) => {
                                setPayment(e.target.value);
                              }}
                            >
                              {paymentMethod.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col xs="12" lg="12" md="12">
                    <Row>
                      <Col xs="12" md="4" lg="12">
                        <Button
                          type="submit"
                          size={width > 786 ? "lg" : "md"}
                          style={{ backgroundColor: "#FFA302", border: "#FFA302" }}
                        >
                          {isAdding ? "Adding" : "Post Job"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </form>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default NewJob;
