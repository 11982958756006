export const FETCH_CONTRACTOR_LIST = "SET_CONTRACTOR_STATUS";
export const FETCH_CONTRACTOR_LIST_SUCCESS = "FETCH_CONTRACTOR_STATUS_SUCCESS";
export const FETCH_CONTRACTOR_LIST_FAILURE = "FETCH_CONTRACTOR_STATUS_FAILURE";

export const FETCH_CONTRACTOR_DETAILS = "SET_CONTRACTOR_DETAIL";
export const FETCH_CONTRACTOR_DETAILS_SUCCESS = "FETCH_CONTRACTOR_DETAILS_SUCCESS";
export const FETCH_CONTRACTOR_DETAILS_FAILURE = "FETCH_CONTRACTOR_DETAILS_FAILURE";

export const UPDATE_CONTRACTOR_STATUS = "UPDATE_CONTRACTOR_STATUS";
export const UPDATE_CONTRACTOR_STATUS_SUCCESS = "UPDATE_CONTRACTOR_STATUS_SUCCESS";
export const UPDATE_CONTRACTOR_STATUS_FAILURE = "UPDATE_CONTRACTOR_STATUS_FAILURE";

export const FETCH_TRUCK_DRIVER = "FETCH_TRUCK_DRIVER";
export const FETCH_TRUCK_DRIVER_SUCCESS = "FETCH_TRUCK_DRIVER_SUCCESS";
export const FETCH_TRUCK_DRIVER_FAILURE = "FETCH_TRUCK_DRIVER_FAILURE";

export const FETCH_TRUCK_DRIVER_DETAILS = "FETCH_TRUCK_DRIVER_DETAILS";
export const FETCH_TRUCK_DRIVER_DETAILS_SUCCESS = "FETCH_TRUCK_DRIVER_DETAILS_SUCCESS";
export const FETCH_TRUCK_DRIVER_DETAILS_FAILURE = "FETCH_TRUCK_DRIVER_DETAILS_FAILURE";

export const UPDATE_TRUCK_DRIVER_STATUS = "UPDATE_TRUCK_DRIVER_STATUS";
export const UPDATE_TRUCK_DRIVER_STATUS_SUCCESS = "UPDATE_TRUCK_DRIVER_STATUS_SUCCESS";
export const UPDATE_TRUCK_DRIVER_STATUS_FAILURE = "UPDATE_TRUCK_DRIVER_STATUS_FAILURE";

export const SET_CONTRACTOR_PAGE_SIZE = "SET_CONTRACTOR_PAGE_SIZE";
export const SET_TRUCK_DRIVER_PAGE_SIZE = "SET_TRUCK_DRIVER_PAGE_SIZE";

export const UPDATE_CONTRACTOR_NOTE = "UPDATE_CONTRACTOR_NOTE";
export const UPDATE_TRUCK_DRIVER_NOTE = "UPDATE_TRUCK_DRIVER_NOTE";
