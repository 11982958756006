import axiosInstance from "./axiosInstance";

export const dashboardStatisticsApi = () => axiosInstance.get(`/api/v1/admin/dashboard`);

export const dashboardStatisticsTwoApi = (params) =>
  axiosInstance.get(`/api/v1/admin/dashboard/statistics`, {
    params: {
      start_date: params?.start_date || undefined,
      end_date: params?.end_date || undefined
    }
  });

export const logDetailsApi = (params) =>
  axiosInstance.get(`/api/v1/admin/dashboard/job-outstanding`, {
    params: {
      start_date: params?.start_date || undefined,
      end_date: params?.end_date || undefined
    }
  });
