import React, { useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchContractorList } from "../../redux/Admin/selectors";
import { fetchContractorDetailsThunkAction } from "../../redux/Admin/action";
import { formatPhoneNumber } from "../../utils/helper";

import "./ContractorDetails.css";
import Contractor from "../../assets/img/sidebarIcon/contractor.jpg";
import Loader from "../../components/Loader/Loader";
import toast from "react-hot-toast";
import RequestApprovedModal from "./RequestApprovedModal";
import RequestDenyModal from "./RequestDenyModal";
import moment from "moment/moment";

const ContractorDetails = () => {
  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);
  const [error, setError] = React.useState(null);
  const [approveModal, setApproveModal] = React.useState(false);
  const [denyModal, setDenyModal] = React.useState(false);

  const { id: contractorId } = useParams();
  const dispatch = useDispatch();

  const { isLoading, contractorDetails } = useSelector(fetchContractorList);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  useEffect(() => {
    if (contractorId) {
      dispatch(fetchContractorDetailsThunkAction(contractorId, onSuccess, onError));
    }
  }, [contractorId]);

  return (
    <div className="px-3">
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            Contractor Details
          </h4>
        </Col>
      </Row>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Loader />
        </div>
      ) : (
        <React.Fragment>
          <Card className="mt-4" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          Contractor Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.dispatcherName}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Company Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.companyName}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Dispatcher Name
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.dispatcherName}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Mobile Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {formatPhoneNumber(contractorDetails?.phone)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Email Id
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.email}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Address
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.address}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          City/State/Country
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.city + ", " + contractorDetails?.state}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Request Date
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {moment(contractorDetails?.createdAt).format("D MMM YYYY")}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg="3"
                  md="3"
                  style={
                    width >= 916
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }
                      : { display: "none" }
                  }
                >
                  <Row className="imageStyle">
                    {contractorDetails?.logo && (
                      <img className="my-2" src={`${contractorDetails?.logo}`} />
                    )}
                    {!contractorDetails?.logo && <img className="my-2" src={Contractor} />}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mt-3" style={{ background: "#FFFFFF" }}>
            <CardBody>
              <Row>
                <Col className="mx-3" style={{ fontStyle: "normal" }}>
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" xs="5" md="5" lg="5">
                          EIN Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.einNumber}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Bank Account Number
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.bankAccountNumber}
                        </Col>
                      </Row>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xs="12" lg="9" md="8" className="mb-2">
                      <Row>
                        <Col className="label" md="5" lg="5" xs="5">
                          Contracts
                        </Col>
                        <Col xs="1" md="1" lg="1">
                          :
                        </Col>
                        <Col className="value" xs="6" md="6" lg="6">
                          {contractorDetails?.serviceContract + " Years"}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg="3" md="3"></Col>
              </Row>
            </CardBody>
          </Card>

          <CardBody className="mt-3">
            <div className="d-flex justify-content-between">
              {(contractorDetails?.isApproved === true || contractorDetails?.isApproved === null) &&
              !contractorDetails?.hasJob ? (
                <Button size="md" color="danger" outline onClick={() => setDenyModal(true)}>
                  <i style={{ marginRight: "4px" }} className="bi bi-x"></i>
                  Deny
                </Button>
              ) : (
                ""
              )}

              {contractorDetails?.isApproved === false || contractorDetails?.isApproved === null ? (
                <Button size="md" color="primary" outline onClick={() => setApproveModal(true)}>
                  <i style={{ marginRight: "4px" }} className="bi bi-check" />
                  Approve
                </Button>
              ) : (
                ""
              )}
            </div>
          </CardBody>
        </React.Fragment>
      )}
      <RequestDenyModal
        isOpen={denyModal}
        toggleModal={() => setDenyModal(false)}
        id={contractorId}
      />
      <RequestApprovedModal
        isOpen={approveModal}
        toggleModal={() => setApproveModal(false)}
        id={contractorId}
      />
    </div>
  );
};

export default ContractorDetails;
