import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter, Row, Input, Col } from "reactstrap";
import toast from "react-hot-toast";
import {
  fetchTruckDriverListThunkAction,
  updateTruckDriverStatusThunkAction
} from "../../redux/Admin/action";
import { useNavigate } from "react-router-dom";

const RequestDeniedModal = ({ toggleModal, isOpen, id }) => {
  const [denyError, setDenyError] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [reason, setReason] = React.useState("");
  const dispatch = useDispatch();
  const navigator = useNavigate();

  const DenyModal = () => {
    if (reason === "" || reason.length < 10) {
      setDenyError("Please Enter Appropriate Reason");
    } else {
      const userData = { status: false, reason: reason };
      dispatch(updateTruckDriverStatusThunkAction(id, userData, onNavigate, onError));
      toggleModal();
    }
  };

  const onNavigate = () => {
    navigator("/admin/truckdriver-account");
    dispatch(
      fetchTruckDriverListThunkAction(
        {
          limit: 10
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  if (error) {
    toast.error(error);
  }

  return (
    <div className="text-center">
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <div className="modal-header">
          <h5 className="modal-title">Denying Reason</h5>
          <button
            onClick={() => toggleModal(false)}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <ModalBody>
          <Row className="my-2">
            <Col style={{ fontSize: "20px", fontWeight: "bold" }}>Reason :</Col>
          </Row>
          <Row>
            <Col>
              <Input
                type="textarea"
                name="reason"
                id="reason"
                placeholder="Enter Reason for Denying"
                onChange={(e) => {
                  setReason(e.target.value);
                  setDenyError("");
                }}
              />
            </Col>
            <div className="text-danger mt-2">{denyError}</div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              DenyModal();
            }}
          >
            Deny
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RequestDeniedModal;
