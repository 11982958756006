import React, { useEffect } from "react";
import useViewport from "../../hooks/useViewport";
import { toggleContext } from "../../App";
import { Col, Row, Table, Button, Input } from "reactstrap";
import { getColumns } from "./ForemanList";
import AddForemanModal from "./AddForemanModal";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import Paginate from "../../components/Pagination/Pagination";
import { fetchForemenList } from "../../redux/Contractor/selectors";
import { selectUserToken } from "../../redux/User/selectors";
import { usePagination, useSortBy, useGlobalFilter } from "react-table";
import { fetchForemanListThunkAction } from "../../redux/Contractor/action";
import { setForemanPageSize } from "../../redux/Contractor/action";

import DeleteForemanModal from "./DeleteForemanModal";

import { useTable } from "react-table";
import Search from "../../components/Search/Search";

const INITIAL_PAGE_INDEX = 0;

const Foreman = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteModalShow, setDeleteModalShow] = React.useState(false);
  const [id, setId] = React.useState("");
  const [foremanLists, setForemanLists] = React.useState([]);
  const [sort, setSort] = React.useState({ sortBy: "created_at", sortDirection: "asc" });
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();

  const { width } = useViewport();
  const { isopen } = React.useContext(toggleContext);

  const token = useSelector(selectUserToken) || localStorage.getItem("token");
  const {
    foremanStatusList,
    isLoading,
    totalPage,
    foremanPageSize: customPageSize
  } = useSelector(fetchForemenList);

  const [size, setSize] = React.useState(customPageSize);

  useEffect(() => {
    setForemanLists(foremanStatusList);
  }, [foremanStatusList, foremanStatusList?.length]);

  const onClickViewHandler = (id) => {
    setDeleteModalShow(true);
    setId(id);
  };

  const columns = React.useMemo(() => getColumns(onClickViewHandler), []);
  const data = React.useMemo(() => foremanLists, [foremanLists]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: INITIAL_PAGE_INDEX,
        sortBy: [],
        pageSize: size
      },
      manualPagination: true,
      manualSortBy: true,
      disableMultiSort: false,
      manualGlobalFilter: true,
      pageCount: Math.ceil(totalPage)
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    setSortBy,
    setPageSize,
    setGlobalFilter
  } = tableInstance;

  const { pageIndex, globalFilter, pageSize } = state;

  useEffect(() => {
    if (token) {
      dispatch(
        fetchForemanListThunkAction(
          {
            search: globalFilter || "",
            limit: pageSize,
            page: pageIndex,
            sort: sort?.sortBy,
            order: sort?.sortDirection
          },
          onSuccess,
          onError
        )
      );
    }
  }, [pageIndex, pageSize, globalFilter, token, sort]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSubmitHandler = (globalFilter) => {
    setGlobalFilter(globalFilter);
  };

  const showPage = [5, 10, 20, 30, 50];

  return (
    <div className="px-3">
      <Row
        className={width > 786 || isopen ? "justify-content-between my-3" : "mx-4 header"}
        md="4"
      >
        <Col>
          <h4
            style={
              width > 786 || isopen
                ? { color: "#1A1A1A", fontWeight: "bolder", marginTop: "25px" }
                : {
                    color: "#1A1A1A",
                    fontWeight: "bolder",
                    textAlign: "center",
                    marginTop: "25px"
                  }
            }
          >
            List of Foreman
          </h4>
        </Col>
      </Row>

      <Row className="pagination justify-content-end mb-0 d-flex flex-wrap align-items-center">
        <Col xs="12" md="7" className="d-flex align-items-center">
          <span>Show</span>
          <Input
            type="select"
            style={{
              appearance: "auto",
              width: "8rem",
              margin: "10px",
              cursor: "pointer"
            }}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              dispatch(setForemanPageSize(Number(e.target.value)));
            }}
          >
            {showPage.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col xs="12" md="5">
          <div className="d-flex justify-content-end m-3">
            <Search
              keyword={globalFilter}
              onSubmitHandler={onSubmitHandler}
              setKeyword={setGlobalFilter}
            />

            <Button
              onClick={() => setModalShow(true)}
              className="mx-3"
              size={width < 510 ? "sm" : "md"}
              style={{ backgroundColor: "#FFA302", border: "#FFA302" }}
            >
              <i style={width < 510 ? { display: "none" } : {}} className="bi bi-plus"></i>
              Add Foreman
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="mt-2">
        {isLoading ? (
          <React.Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {error ? (
              <React.Fragment>
                <div className="post_error_loading">{error}</div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table
                  {...getTableProps()}
                  className="align-items-center table-flush Firms_table_container"
                  responsive
                  bordered
                >
                  <thead className="thead-light Firms_table_header">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup}>
                        {headerGroup.headers.map((column) => (
                          <th
                            key={column.id}
                            style={!column.disableSortBy ? { cursor: "pointer" } : {}}
                            onClick={() => {
                              if (!column.disableSortBy) {
                                const desc =
                                  column.isSortedDesc === true
                                    ? undefined
                                    : column.isSortedDesc === false
                                    ? true
                                    : false;

                                setSort({
                                  sortBy: column.id,
                                  sortDirection: desc ? "asc" : "desc"
                                });

                                setSortBy([{ id: column.id, desc }]);
                              }
                            }}
                            {...column.getHeaderProps()}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="bi bi-arrow-down mx-2"></i>
                                ) : (
                                  <i className="bi bi-arrow-up mx-2"></i>
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()} key={row.id}>
                          {row.cells.map((cell) => {
                            return (
                              <td key={cell.id} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {!error && foremanLists?.length === 0 && (
                  <div className="post_error_loading">No Foreman found</div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {isLoading === false && error === null ? (
          <nav aria-label="..." className="post_footer">
            <Paginate
              pageIndex={pageIndex}
              pageCount={pageCount}
              previousPage={previousPage}
              nextPage={nextPage}
              gotoPage={gotoPage}
            />
          </nav>
        ) : null}
      </Row>
      <AddForemanModal isOpen={modalShow} toggleModal={() => setModalShow(false)} />
      <DeleteForemanModal
        isOpen={deleteModalShow}
        toggleModal={() => setDeleteModalShow(false)}
        id={id}
      />
    </div>
  );
};

export default Foreman;
