import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import RestrictedAuth from "../components/RestrictedAuth";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import ContractorLogin from "../pages/ContractorLogin/ContractorLogin";
import CreateContractorAccount from "../pages/CreateContractorAccount/CreateContractorAccount";
import ContractorRegistration from "../pages/Register/ContractorRegistration";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import VerifyOtp from "../pages/ForgotPassword/VerifyOtp";
import { ResetPassword } from "../pages/ForgotPassword/ResetPassword";

const AuthLayout = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route element={<RestrictedAuth restricted={true} />}>
        <Route path="admin/login" element={<AdminLogin />} />

        <Route path="contractor/login" element={<ContractorLogin />} />
        <Route path="contractor/register" element={<ContractorRegistration />} />
        <Route path="contractor/sign-in" element={<CreateContractorAccount />} />

        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="verifyOtp" element={<VerifyOtp />} />
        <Route path="resetPassword/" element={<ResetPassword />} />

        <Route
          path="*"
          element={<Navigate to={`/auth/admin/login`} replace state={{ from: location }} />}
        />
      </Route>
    </Routes>
  );
};

export default AuthLayout;
