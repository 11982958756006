import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedRoles = [] }) => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const location = useLocation();

  const loginPageRoute = () => {
    if (location?.pathname?.includes("/admin/")) {
      return "/auth/admin/login";
    } else {
      return "/auth/contractor/login";
    }
  };

  return token && role && allowedRoles?.includes(Number(role)) ? (
    <Outlet />
  ) : token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to={`${loginPageRoute()}`} state={{ from: location }} replace />
  );
};

export default RequireAuth;
