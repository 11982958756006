const userRoles = {
  ADMIN: 1,
  CONTRACTOR: 2
};

export default userRoles;

export const getUserRoleName = (role) => {
  switch (role) {
    case userRoles.ADMIN:
      return "Admin";
    case userRoles.CONTRACTOR:
      return "Contractor";
    default:
      return "Unknown";
  }
};
